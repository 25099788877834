const Labels = {
    CUSTOMER_ACQUISITION_COST: "Customer acquition cost",
    POWER_BUYER_PERCENTAGE: "  Power Buyer",
    LIMITED_BUYER_PERCENTAGE: " Limited Buyer",
    CUSTOMER_ACQUISITION_COST_DESC: 
      "By adding your standard customer acquisition costs you are now able to generate the customer's buying power as displayed on the Customers and Insights screens.",
    POWER_BUYER_PERCENTAGE_DESC: 
      "This buyer is deemed to be in the top percentile of buyers that have a high lifetime value (LTV) to your business as designated by your Metrics.",
    LIMITED_BUYER_PERCENTAGE_DESC: 
      "This buyer is deemed to be in the lower percentile of buyers and have a low lifetime value (LTV) to your business as designated by your Metrics.",
  };
  
  export default Labels;
  