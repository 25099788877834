export const GET_ACCOUNT_INFO_SUCCESS = 'GET_ACCOUNT_INFO_SUCCESS';
export const GET_ACCOUNT_INFO_ERROR = 'GET_ACCOUNT_INFO_ERROR';

export const UPDATE_ACCOUNT_INFO ="UPDATE_ACCOUNT_INFO";
export const UPDATE_ACCOUNT_INFO_ERROR ="UPDATE_ACCOUNT_INFO_ERROR";
export const UPDATE_ACCOUNT_INFO_ORG = "UPDATE_ACCOUNT_INFO_ORG";
export const UPDATE_ACCOUNT_INFO_ORG_ERROR = "UPDATE_ACCOUNT_INFO_ORG_ERROR";

export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_ERROR = "INVITE_USER_ERROR";

export const COUNT_USERS_SUCCESS = "COUNT_USERS_SUCCESS";
export const COUNT_USERS_ERROR = "COUNT_USERS_ERROR";

export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";


export const GET_USERS_INFO_SUCCESS = "GET_USERS_INFO_SUCCESS";
export const GET_USERS_INFO_ERROR = "GET_USERS_INFO_ERROR";


export const SUBMIT_USER_INFO_SUCCESS = "SUBMIT_USER_INFO_SUCCESS";
export const SUBMIT_USER_INFO_ERROR = "SUBMIT_USER_INFO_ERROR";

export const DELETE_USER_BY_ID = "DELETE_USER_BY_ID";
export const DELETE_USER_BY_ID_ERROR = "DELETE_USER_BY_ID_ERROR";


export const RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS";
export const RESEND_INVITE_ERROR = "RESEND_INVITE_ERROR";