import { useState, useEffect } from "react";
import "./style.scss";
import { Input, Button,Tag, Table, Dropdown, Menu } from "antd";
import UserAvt from "../../../assets/img/user1.png";
import ModalInvite from "./ModalInvite";
import ModalUserDetail from "./ModalUserDetail";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { getUsersInfobyId, getAllUsersInfo, deleteUserById, resendInvite } from "../../../redux/actions/settings";


const { Search } = Input;

const SettingUsers = () => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const [openDetail, setOpenDetail] = useState(false);
  const [detail, setDetail] = useState(null);
  const [data, setData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const { inviteSent, count, allUsersData, userInfo, resend, deletedUser } = useSelector((state) => state.settings);
  const [filteredData, setFilteredData] = useState([]);
  const StyledAvatar = styled(Avatar)({
    backgroundColor: "#002957", // Custom background color
    color: '#fff'
  });
  const dispatch = useDispatch();
  const onSearch = value => {
    if (value) {
      const lowercasedValue = value.toLowerCase();
      const filtered = data.filter(item =>
        item.email.toLowerCase().includes(lowercasedValue)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data); // reset to full list when search is cleared
    }
  };
  const handleCloseDetail = () => {
    setOpenDetail(false);
    setDetail(null);
  };
  useEffect(() => {
    if (inviteSent || resend ) {
      toast.success("User invited successfully and email sent!");
    }
    if(deletedUser){
      toast.success("User deleted successfully");
    }
  }, [inviteSent, resend, deletedUser])


  useEffect(() => {
    if (userInfo) {
      console.log("user info", userInfo);
      setDetail(userInfo);
      setOpenDetail(true);

    }
  }, [userInfo])

 
  const getInitials = (name) => {
    const names = name.split(' ');
    const initials = (names.map((n) => n[0]).join('')).toUpperCase();
    return initials;
  };

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1":
        console.log('Show details clicked');
        break;
      case "2":
        console.log('Revoke access clicked', selectedUserId);
        dispatch(deleteUserById(selectedUserId));  // Dispatch your Redux action here
        break;
      default:
        console.log('Unknown command');
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {/* <Menu.Item key="1">
        Show details
      </Menu.Item> */}
      <Menu.Item key="2">
        Revoke access
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "id",
      render: (id, record) => (
        <div className="viewUser">
           <StyledAvatar>{record && record?.first_name ? getInitials(record?.first_name):""}</StyledAvatar>
          <div className="userInfo">
            <h4 >
              {record?.first_name || ""} {record?.last_name || ""}{" "}
              {record?.roles === "Admin" && <Tag style={{marginLeft:"3px"}}>USER ACCESS ADMIN</Tag>}
            </h4>
            <p style={{textTransform:"lowercase", paddingTop:"4px"}}>{record?.email.toString().toLowerCase() || ""}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 140,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      width: 160,
      // align:"right",
      render: (id, record) => {
        console.log("rexord", record)
        return (
          <div className="action">
            {record?.roles == "password_reset" ? (
              <Button type="text" className="btnInvite"
              onClick={() => {
                dispatch(resendInvite(record.email))
              }}>
                Resend invite
              </Button>
            ) : (
              <Button
                type="text"
                className="btnInvite"
                onClick={() => {
                  dispatch(getUsersInfobyId(record.id))
                  setSelectedUserId(record.id);
                }}
              >
                Show details
              </Button>
            )}

            {/* <Dropdown
              menu={{ items: menuItems }}
              trigger={["click"]}
              placement="bottomRight"
            > */}
              <Dropdown overlay={menu} trigger={['click']} placement="bottomRight"  disabled={record?.roles === 'Admin'}>
              
              <Button type="text" className="btnMenu" onClick={() => {setSelectedUserId(record.id);}}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                    fill="currentColor"
                  />
                </svg>
              </Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const createData = () => {
    let results = [];
    for (let i = 0; i < allUsersData.length; i++) {
      let obj = {
        id: allUsersData[i]?.user_id,
        first_name: allUsersData[i]?.first_name || "Unnamed",
        last_name: allUsersData[i]?.last_name,
        email: allUsersData[i]?.email?.toLowerCase(),
        phone: "123123123",
        roles: allUsersData[i]?.roles,
        lastActive: "Feb 07, 2024",
        status: (allUsersData[i]?.roles == "User" ||  allUsersData[i]?.roles == "Admin")? "Active":"Invited",
        title: `Title ${i}`,
        location: "Signature Mazda",
      };
      results.push(obj);
    }

    setData(results);
    setFilteredData(results);
  };

  // useEffect(() => {
  //   createData();
  // }, []);
  useEffect(() => {
    if (allUsersData) {
   
      createData();
    }

  }, [allUsersData])

  return (
    <><h1 className="title" style={{ display: isMobile ? "contents" : "none", marginLeft: isMobile ? "20px" : "" }}>
      Users
    </h1><div className="settingUsers">
        <div className="userHeader" style={{ flexDirection: isMobile ? "column" : "" }}>
          <div className="stats">
            <p>Manage access for all the users in your organization. </p>

            <div className="viewStats">
              <div className="statItem">
                <h6>Total users</h6>
                <h2>{count?.user_count}</h2>
              </div>


              <div className="statItem">
                <h6>Administrator</h6>
                <h2>1</h2>
              </div>
            </div>

            <Search
              placeholder="Enter email address"
              onSearch={onSearch}
              style={{
                width: 330,
              }} />
          </div>

          <Button color="primary" className="btnInvite" onClick={() => setOpen(true)}>
            Invite users
          </Button>
        </div>

        <div>
          <Table columns={columns} dataSource={filteredData} scroll={{ x: 800 }} />
        </div>

        <ModalInvite open={open} onClose={() => setOpen(false)} />
        <ModalUserDetail
          detail={detail}
          open={openDetail}
          onClose={handleCloseDetail}
          selectedUserId={selectedUserId} />
      </div></>
  );
};

export default SettingUsers;
