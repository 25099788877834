
import {
  SHOW,
  HIDE,
} from "../constant/modals"

export const show = (modal, data) => async (dispatch) => {
  dispatch({
    type: SHOW,
    payload: {
      visible: modal,
      data
    },
  })
}

export const hide = () => async (dispatch) => {
  dispatch({
    type: HIDE,
  })
}
