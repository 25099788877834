import {
  Box,
  Grid,
  Typography,
  Avatar,
  Skeleton,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Images from "../../../assets/img/ImgConstants";
import "../../../pages/Reports/style.scss";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllFeeds, getFeedId, getAllRecommendations } from "../../../redux/actions/dashboard";
import woman from "../../../assets/img/woman.png";
import Badge from "@mui/material/Badge";
import RefreshIcon from "@mui/icons-material/Refresh";
import dog1 from "../../../assets/img/White dog.png";
import dog2 from "../../../assets/img/Layla.png";
import dog3 from "../../../assets/img/Dog pic.png";
import { getOrAssignAvatar } from "../../../utils/avatar";



const BioCard = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { feedsData, feedsDate } = useSelector((state) => state.dashboard);
  const { token } = useSelector((state) => state.auth);
  const [allFeeds, setAllFeeds] = useState();
  const [refreshDate, setRefreshDate] = useState();
  const [isLaptop, setIsLaptop] = useState(false);
  const dogImages = [dog1, dog2, dog3];
  useEffect(() => {
    const checkSize = () => {
      setIsLaptop(window.innerWidth === 1024 && window.innerHeight === 513);
    };

    checkSize();
    window.addEventListener("resize", checkSize); 

    return () => {
      window.removeEventListener("resize", checkSize); 
    };
  }, []);
  useEffect(() => {
    setLoading(true);  // Set loading to true when starting to fetch data
    dispatch(getAllFeeds(token));  // Dispatch action to fetch feed data
  }, [dispatch]);

  useEffect(() => {
    if (feedsData) {
      let data = feedsData.sort(compareDates);  // Sort feed data by date
      setAllFeeds(data);
      if(data.length > 0)
      setLoading(false); 
    }
    let currentTime = changeTimeFormat();  // Format the refresh time
    setRefreshDate(feedsDate + " " + currentTime);
  }, [feedsData]);

  const compareDates = (a, b) => {
    const dateA = new Date(a.time);
    const dateB = new Date(b.time);
    return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
  };

  // const avatarImage = getOrAssignAvatar(allFeeds.id);
  // console.log("allFeeds", allFeeds)
  const skeletonCard = () => {
    return (
      <Box className="segmentBio" style={{backgroundColor: "#F5F5F5", height: "inherit"}}>
        <Grid
          container
          className="bioUpperSection"
          style={{ backgroundColor: "#F5F5F5", marginBottom: "7px" ,}}
         >
          <Grid xs={12}>
            <Typography variant="body1" className="blue bold-700">
              <span>
                Feed{" "}
                <RefreshIcon
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => fetchDetails()}
                />
              </span>
            </Typography>
         <Skeleton sx={{ marginRight: "0%", marginTop: '7px' }} variant="text" height={20} width={130} />
          </Grid>
          <Grid sx={{ maxHeight: "100%", marginTop: "1%" }}
            container
            spacing={2}
            item
            xs={12}
            className="feedCentre"
            style={{ marginLeft: "0%" ,display:"flex",justifyContent:"center"}}
          >

            <Grid
              container
              direction="row"
              style={{
                justifyContent: "center",
                display: "flex",

              }}
            >
              <Skeleton sx={{ marginRight: "75%" ,marginTop: "6%",}} variant="text" height={20} width={100}/>
              <Grid
                item
                container
                xs={12}
                className="feedCentre"
                style={{ paddingBottom:isLaptop? "10%":"5%", paddingTop: "10px", marginTop: "2%", backgroundColor: "white", borderRadius:"7%"}}
              >
                <Skeleton variant="circular">
                  <Avatar sx={{ display: "flex", width: 70, height: 70 }} />
                </Skeleton>
                <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                  <Skeleton
                    animation="wave"
                    sx={{ width: "70px" }}
                  />
                </Grid>
                <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                  <Box component="span" className="ratingBox" style={{ fontSize: "11px", fontWeight: "100" }}>
                    {Images.STAR_ICON}
                    <Typography
                      variant="body1"
                      className="darker-gray bold-700"
                      style={{ fontSize: "11px", fontWeight: "100" }}
                    >
                      <Skeleton
                        animation="wave"
                        sx={{ width: "20px" }}
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "60%",
                  alignself: "center",
                  marginTop: "5%",
                  marginBottom: "5%",
                  marginRight: "10%",
                }}
              />
              <Skeleton sx={{ marginTop:isLaptop? "3%":"0%", marginRight: "75%", }} variant="text" height={20} width={100} />
              <Grid
                item
                container
                xs={12}
                className="feedCentre"
                style={{  paddingBottom:isLaptop? "10%":"5%", paddingTop: "10px", marginTop: "2%", backgroundColor: "white",borderRadius:"7%" }}
              >
                <Skeleton variant="circular">
                  <Avatar sx={{ display: "flex", width: 70, height: 70 }} />
                </Skeleton>
                <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                  <Skeleton
                    animation="wave"
                    sx={{ width: "70px" }}
                  />
                </Grid>
                <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                  <Box component="span" className="ratingBox" style={{ fontSize: "11px", fontWeight: "100" }}>
                    {Images.STAR_ICON}
                    <Typography
                      variant="body1"
                      className="darker-gray bold-700"
                      style={{ fontSize: "11px", fontWeight: "100" }}
                    >
                      <Skeleton
                        animation="wave"
                        sx={{ width: "20px" }}
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider
                style={{
                  width: "60%",
                  alignself: "center",
                  marginTop: "5%",
                  marginBottom: "5%",
                  marginRight: "10%",
                }}
              />
              <Skeleton sx={{ marginRight: "75%", marginTop:isLaptop? "3%":"0%", }} variant="text" height={20} width={100} />
              <Grid
                item
                container
                xs={12}
                className="feedCentre"
                style={{  paddingBottom:isLaptop? "10%":"5%", paddingTop: "10px", marginTop: "2%", backgroundColor: "white", borderRadius:"7%"}}
              >
                <Skeleton variant="circular">
                  <Avatar sx={{ display: "flex", alignItems:"center",width: 70, height: 70 }} />
                </Skeleton>
                <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                  <Skeleton
                    animation="wave"
                    sx={{ width: "70px" }}
                  />
                </Grid>
                <Grid xs={12} style={{ justifyContent: "center", display: "flex" }}>
                  <Box component="span" className="ratingBox" style={{ fontSize: "11px", fontWeight: "100" }}>
                    {Images.STAR_ICON}
                    <Typography
                      variant="body1"
                      className="darker-gray bold-700"
                      style={{ fontSize: "11px", fontWeight: "100" }}
                    >
                      <Skeleton
                        animation="wave"
                        sx={{ width: "20px" }}
                      />
                    </Typography>
                  </Box>

                </Grid>

              </Grid>

            </Grid>

          </Grid>

        </Grid>
      </Box>
    );
  };
  const userData = [
    {
      name: "Mark Jones",
      rating: 4.56,
      color: "lightgreen",
    },
    {
      name: "Neera Patel",
      rating: 4.65,
      color: "lightgreen",
    },
    {
      name: "Naresh Patel",
      rating: 4.65,
      color: "lightgreen",
    },
    {
      name: "Naresh Patel",
      rating: 4.65,
      color: "lightgreen",
    },
    {
      name: "Naresh Patel",
      rating: 4.65,
      color: "lightgreen",
    },
  ];

  function userDetailData(card) {
    if (card) {
      dispatch(getFeedId(card.id, token));
      dispatch(getAllRecommendations(token, card.email));
    }
    else {
      setLoading(true);

    }
  }
  function fetchDetails() {
    setLoading(true);
    dispatch(getAllFeeds(token)); 
  }

  function changeTimeFormat() {
    let date = new Date();

    let formatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    let formattedTime = formatter.format(date);
    return formattedTime;
  }

  const statusIndicator = (checkinTime) => {
    var givenDate = new Date(checkinTime);
    var currentDate = new Date();
    var timeDifference = currentDate - givenDate;
    var hoursDifference = timeDifference / (1000 * 60 * 60);
    if (hoursDifference <= 1) {
      return "green";
    } else if (hoursDifference > 1 && hoursDifference <= 2) {
      return "Yellow";
    } else {
      return "grey";
    }

  };

  return (
    <>
      {loading ? (

        skeletonCard()
      ) : (
        <Box id="scrollbar" className="segmentBio" style={{
          height: "inherit",  
        }}>
          <Grid
            container
            className="bioUpperSection"
            style={{ marginBottom: "10px", backgroundColor: "#FFFFFF" }}
          >
            <Grid xs={12}>
              <Typography variant="body1" className="blue bold-700">
                <span>
                  Feed{" "}
                  <RefreshIcon
                    style={{
                      float: "right",
                      cursor: "pointer",
                      marginRight: "2px",
                    }}
                    onClick={() => fetchDetails()}
                  />
                </span>
              </Typography>
              <Typography variant="body2">
                <span
                  style={{
                    color: "#999999",
                    fontSize: "80%",
                    fontWeight: "500",
                  }}
                >
                  {refreshDate} {/* {changeTimeFormat()}{" "} */}
                </span>
              </Typography>
            </Grid>

          </Grid>
          <Grid 
  xl={12} lg={12} md={12} xs={12}
  sx={{
    maxHeight: allFeeds?.length <= 3 ? "100%" : "80%",
    overflowY: allFeeds?.length > 3 ? "auto" : "visible",
    margin: "8%",
    marginRight: "4%",
    paddingRight:"4%"
  }}
  item
  spacing={2.5}
>
  {allFeeds?.map((card, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          color: "#999999",
          fontSize: "70%",
          fontWeight: "500",
          marginTop: "1%",
          marginBottom: "5%",
        }}
      >
        {new Intl.DateTimeFormat('en-US', {
          month: 'short',   
          day: 'numeric',  
          year: 'numeric'  
        }).format(new Date(card.time))}
      </div>
      <Grid
        container
        direction="row"
        sx={{ boxShadow: 3 }}
        style={{
          justifyContent: "center",
          display: "flex",
          padding: "5%",
          backgroundColor: "white",
          marginRight: "0%",
          borderRadius: "10px",
          maxWidth: "-webkit-fill-available",
        }}
        onClick={() => userDetailData(card)}
      >
        <React.Fragment style={{ backgroundColor: "white" }}>
          <Grid
            item
            xs={8}
            className="feedCentre"
            style={{ marginLeft: "0%" }}
          >
            <Badge
              overlap="circular"
              badgeContent=" "
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              sx={{
                "& .MuiBadge-badge": {
                  color: `${statusIndicator(card.time)}`,
                  backgroundColor: `${statusIndicator(card.time)}`,
                },
              }}
            >
              <Avatar
                alt="Bio Image"
                src={getOrAssignAvatar(card.id)}
                sx={{
                  display: "flex",
                  width: 70,
                  height: 70,
                  cursor: "pointer"
                }}
              />
            </Badge>
          </Grid>
          <Grid item xs={12} className="feedCentre">
            <Typography
              variant="body1"
              className="blue bold-700"
              style={{
                marginTop: "7px",
                marginBottom: "3px",
                cursor: "pointer",
                fontSize: '14px',
                fontFamily: 'Gotham'
              }}
            >
              {card.name}
            </Typography>
          </Grid>
          <Grid item xs={12} className="feedCentre">
            <Box component="span" className="ratingBox" style={{ fontSize: "11px", fontWeight: "100" }}>
              {Images.STAR_ICON}
              <Typography
                variant="body1"
                className="darker-gray bold-700"
                style={{ fontSize: "11px", fontWeight: "100" }}
              >
                {"4.56"}
              </Typography>
            </Box>
          </Grid>
        </React.Fragment>
      </Grid>
      {index !== allFeeds?.length - 1 && (
        <Divider
          style={{
            width: "50%",
            alignSelf: "center",
            marginTop: "1%",
            marginBottom: "5%",
            marginLeft: "25%"
          }}
        />
      )}
    </React.Fragment>
  ))}
  {allFeeds?.length > 3 && (
    <div style={{ height: "10px" }} /> 
  )}
</Grid>

        </Box>

      )}
    </>
  );
};

export default BioCard;
