import axios from "axios";
import {
  OPT_OUT_REQUEST,
  OPT_OUT_REQUEST_SUCCESS,
  OPT_OUT_REQUEST_ERROR,
} from "../constant/opt-out";
import { userPool } from "../../utils/aws-config";
import { setNotification } from "./notification";

export const optOutConfirm = (formdata) => async (dispatch) => {
  dispatch({
    type: OPT_OUT_REQUEST,
  });

  try {
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      formdata = { ...formdata };
    }
    const response = await axios.post(
      `${process.env.REACT_APP_OPT_OUT_API_URL}`,
      formdata
    );
    dispatch({
      type: OPT_OUT_REQUEST_SUCCESS,
      payload: response.data,
    });

    dispatch(setNotification("success", response.data));

  } catch (error) {
    dispatch({
      type: OPT_OUT_REQUEST_ERROR,
      payload: error.message,
    });
  }
};
