import axios from "axios";
import {
  GET_INDIVIDUALS_TABLE_DATA,
  GET_INDIVIDUALS_TABLE_DATA_FAIL,
  GET_INDIVIDUALS_TABLE_DATA_SUCCESS,
  GET_INDIVIDUALS_TAB_DATA,
  GET_INDIVIDUALS_TAB_DATA_FAIL,
  GET_INDIVIDUALS_TAB_DATA_SUCCESS,
  GET_SEGMENT_TAB_DATA,
  GET_SEGMENT_TAB_DATA_FAIL,
  GET_SEGMENT_TAB_DATA_SUCCESS,
} from "../constant/insights";
import {
  individualsListQuery,
  individualsQuery,
  segmentQuery,
} from "../constant/insights-queries";

export const getSegmentTabData = ({ startDate, endDate }) => async (dispatch) => {
    dispatch({
      type: GET_SEGMENT_TAB_DATA,
    });

    // const databillityStr = localStorage.getItem("databillity");
    // const databillity = databillityStr ? JSON.parse(databillityStr) : null;
    // let databillityId;
    // if (databillityStr) {
    //   databillityId = databillity.databillity_id;
    // }

    try {
      const response = await axios.post(process.env.REACT_APP_GRAPHQL_API_URL, {
        query: segmentQuery,
        variables: {
          startDate: startDate,
          endDate: endDate,
          databillityId: process.env.REACT_APP_DATABILLITY_ID,
        },
      },
      // {
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Access-Control-Allow-Credentials': 'true',
      //     'Content-Type': 'application/json',
      //     'Access-Control-Allow-Methods': '*',
      //     'Access-Control-Allow-Headers': '*',
      //   },
      // }
    );

      dispatch({
        type: GET_SEGMENT_TAB_DATA_SUCCESS,
        payload: response.data.data.segmentTabQuery,
      });
    } catch (error) {
      console.log("error ==>>", error);
      dispatch({
        type: GET_SEGMENT_TAB_DATA_FAIL,
        payload: error.message,
      });
    }
  };

export const getIndividualsTabData = ({ customerId, startDate, endDate }) => async (dispatch) => {
    dispatch({
      type: GET_INDIVIDUALS_TAB_DATA,
    });

    try {
      const response = await axios.post(process.env.REACT_APP_GRAPHQL_API_URL, {
        query: individualsQuery,
        variables: {
          customerId: customerId,
          startDate: startDate,
          endDate: endDate,
        },
      });

      dispatch({
        type: GET_INDIVIDUALS_TAB_DATA_SUCCESS,
        payload: response.data.data.individualsTabQuery,
      });
    } catch (error) {
      console.log("error ==>>", error);
      dispatch({
        type: GET_INDIVIDUALS_TAB_DATA_FAIL,
        payload: error.message,
      });
    }
  };

export const getIndividualsTableData = ({ startDate, endDate }) => async (dispatch) => {
    dispatch({
      type: GET_INDIVIDUALS_TABLE_DATA,
    });

    // const databillityStr = localStorage.getItem("databillity");
    // const databillity = databillityStr ? JSON.parse(databillityStr) : null;
    // let databillityId;
    // if (databillityStr) {
    //   databillityId = databillity.databillity_id;
    // }

    try {
      const response = await axios.post(process.env.REACT_APP_GRAPHQL_API_URL, {
        query: individualsListQuery,
        variables: {
          databillityId: process.env.REACT_APP_DATABILLITY_ID,
          startDate: startDate,
          endDate: endDate,
        },
      });

      dispatch({
        type: GET_INDIVIDUALS_TABLE_DATA_SUCCESS,
        payload:
          response.data.data.individualsTableListQuery.individualsCustomerList,
      });
    } catch (error) {
      console.log("error ==>>", error);
      dispatch({
        type: GET_INDIVIDUALS_TABLE_DATA_FAIL,
        payload: error.message,
      });
    }
  };
