import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { showLaunchButton } from "../../../redux/actions/connection";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Grid, Typography, Box, Button } from "@mui/material";
import "./style.scss";
import nuvImg from "../../../assets/img/nuv.svg";
import psb from "../../../assets/img/psb.svg";
import cdk from "../../../assets/img/cdk.svg";
import shopify from "../../../assets/img/shopify.svg";
import stripe from "../../../assets/img/stripe.svg";
import salesforce from "../../../assets/img/salesforce.svg";
import quickbook from "../../../assets/img/quickbook.svg";
import LoginHeader from "../../Layout/LoginHeader";
import Footer from "../../Layout/Footer";
import { useMediaQuery } from "react-responsive";
import { Diversity1 } from "@mui/icons-material";
const ConnectData = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useSelector(state => state.auth);
    const [buttonsVisible, setButtonsVisible] = useState(true); 
    const [clickedIndex, setClickedIndex] = useState(null); // Track the clicked connection
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 2560);
    const [isExtraWideDesktop, setIsExtraWideDesktop] = useState(window.innerWidth >= 3280);
    
    const dataConnections = [
        { id: 1, name: "Salesforce", logo: salesforce, connected: false },
        { id: 2, name: "Stripe", logo: stripe, connected: false },
        { id: 3, name: "Shopify", logo: shopify, connected: false },
        { id: 4, name: "Quickbooks", logo: quickbook, connected: false },
        { id: 5, name: "Nuvei", logo: nuvImg, connected: false },
        { id: 6, name: "PBS", logo: psb, connected: false },
        { id: 7, name: "CDK", logo: cdk, connected: false }
    ];
    useEffect(() => {
        
        const handleResize = () => {
            setIsWideDesktop(window.innerWidth >= 2560); 
            setIsExtraWideDesktop(window.innerWidth >= 3280);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize); 
        };
    }, []);
    useEffect(() => {
        if (location.state?.fromPbs) {
            setButtonsVisible(true);
        } else {
            setButtonsVisible(false);
        }
    
        dispatch(showLaunchButton(location.state?.Pbs || false));
    }, [location.state, dispatch]);
    
    const handleClick = (id) => {
        if (id === 6) {
            navigate("/Pbs", { state: { fromPbs: true } });
        } else {
            dispatch(showLaunchButton(true));
        }
    };
   
    const handleLaunchapp = () => {
        console.log('Auth state:', auth);
        navigate('/customers');
        // if (auth.isAuthenticated) { 
        //     navigate('/customers');
        // } else {
        //     navigate('/login');
        // }
    };
    return (
        <Container maxWidth="xxl" className="createAccount" style={{ backgroundImage: "none", backgroundColor: "#ECEFF3" }}>
            <Grid container style={{ display: "flex", flexDirection: "column", minHeight: "100vh", }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ flex: "1" }}>
                <LoginHeader/>
                <Box 
                className="mobileHeadingContainer" style={{alignItems:"center",marginTop:"12%",}}
                sx={{
                    display: { xs: 'block', sm: 'none' }, 
                    textAlign: "center",
                    marginBottom: "20px"
                }}
            >
                <Typography variant="h6" className="cardHeading">
                    Connect a data source
                </Typography>
                <Typography variant="body1" className="cardSubHeading">
                    Add pipelines to unify your commerce data
                </Typography>
            </Box>
                    <Box className="createAccountContainer">
                        <Box className="createAccountCard" style={{ backgroundColor: "white", marginTop:isMobile?"0px":"50px",width:isMobile?"95vw":""}}>
                            <Grid container justifyContent={"center"} alignItems={"center"}>
                                <Grid item xl={4} lg={4} md={4} sm={8} xs={12}>
                                    <Box className="cardUpperSection" style={{ whiteSpace: "nowrap",display: isMobile ? "none" : ""  }}>
                                        <Typography variant="h6" className="cardHeading">
                                            Connect a data source
                                        </Typography>
                                        <Typography variant="body1" className="cardSubHeading">
                                            Add pipelines to unify your commerce data
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid
  container 
  sx={{
    marginLeft: isMobile ? "10%" : "2.5%",
    justifyContent:"flex-start",
    marginTop:isMobile?"22px":"",
    width: isMobile ? "85%" : "100%",
    rowGap:isMobile? "42px":"",
    columnGap:isMobile? "65px":"",
  }}
>
                                {dataConnections.map((data, index) => (
                                    <Grid
                                        key={index}
                                        item
                                        xl={3}
                                        lg={3}
                                        md={3}
                                        sm={4.5}
                                        xs={4.5}
                                        sx={{
                                            backgroundColor: "white",
                                            // padding: "10px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent:"center",
                                            margin:isMobile?"0px":"20px",
                                            boxShadow: '4px 4px 14px rgba(0, 0, 0, 0.1)',
                                            cursor: "pointer",
                                            borderRadius:isMobile?"5px":"",                                      
                                        }}
                                        onClick={() => {
                                            setClickedIndex(index); 
                                            handleClick(data.id);
                                        }}
                                    >
                                        
                                        {clickedIndex === index ? (
                                            <svg width="25" height="25" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "-75%",marginTop:"10px" }}>
                                                <circle cx="12.5" cy="12.5" r="12.5" fill="#4BDD82" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M19.2987 8.21934C19.4392 8.35997 19.518 8.55059 19.518 8.74934C19.518 8.94809 19.4392 9.13871 19.2987 9.27934L12.2987 16.2793C12.1581 16.4198 11.9675 16.4987 11.7687 16.4987C11.57 16.4987 11.3793 16.4198 11.2387 16.2793L7.23871 12.2793C7.16502 12.2107 7.10592 12.1279 7.06493 12.0359C7.02394 11.9439 7.00189 11.8446 7.00012 11.7439C6.99834 11.6432 7.01686 11.5431 7.05459 11.4497C7.09231 11.3564 7.14845 11.2715 7.21967 11.2003C7.29089 11.1291 7.37572 11.0729 7.46911 11.0352C7.5625 10.9975 7.66253 10.979 7.76323 10.9807C7.86393 10.9825 7.96325 11.0046 8.05525 11.0456C8.14725 11.0866 8.23005 11.1457 8.29871 11.2193L11.7687 14.6893L18.2387 8.21934C18.3793 8.07889 18.57 8 18.7687 8C18.9675 8 19.1581 8.07889 19.2987 8.21934Z" fill="white" />
                                            </svg>
                                        ) : (
                                            <svg width="23" height="23" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: "-75%",marginTop:isMobile?"8px":"10px",marginBottom:"-4px" }}>
                                                <circle  className="mobileCircle" cx="12.5" cy="12.5" r="12" fill="white" stroke="#D9D9D9" />
                                            </svg>
                                        )}
                                        <img
                                            alt="Logo Connect"
                                            src={data?.logo}
                                            style={{ height:isMobile?"42px":"50px", width:isMobile?"75px":"100px",marginTop:isMobile?"-10px":"" }}
                                        />
                                        <Typography sx={{ fontSize:isMobile?"12px":"14px", fontWeight: "500", paddingTop:"10px", marginBottom:isMobile?"10px":"19px", }}>
                                            {data.name}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "10%"
                            }}>
                               <Button
    variant="contained"
    style={{
        backgroundColor: 'white',
        color: '#002857',
        boxShadow: "0 0 0 1px #002857",
        width: "25%",
        borderRadius: "10px",
        marginLeft: "15px",
        height: "45px",
        minWidth: "fit-content",
        marginBottom: "13%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px", // Adds spacing between the icon and text
    }}
    onClick={handleLaunchapp}
> Launch the App
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="20" height="20" rx="4" fill="#1460C8" fillOpacity="0.2" />
        <path
            d="M15 5.5C15 5.22386 14.7761 5 14.5 5L10 5C9.72386 5 9.5 5.22386 9.5 5.5C9.5 5.77614 9.72386 6 10 6L14 6L14 10C14 10.2761 14.2239 10.5 14.5 10.5C14.7761 10.5 15 10.2761 15 10L15 5.5ZM5.85355 14.8536L14.8536 5.85355L14.1464 5.14645L5.14645 14.1464L5.85355 14.8536Z"
            fill="#1460C8"
        />
    </svg>
   
</Button>


                                {buttonsVisible && (
                                    <>
                                        <Button variant="contained" color="primary"
                                            style={{
                                                width: "25%", borderRadius: "20px", marginLeft: "15px", height: "45px",
                                                minWidth: "fit-content", marginBottom: "5%"
                                            }}
                                        >
                                            Continue
                                        </Button>
                                        <Typography color="textSecondary" style={{ cursor: "pointer", marginBottom: "5%" }} onClick={() => navigate(-1)}>
                                            Back
                                        </Typography>
                                    </>
                                )}
                            </div>
                        </Box>
                    </Box>
                    {/* </Grid> */}
                </Grid>
              <Footer/>
           </Grid>
        </Container>
    );
};


export default ConnectData;
