
import {CLEAR_STORE } from "../constant/auth";
import {
    GET_CURRENT_PRODUCT_SUCCESS,
    GET_END_DATE_SUCCESS,
    CHANGE_PLAN_SUCCESS,
    GET_PLANS_SUCCESS


} from "../constant/subscriptionManagement";

const initialState = {
    currentProduct: null,
    subscriptionEndDate: "",
    newPlan: null,
    plansData: []
};

export default function subscriptionManagement(state = initialState, action) {
    switch (action.type) {
        case CLEAR_STORE:
            return initialState;
        case GET_CURRENT_PRODUCT_SUCCESS:
            return {
                ...state,
                currentProduct: action.payload.product,
            };
        case GET_END_DATE_SUCCESS:
            return {
                ...state,
                subscriptionEndDate: action.payload.message,
            };
        case CHANGE_PLAN_SUCCESS:
            return {
                ...state,
                newPlan: action.payload
            }
        case GET_PLANS_SUCCESS:
            return {
                plansData: action.payload
            }
        default:
            return state;
    }
}
