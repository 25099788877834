import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import Images from "../../../assets/img/ImgConstants";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { Rate, Input, Button, Empty } from "antd";
import "../../CoPilot/style.scss";

import {
    Chip,
    Avatar,
    Grid,
    Typography,
    Stack,
    Box
} from "@mui/material";
import moment from "moment";

import "./style.scss";
import "../../../pages/Reports/style.scss";
import MailIcon from "@iconify-icons/ic/mail-outline";
import PhoneIcon from "@iconify-icons/bx/bxs-phone";
import { Icon } from "@iconify/react";
import { createRatingDashboard } from "../../../redux/actions/dashboard";


import { getUserRatings } from "../../../redux/actions/dashboard";
import woman from "../../../assets/img/woman.png";

const RatingDialog = ({ onClose, selectedValue, open, userId, userName, userEmail, userRating }) => {
 
    const [personaData, setPersonaData] = useState();
    const { personas, ratingsData, rated } = useSelector((state) => state.dashboard);
    const [allFeeds, setAllFeeds] = useState();
    const isMobile = useMediaQuery({ maxWidth: 769 });
    const [view, setView] = useState("");
    const [rate, setRate] = useState(0);
    const [comment, setComment] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        //    setLoading(true);
        setView("")
    }, [open]);

    const handleClose = () => {
        onClose(selectedValue);
    };
    useEffect(() => {
        if (personas) {
            setPersonaData(personas);
        }
    }, [personas]);
    useEffect(() => {
        dispatch(getUserRatings(userId));
    }, []);
    const handleListItemClick = (value) => {
        onClose(value);
    };
    useEffect(() => {
        if (rated) {
            toast.success("Submitted ratings.");
            setRate(0);
            setComment("");
            onClose();
        }
    }, [rated]);
    const handleSubmitRating = () => {
        const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
        if (rate > 0 && userId && token) {
          const ratingData = {
            customer_id: userId,
            rating: rate,
            comments: comment,
            submitted_at: moment().format("YYYY-MM-DD")
          };
          
          dispatch(createRatingDashboard(token, ratingData));
        }
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="md" >
            <div style={{ width: isMobile ? "100%" : "500px", marginTop: "10%" }}>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2}>
                        <Avatar
                            alt="Bio Image"
                            src={woman}
                            sx={{ display: "flex", width: 100, height: 100 }}
                        />
                    </Grid>
                    <Grid item xs={1} style={{ marginLeft: isMobile ? "20px" : "0px" }}></Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" className="blue bold-700">
                            <Stack direction="row" alignItems="end" gap={1}>
                                <span style={{ fontSize: "large" }}>{userName}</span>
                                <Box component="span" className="ratingBox" style={{
                                    fontSize: "11px", fontWeight: "100",
                                    display: "inline-flex",
                                    border: "1px solid #d9d9d9",
                                    borderRadius: "4px",
                                    width: "fit-content",
                                    height: "fit-content",
                                    fontSize: "3rem",
                                    padding: "0.1rem"
                                }} >
                                    <React.Fragment className="ratingBox">
                                        {Images.STAR_ICON}

                                    </React.Fragment>
                                    <Typography
                                        variant="body1"
                                        className="darker-gray bold-700"
                                        style={{
                                            fontSize: "11px", fontWeight: "100", marginLeft: "6px",
                                            lineHeight: "18px"
                                        }}
                                    >
                                       {userRating}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Typography>
                        <div style={{ display: "flex", color: "#6F7174", paddingTop: "2%" }}>
                            <Icon icon={MailIcon} style={{ fontSize: "12px" }} />
                            <p
                                style={{
                                    fontSize: "11px",
                                    lineHeight: "11px",
                                    paddingLeft: "4px",
                                    fontWeight: "500",
                                }}
                            >
                                {userEmail}
                            </p>
                        </div>
                        <div style={{ display: "flex", color: "#6F7174", paddingTop: "2%" }}>
                            <Icon icon={PhoneIcon} style={{ fontSize: "12px" }} />
                            <p
                                style={{
                                    whiteSpace: "nowrap",
                                    fontSize: "11px",
                                    lineHeight: "11px",
                                    paddingLeft: "4px",
                                    fontWeight: "500",
                                }}
                            >
                                778-999-1234
                            </p>
                        </div>
                    </Grid>
                    <Grid container flexDirection="column" alignItems="center" justifyContent="center" style={{ marginTop: "3%" }}>
                        <Grid item xs={12} style={{ marginTop: "7%", color: "#4A4C4D", marginTop: "4%", marginBottom: "2%" }}>
                            <h1>{userRating}</h1>
                        </Grid>
                        <Grid item xs={12} alignItems="center" justifyContent="center">
                            <Rate
                                allowHalf
                                value={
                                    (ratingsData &&
                                        ratingsData?.customer_rating_average &&
                                        Math.round(ratingsData?.customer_rating_average * 10) / 10) ||
                                    0
                                }
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} alignItems="center" justifyContent="center" style={{ color: "#6F7174", marginTop: "2%", fontWeight: "400" }}>
                            <h6>
                                Based on{" "}
                                <a className="viewReviews" onClick={() => { setView("reviews") }}>
                                    {(ratingsData && ratingsData?.customer_rating_count) || 0} reviews
                                </a>
                            </h6>
                        </Grid>


                    </Grid>


                </Grid>
                {view === "reviews" ? (
                    <div className="listReviews">
                        {ratingsData &&
                            ratingsData.all_reviews &&
                            ratingsData.all_reviews.length > 0 ? (
                            ratingsData.all_reviews.map((item, i) => (
                                <div className="reviewItem" key={i}>
                                    <div className="itemHead">
                                    <Rate
        allowHalf
        value={rate}
        onChange={setRate} // Update rate when the user selects it
      />
                                        <h5>{item.submitted_by || "Test User"}</h5>
                                        <p>
                                            {item.submitted_at
                                                ? moment
                                                    .utc(item.submitted_at)
                                                    .local()
                                                    .format("MMM DD, YYYY")
                                                : ""}
                                        </p>
                                    </div>
                                    <p className="comment">{item.comments || "test data"}</p>
                                    <a className="readMore">Read more</a>
                                </div>
                            ))
                        ) : (
                            <Empty />
                        )}
                    </div>
                ) : (
                    <>
                        <Grid container flexDirection="column" alignItems="center" justifyContent="center" style={{ marginTop: "3%" }}>
                            <Grid item xs={12} alignItems="center" justifyContent="center" style={{ marginTop: "10%", color: "#6F7174" }}>
                                <h3>Rate customer</h3>
                            </Grid>
                            <p style={{ color: "#6F7174", marginTop: "2%", fontWeight: "400", fontSize: "14px", marginBottom: "2%" }}>Would you do business with this customer again?</p>
                            <Grid item xs={12} alignItems="center" justifyContent="center">
                                <Rate allowHalf value={rate} onChange={(value) => setRate(value)} />
                            </Grid>

                        </Grid>
                        <Grid container>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={8}>
                                <div className="comments" style={{ marginTop: "10%" }}>
                                    <label style={{ color: "#6F7174", fontWeight: "500", marginBottom: "15px", display: "block" }}>Comments</label>
                                    <Input.TextArea
                                        rows={3}
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </div>
                                <Button
                                    type="primary"
                                    block
                                    className="btnSubmit"
                                    style={{ width: "60%", marginLeft: "20%", marginTop: "15px", marginBottom: "40px" }}
                                    onClick={handleSubmitRating}
                                    disabled={rate === 0}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </>)}
            </div>
        </Dialog >
    );
}
export default RatingDialog;
