import React, { useEffect, useState } from "react";
import { Modal, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentTransactions } from "../../../../redux/actions/payment";
import { hide } from "../../../../redux/actions/modals";
import "./style.scss";

const WarningIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9.375L6.25 7.125L8.5 9.375L9.375 8.5L7.125 6.25L9.375 4L8.5 3.125L6.25 5.375L4 3.125L3.125 4L5.375 6.25L3.125 8.5L4 9.375ZM6.25 12.5C5.38542 12.5 4.57292 12.3358 3.8125 12.0075C3.05208 11.6792 2.39063 11.234 1.82813 10.6719C1.26563 10.1098 0.820418 9.44833 0.492501 8.6875C0.164584 7.92667 0.000417458 7.11417 7.91139e-07 6.25C-0.000415876 5.38583 0.163751 4.57333 0.492501 3.8125C0.821251 3.05167 1.26646 2.39021 1.82813 1.82813C2.38979 1.26604 3.05125 0.820833 3.8125 0.4925C4.57375 0.164167 5.38625 0 6.25 0C7.11375 0 7.92625 0.164167 8.6875 0.4925C9.44875 0.820833 10.1102 1.26604 10.6719 1.82813C11.2335 2.39021 11.679 3.05167 12.0081 3.8125C12.3373 4.57333 12.5013 5.38583 12.5 6.25C12.4988 7.11417 12.3346 7.92667 12.0075 8.6875C11.6804 9.44833 11.2352 10.1098 10.6719 10.6719C10.1085 11.234 9.44708 11.6794 8.6875 12.0081C7.92792 12.3369 7.11542 12.5008 6.25 12.5Z"
      fill="#DE2B2B"
    />
  </svg>
);

const CancelSubscriptionstep2 = () => {
  const [selectedReason, setSelectedReason] = useState(null);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const dispatch = useDispatch();
  const { accountInfo: { user_id } } = useSelector((state) => state.settings);

  const reasons = [
    "We completed our project or campaign",
    "DataBillity is not working for our needs",
    "The platform is hard to use",
    "The platform is too expensive",
    "Personalization doesn’t help our business",
    "Other"
  ];

  const handleReasonClick = (reason) => {
    setSelectedReason(reason);
    setShowOtherInput(reason === "Other");
  };

  const handleHide = () => {
    dispatch(hide());
  };

  useEffect(() => {
    if (user_id) {
      dispatch(getPaymentTransactions({ user_id }));
    }
  }, [user_id]);

  return (
    <Modal
      centered
      open
      onCancel={handleHide}
      className="cancel-subscription-modal"
      footer={null}
    >
      <div className="cancel-subscription-container">
        <div style={{ display: "flex", alignItems: "center", color: "#DE2B2B" }}>
        
          <span style={{ fontSize: "12px", marginLeft: "0px",fontWeight:"500" }}>We're sorry to see you go</span>
        </div>
        <div style={{
          marginRight: "59px",
          fontWeight: "500",
          fontFamily: "gotham",
          fontSize: "16px",
          marginBottom: "20px"
        }}>
          If you still want to cancel your subscription, please specify your reason
        </div>

        {/* Box with reasons */}
        <div>
  <div>Select reason:</div>
  <div
    style={{
      border: "1px solid #ccc",
      marginTop: "10px",
      fontSize: "14px",
      width:"24.5rem",
      borderRadius:"2px"
    }}
  >
    {reasons.map((reason, index) => (
      <div 
        key={index} 
        onClick={() => handleReasonClick(reason)}
        style={{ 
          cursor: "pointer",
          backgroundColor: selectedReason === reason ? "rgb(153, 153, 153,0.2)" : "#FFFFFF",
          color: "black",
          fontWeight: "500",
          width: "94.5%",
          padding: "8px 0",   
          paddingLeft:"21px",
        }}
      >
        <div>{reason}
        {reason === "Other" && showOtherInput && (
          <Input 
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
            style={{ 
              marginLeft: "10px", 
              width: "80%", 
              borderColor: "initial", 
              boxShadow: "none" 
            }}
          />
        )}
        </div>
      </div>
    ))}
  </div>
</div>


        <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
          <Button
            onClick={handleHide}
            style={{
              backgroundColor: "#999999",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "12px",
              fontFamily: "gotham",
              borderRadius: "30px",
              marginRight: "25px",
            }}
          >
            Dismiss
          </Button>
          <Button
            style={{
              backgroundColor: "#DE2B2B",
              color: "#FFFFFF",
              fontWeight: "bold",
              fontSize: "12px",
              fontFamily: "gotham",
              borderRadius: "30px",
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionstep2;
