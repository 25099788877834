import { React, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, signOut, getCurrentUser } from "aws-amplify/auth";
import { defaultStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { useMsal } from "@azure/msal-react";
import { clearStore } from '../../../redux/actions/auth';
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Container,
  Grid,
  Typography,
  Box,
  Divider,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ClosedLogo from "../../../assets/img/logo-closed.svg";
import GoogleLogo from "../../../assets/img/google-icon.svg";
import MicrosoftLogo from "../../../assets/img/microsoft-icon.svg";
import Footer from "../../Layout/Footer";
import LoginHeader from "../../Layout/LoginHeader";
import "./style.scss";
import {
  createAccount,
  createAccountSSO,
} from "../../../redux/actions/onboarding";

// Amplify.configure({
//   Auth: {
//     Cognito: {
//       region: process.env.REACT_APP_AWS_REGION,
//       userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
//       userPoolClientId: process.env.REACT_APP_AWS_CLIENT_ID,
//       signUpVerificationMethod: "code",

//       loginWith: {
//         oauth: {
//           redirectSignIn: [
//             process.env.REACT_APP_REDIRECT_URI_LOGIN_LOCAL,
//             process.env.REACT_APP_REDIRECT_URI_LOGIN_STAGING,
//           ],
//           redirectSignOut: [
//             process.env.REACT_APP_REDIRECT_URI_LOGOUT_LOCAL,
//             process.env.REACT_APP_REDIRECT_URI_LOGOUT_STAGING,
//           ],
//           domain: process.env.REACT_APP_COGNITO_DOMAIN,
//           scopes: ["email", "profile", "openid"],
//           responseType: process.env.REACT_APP_RESPONSE_TYPE,
//         },
//       },
//     },
//   },
// });

// cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

const CreateAccount = (props) => {
  const { setActiveStep } = props;
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  // const { instance } = useMsal();
  const [showPassword, setShowPassword] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const dispatch = useDispatch();

  // const [user, setUser] = useState(null);
  // const [errorSSO, setErrorSSO] = useState(null);
  // const [customState, setCustomState] = useState(null);

  // useEffect(() => {
  //   const unsubscribe = Hub.listen("auth", ({ payload }) => {
  //     switch (payload.event) {
  //       case "signInWithRedirect":
  //         getUser();
  //         break;
  //       case "signInWithRedirect_failure":
  //         setErrorSSO("An error has occurred during the Oauth flow.");
  //         break;
  //       case "customOAuthState":
  //         setCustomState(payload.data);
  //         break;
  //       default:
  //         break;
  //     }
  //   });

  //   getUser();
  //   return unsubscribe;
  // }, []);
useEffect(()=>{
  localStorage.clear();
},[])
useEffect(() => {
  dispatch(clearStore());
  console.log("cleared")
}, [dispatch]);
  // const getUser = async () => {
  //   try {
  //     const currentUser = await getCurrentUser();
  //     setUser(currentUser);
  //     console.log("User", currentUser, user);
  //   } catch (error) {
  //     // console.error(error);
  //     // console.log("Not signed in", errorSSO);
  //   }
  // };

  // const handleLoginMicrosoft = () => {
  //   try {
  //     instance
  //       .loginPopup({
  //         scopes: ["user.read"],
  //         prompt: "select_account",
  //       })
  //       .then((response) => {
  //         console.log("Microsoft Response", response);

  //         const { idToken } = response;
  //         dispatch(
  //           createAccountSSO({
  //             idToken: idToken,
  //             provider: "microsoft",
  //             userEmail: response.account.username, // remove and
  //             password: "PasswordTest123!", // change client id on the backend
  //           })
  //         );
  //         console.log("Microsoft login successful");
  //       })
  //       .catch((e) => {
  //         console.log("Microsoft Login Error", e);
  //       });
  //   } catch (error) {
  //     console.log("Error occurred", error);
  //   }
  // };

  console.log("process.env.REACT_APP_REDIRECT_URI_LOGIN_STAGING", process.env.REACT_APP_REDIRECT_URI_LOGIN_STAGING);
  const domain = process.env.REACT_APP_COGNITO_DOMAIN;
  const clientId = process.env.REACT_APP_AWS_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI_LOGIN_STAGING;
  const responseType = process.env.REACT_APP_RESPONSE_TYPE;
  //const microsoftClientId = `ac2a9159-1bda-4260-8026-e9bf98dc18a2`
  const scope = 'openid profile email';
  //const tenantId = 'common'; 
  const loginUrlGoogle = `${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&identity_provider=Google`;
  //const loginUrlMicrosoft = `${domain}/${tenantId}/oauth2/authorize?response_type=${responseType}&client_id=${microsoftClientId}&redirect_uri=${redirectUri}&scope=${`aws.cognito.signin.user.admin+email+openid+profile`}&identity_provider=Microsoft`;

  function handleGoogleSignIn() {
    window.gapi.load('auth2', () => {
      if (!window.gapi.auth2.getAuthInstance()) {
        window.gapi.auth2.init({
          client_id: clientId,
          // Add other options as needed
        }).then(() => console.log('Google Auth initialized'), 
                error => console.error('Error initializing Google Auth:', error));
      }
    });
    window.location.href = loginUrlGoogle;
  }
  // https://signin.app.databillity.com/login?client_id=45ga8lutci55nap3smmspc9lr7&
  // response_type=code&
  // scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=https%3A%2F%2Fapp.stg.databillity.com%2Fonboarding
  // function handleMicrosoftSignIn() {
  //   window.location.href = loginUrlMicrosoft;
  // }


  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[@$!%*?&#^()_\-+=<>{}:;,./"']/,
        `Password must contain at least one special character`
      ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!termsChecked) setNextClicked(true);
      else {
        dispatch(
          createAccount({
            email: values.email,
            password: values.password,
            setActiveStep: setActiveStep,
          })
        );
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Container maxWidth="xxl" className="createAccount">
      <Grid container style={{
            display: "flex",
            flexDirection: "column",
            minHeight:"100vh"
      }}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <LoginHeader/>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{flex:"1"}}>
          <Box className="createAccountContainer">
            <Box className="logoContainer">
              <img src={ClosedLogo} alt="Logo" className="logo" />
            </Box>
            <Box className="createAccountCard">
              <Grid container justifyContent={"center"} alignItems={"center"}>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
                  <Box className="cardUpperSection">
                    <Typography variant="h6" className="cardHeading">
                      Create Account
                    </Typography>
                    <Typography variant="body1" className="cardSubHeading">
                      Start your 30 day free trial. Cancel anytime
                    </Typography>

                    <Button
                      variant="outlined"
                      onClick={handleGoogleSignIn}
                    >
                      <img src={GoogleLogo} alt="Google Logo" />
                      Login with Google
                    </Button>
                    {/* <Button
                      variant="outlined"
                      onClick={handleMicrosoftSignIn}
                    >
                      <img src={MicrosoftLogo} alt="Microsoft Logo" />
                      Login with Microsoft
                    </Button> */}
                    <Divider className="divider">
                      <Typography>OR</Typography>
                    </Divider>
                  </Box>
                </Grid>

                <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
                  <Box className="cardForm">
                    <Typography variant="body1" className="formHead">
                      Company email
                    </Typography>
                    <TextField
                      placeholder="yourcompany@company.com"
                      variant="outlined"
                      name="email"
                      sx={{ marginBottom: 1 }}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <Typography variant="body1" className="formHead">
                      Create Password
                    </Typography>
                    <TextField
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      variant="outlined"
                      sx={{ marginBottom: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={termsChecked}
                          onChange={(event) =>
                            setTermsChecked(event.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography variant="body2" className="formCondition">
                          I agree to the
                          <a href="https://www.databillity.com/terms-conditions" className="link" target="_blank" rel="noopener noreferrer"> terms of service</a>
                        </Typography>
                      }
                    />
                    {nextClicked && !termsChecked && (
                      <Typography
                        variant="caption"
                        color="error"
                        className="errorMsg"
                      >
                        Please accept the terms of service
                      </Typography>
                    )}
                    <Box className="formButtons">
                      <Button variant="outlined">Cancel</Button>
                      <Button variant="contained" onClick={formik.handleSubmit}>
                        Next
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Footer />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreateAccount;
