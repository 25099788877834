import { React, useState } from "react";
import Authenticate from "../../../components/Onboarding/Authenticate";
import CreateAccount from "../../../components/Onboarding/CreateAccount";

const CreateAccountPage = () => {
  const [activeStep, setActiveStep] = useState(0);

 

  return activeStep === 0 ? (
    <CreateAccount setActiveStep={setActiveStep} />
  ) : activeStep === 1 ? (
    <Authenticate />
  ) : null;
};

export default CreateAccountPage;
