import { LOGOUT_REQUEST_SUCCESS , CLEAR_STORE} from "../constant/auth";
import {
  GET_ALL_RECOMMENDATIONS,
  GET_ALL_RECOMMENDARTIONS_ERROR,
  GET_ALL_FEEDS,
  GET_ALL_FEEDS_ERROR,
  GET_FEED_ID_LOADING,
  GET_FEED_ID,
  GET_FEED_ID_ERROR,
  GET_ALL_PERSONAS,
  GET_ALL_PERSONAS_ERROR,
  UPDATE_PERSONAS,
  UPDATE_PERSONAS_ERROR,
  GET_USER_RATING,
  GET_USER_RATING_ERROR,
  CREATE_RATE_SUCCESS_DASHBOARD,
  CREATE_RATE_ERROR_DASHBOARD

} from "../constant/dashboard";

const initialState = {
  recommendationData: [],
  feedsData: [],
  feedByID: {},
  feedsDate: "",
  bioLoading: true,
  rating: "",
  personas: [],
  updatePersonaResponse: "",
  ratingsData: null,
  rated: false
};


export default function dashboard(state = initialState, action) {
  switch (action.type) {
    // get all recommendations
    case CLEAR_STORE:
      return initialState;
    case CREATE_RATE_SUCCESS_DASHBOARD:
      return {
        ...state,
        rated: true,
      };
    case CREATE_RATE_ERROR_DASHBOARD:
      return {
        ...state,
        rated: false,
        errors: {
          rated: action.payload,
        },
      };
    case GET_USER_RATING:
      return {
        ...state,
        ratingsData: action.payload,
      };
    case GET_USER_RATING_ERROR:
      return {
        ...state,
        errors: {
          ratingsData: action.payload,
        },
      };

    case GET_ALL_RECOMMENDATIONS:
      return {
        ...state,
        recommendationData: action.payload,
      };
    case GET_ALL_RECOMMENDARTIONS_ERROR:
      return {
        ...state,
        errors: {
          recommendationData: action.payload,

        }
      };
    case GET_ALL_FEEDS:
      return {
        ...state,
        feedsData: action.payload,
        feedsDate: new Intl.DateTimeFormat().format(new Date())

      };
    case GET_ALL_FEEDS_ERROR:
      return {
        ...state,
        errors: {
          feedsData: action.payload,
        },
      };
    case GET_FEED_ID_LOADING:
      return {
        ...state,
        bioLoading: true,
      };
    case GET_FEED_ID:
      return {
        ...state,
        feedByID: action.payload,
        rating: action.rating,
        bioLoading: false
      };
    case GET_FEED_ID_ERROR:
      return {
        ...state,
        errors: {
          feedByID: action.payload,
        },
      };
    case GET_ALL_PERSONAS:
      return {
        ...state,
        personas: action.payload
      };
    case GET_ALL_PERSONAS_ERROR:
      return {
        ...state,
        errors: {
          personas: action.payload
        },
      };
    case UPDATE_PERSONAS:
      return {
        ...state,
        updatePersonaResponse: action.payload.message === "success" ? true : false

      }
    case UPDATE_PERSONAS_ERROR:
      return {
        ...state,
        errors: {
          updatePersonaResponse: false
        },
      };
    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        recommendationData: [],
        feedsData: [],
        feedByID: {},
        feedsDate: "",
        bioLoading: false,
        rating: "",
        personas: [],
        updatePersonaResponse: "",
        ratingsData: null,
        rated: false,
      }
    default:
      return state;
  }
}
