import axios from "axios"
import {
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_REQUEST_SUCCESS,
  GET_SUBSCRIPTION_REQUEST_ERROR,
} from "../constant/subscription"

export const getSubscription = ({ user_id }) => async (dispatch) => {
  dispatch({
    type: GET_SUBSCRIPTION_REQUEST,
  })
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/subscriptions`,
      {
        params: {
          id: user_id,
        },
      }      
    )

    dispatch({
      type: GET_SUBSCRIPTION_REQUEST_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: GET_SUBSCRIPTION_REQUEST_ERROR,
      payload: error.message,
    })
  }
}