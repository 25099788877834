import React from "react";
import { Box, Typography, TextField, Grid, Checkbox, FormControlLabel, Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./style.scss"; 
import psb from "../../../assets/img/psb.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import { showLaunchButton} from "../../../redux/actions/connection";

const Pbs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      UserName: "",
      Password: "",
      SerialNumber: "",
      agreeToTerms: false,
    },
    validationSchema: Yup.object({
      UserName: Yup.string().required("User name is required"),
      Password: Yup.string().required("Password is required"),
      SerialNumber: Yup.string().required("Serial number is required"),
      agreeToTerms: Yup.boolean().oneOf([true], "You must agree to the terms"),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("Form Submitted", values);
      navigate("/connect-data");
      dispatch(showLaunchButton(true));

    },
  });

  return (
    
    <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
    <Box className="infoform" style={{ minHeight: "650px"  , padding: 50}}>
        <div style={{ display: 'flex', justifyContent: 'center',marginBottom:'30px' }}>
  <img
    alt="Pbs"
    src={psb}
    style={{ height: "50px", width: "100px" }}
  />
</div>
      <Typography variant="body1" className="Heading" style={{fontWeight:"bold"}}>
        PBS
      </Typography>
      <Typography className="SubHeading">
        Connect your dealer management system
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            className="formItem"
            sx={{ mt: 2 }}
            style={{ marginLeft: "150px", marginRight: "150px" }}
          >
            <Grid container>
              <Grid item md={12}>
                <Typography className="FormHead"  style={{fontWeight:"500",marginBottom:'5px'}}>
                  Username
                </Typography>
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  style={{ fontSize: "16px" }}
                  placeholder="name@company.com"
                  variant="outlined"
                  name="UserName"
                  sx={{
                    input: {
                      fontWeight: "normal",
                    },
                    mb: 2,
                    borderRadius: '10px', 
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px', 
                    },
                  }}
                  value={formik.values.UserName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} // Mark field as touched
                  error={Boolean(formik.touched.UserName && formik.errors.UserName)}
                  helperText={formik.touched.UserName && formik.errors.UserName}
                />
              </Grid>
              <Grid item md={12}>
                <Typography variant="body1" className="FormHead" style={{fontWeight:"500",marginBottom:'5px'}} >
                  Password
                </Typography>
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  placeholder="Password"
                  variant="outlined"
                  name="Password"
                  sx={{
                    input: {
                      fontWeight: "normal",
                    },
                    mb: 2,
                    borderRadius: '10px', 
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px', 
                    },
                  }}
                  value={formik.values.Password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} // Mark field as touched
                  error={Boolean(formik.touched.Password && formik.errors.Password)}
                  helperText={formik.touched.Password && formik.errors.Password}
                />
              </Grid>
              <Grid item md={12}>
                <Typography variant="body1" className="FormHead" style={{fontWeight:"500",marginBottom:'5px'}}>
                  Serial number
                </Typography>
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  placeholder="123456789"
                  variant="outlined"
                  name="SerialNumber"
                  sx={{
                    input: {
                      fontWeight: "normal",
                    },
                    mb: 2,
                    borderRadius: '10px', 
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px', 
                    },
                  }}
                  value={formik.values.SerialNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur} // Mark field as touched
                  error={Boolean(formik.touched.SerialNumber && formik.errors.SerialNumber)}
                  helperText={formik.touched.SerialNumber && formik.errors.SerialNumber}
                />
              </Grid>
              <Grid item md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.agreeToTerms}
                      onChange={() =>
                        formik.setFieldValue("agreeToTerms", !formik.values.agreeToTerms)
                      }
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      Agree to <span style={{ color: '#1CBDEC',fontWeight:'500' }}>terms and conditions</span>
                    </span>
                  }
                />
                {formik.touched.agreeToTerms && formik.errors.agreeToTerms ? (
  <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
    {formik.errors.agreeToTerms}
  </div>
) : null}

              </Grid>
              <Grid item md={12}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="success" 
                  style={{ marginTop: '30px', borderRadius: '10px', marginLeft: '150px', width: '120px' }}
                  onClick={formik.handleSubmit} 
                >
                  Connect
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
    </div>
  );
};

export default Pbs;

