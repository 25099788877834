import { toast } from "react-toastify";

import { LOGOUT_REQUEST_SUCCESS, CLEAR_STORE } from "../constant/auth";
import {
  GET_API_KEYS_REQUEST,
  GET_API_KEYS_REQUEST_SUCCESS,
  GET_API_KEYS_REQUEST_ERROR,
  ROTATE_API_KEYS_REQUEST,
  ROTATE_API_KEYS_REQUEST_SUCCESS,
  ROTATE_API_KEYS_REQUEST_ERROR,
} from "../constant/apikeys"

const initialState = {
  loading: false,
  error: null,
  keys: {
    dby_key: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {    
    case CLEAR_STORE:
      return initialState
    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        checkin: null,
        error: null
      }  
    case GET_API_KEYS_REQUEST:
    case ROTATE_API_KEYS_REQUEST:
      return {
        ...state,
        loading: true,
      }            
    case GET_API_KEYS_REQUEST_SUCCESS:
    case ROTATE_API_KEYS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        keys: action.payload,
      }
    case GET_API_KEYS_REQUEST_ERROR:
    case ROTATE_API_KEYS_REQUEST_ERROR:
      toast.error("API Keys failed. Please contact administrator.");
      console.log("err: ", action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
