import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import BioCard from "../BioCard";
import FeedCard from "../FeedCard";
import RecommendationsWrapper from "../CommunicationCardWrapper";
import { Box, Grid, Typography, Button, Skeleton, Card, CardContent } from "@mui/material";
import { Carousel } from "antd";
import wheel from "../../../assets/img/wheel.png";
import { useDispatch, useSelector } from "react-redux";
import Recommendations from "../RecommendationsCard";
import "./style.scss";
import { getAccountInfo } from "../../../redux/actions/settings";
import robotIcon from '../../../assets/img/robot-icon.svg';
import noRecommendationImage from "../../../assets/img/noRecommendationImage.svg"
import CardMedia from "@mui/material/CardMedia";



const CustomerDetaiilTab = () => {
  const [recommendData, setRecommendData] = useState([]);
  const dispatch = useDispatch();
  const { insightsData, isLoading } = useSelector((state) => state.insights);
  const { recommendationData } = useSelector((state) => state.dashboard);
  const [currentIndex, setCurrentIndex] = useState(0);
  const items = Array.from(new Array(8)); // Mock data
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [isDesktop,setIsDesktop] = useState(window.innerWidth >=1024);
  const [isWideDesktop, setIsWideDesktop] = useState(window.innerWidth >= 2560);

  let description = "26 step hand wash service covering all important areas of the vehicle. Thorough vacuum and compressed air blow out of the entire vehicle.";
  let cardImage = wheel;

  useEffect(() => {
    if (recommendationData) {
      setRecommendData(recommendationData);
    }
  }, [recommendationData]);

  useEffect(() => {
    dispatch(getAccountInfo());
  }, [dispatch]);

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  // const isMobile = useMediaQuery({ maxWidth: 769 });

  // Responsive Items per Slide
  const getItemsPerSlide = () => {
    return isMobile ? 2 : 4; // 2 items on mobile, 4 on larger screens
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
      setIsDesktop(window.innerWidth >= 1024 && window.innerWidth < 2560);
      setIsWideDesktop(window.innerWidth >= 2560);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <Box style={{ marginBottom: "20px" }}>
      <Grid container spacing={3} style={isMobile ? { maxWidth: "100%", margin: "0px" } : {}}>
        <Grid item xl={9.5} lg={8} md={8} xs={12} sx={{ marginLeft: isMobile ? "-10px" : "0" }}>
          <div style={{ paddingLeft: isMobile ? "15px" : "0px", height: "100%", maxHeight: isMobile ? "100%" : isWideDesktop? "100%": isDesktop? "100%":"700px", paddingRight: isMobile ? "0px" : "0px", maxWidth: "-webkit-fill-available", marginRight: isMobile ? "0px" : "0px" }}>
            <BioCard
              name={insightsData?.segmentBio?.name}
              image={insightsData?.segmentBio?.img}
              rating={insightsData?.segmentBio?.rating}
              isLoading={isLoading}
            />
          </div>
        </Grid>
        {!isMobile && (
          <Grid item xl={2.5} lg={4} md={4} xs={8}>
           <div style={{ borderRadius:"10px",backgroundColor: "#FFFFFF",height: isWideDesktop ? "758px" : isDesktop ? "713px" : "700px" ,}}>
              <FeedCard
                name={insightsData?.segmentBio?.name}
                image={insightsData?.segmentBio?.img}
                rating={insightsData?.segmentBio?.rating}
                isLoading={isLoading}
              />
            </div>
          </Grid>
        )}
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <div>
            <Box className={!isMobile ? "segmentBio" : ""}>
              <Grid
                container
                className="bioUpperSection"
                style={{ backgroundColor: !isMobile ? "white" : "none", padding: "1%", paddingLeft: isMobile?"2%":'1%' }}
              >
                <Grid xs={12}>
                  <Typography
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft:isMobile? "0":'0.75%'
                    }}
                  >
                   <span 
  className="blue bold-600" 
  style={{ fontSize: '1.05rem',  }} >
  Recommendations
</span>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "rgb(177 177 177 / 46%)",
                        borderRadius: "5px",
                        padding: "5px 10px",
                        color: "#6f7174",
                        fontWeight: "500",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      <img
                        src={robotIcon}
                        alt="Robot Icon"
                        style={{ width: "15px", height: "15px", marginRight: "5px" }}
                      />
                      AI-Enabled
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              {(recommendData.length === 0) ? (
                <Grid item sm={12} xs={12} md={12} position={"relative"}>
                  <Box className="customer-insights sliderBox customeCarousel" style={{ marginBottom: "15px" }}>
                    <Grid container mt={isDesktop ? "55px" : "2"} ml={0} mr={isMobile ? "-8%" : "0%"} sx={{paddingBottom:isDesktop?"75px":"20px", width: "-webkit-fill-available" }} spacing={1} style={!isMobile ? { marginLeft:'1%' } : {}}>
                      {items.slice(currentIndex, currentIndex + getItemsPerSlide()).map((_, index) => (
                        <Grid item xs={6} md={2.9} key={index} sx={{ maxWidth: isMobile ? "45%" : "51%" }}>
                          <Card style={{  borderRadius: "12px",height:"100%"}}>
                <CardMedia
  component="img"
  sx={{objectFit:"fill" ,paddingTop:'10px',marginLeft:"1%",width:"90%", margin: "auto",   }}
  alt="Auto Detailing"
  height="140"
 image={noRecommendationImage}
/>
                            {/* <Skeleton variant="rectangular" width="100%" height={110} /> */}
                            <CardContent sx={{ textAlign: 'center', paddingTop: 2 }}>
                            <Box display="flex" justifyContent="center" alignItems="center">
  <Skeleton variant="rectangular" width={120} height={20} style={{ borderRadius: "10px" }} />
</Box>
                             <Skeleton variant="rectangular" width="101%" height={80} sx={{ marginRight: "10px", borderRadius: "10px", marginTop: 2 }} />
                              <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                <Skeleton variant="rounded" width={60} height={20} sx={{ borderRadius: 10, marginRight: 2 }} />
                                <Button style={{ borderRadius: "20px", fontSize: "12px" }} variant="contained" color="primary">
                                  Recommend
                                </Button>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
              ) : (
                
      
                <Carousel
                infinite={false}
                slidesToShow={getItemsPerSlide()} // Dynamic items per slide
                slidesToScroll={getItemsPerSlide()}
                dots={true}
                arrows={false}
                afterChange={(current) => setCurrentIndex(current)} // Update current index on change
                customPaging={(index) => (
                  <svg style={{
                      display: "flex", 
                      justifyContent: "center", 
                      alignItems: "center", 
                      // paddingBottom: "20px", 
                      // paddingTop: "20px"
                    }} 
                    width="10" 
                    height="10" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="5"
                      fill={index === currentIndex ? "#002857" : "white"} // Current dot is filled with #002857
                      stroke="#002857" // Non-current dots have #002857 stroke
                    />
                  </svg>
                )}
              >
                {recommendData.map((card, index) => (
                  <div key={index} > {/* Add padding for spacing */}
                    <Recommendations
                      name={card.recommendation}
                      image={card.image ? card.image : noRecommendationImage}
                      description={description}
                      price={card.price}
                      isLoading={false}
                    />
                  </div>
                ))}
              </Carousel>
   
              
              )}
            </Box>
            {isMobile && <RecommendationsWrapper />}
          </div>

       
        </Grid>
      </Grid>
    </Box>
  );
};




export default CustomerDetaiilTab;
