import { useState, useEffect } from "react"
import axios from "axios"
import { Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { loadStripe } from '@stripe/stripe-js'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js'
import arrowBackIcon from "@iconify-icons/material-symbols/arrow-back-ios"
import { Icon } from "@iconify/react"
import { Form, Input } from 'antd';

import { hide, show } from "../../../../redux/actions/modals"
import { types } from "../../../../redux/constant/modals"
import { putPaymentMethod } from "../../../../redux/actions/payment"
import LogoDesktop from "../../../../assets/img/logo-desktop.svg"
import SubmitButton from "../../../../components/common/Button/submit-button"

import "./style.scss";

export default function AddPaymentMethodModal() {
  const dispatch = useDispatch()
  const handleHide = () => {
    dispatch(hide()) 
  }
  const { accountInfo: { user_id } } = useSelector(
    (state) => state.settings
  )
  const [ stripePromise, setStripePromise ]  = useState(null)
  const [ isLoading, setIsLoading ]  = useState(false)
  const [ busy, setBusy ]  = useState(false)
  const [ name, setName ]  = useState('')
  const [ postal, setPostal ]  = useState('')

  const handleSubmit = async (e, stripe, elements) => {
    e.preventDefault()
    setIsLoading(true)
    setBusy(true)
    const { data: { client_secret } } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/payments/intent/setup`,
      {
        params: {
          id: user_id,
        },
      }      
    )

    await stripe
      .confirmCardSetup(client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name,
            address: {
              postal_code: postal,
            },
          },
        },
      })
      .then(confirmIntent => {
        if (confirmIntent.error) {
          console.log('Setup intent err.', confirmIntent.error)
          return
        }
        dispatch(putPaymentMethod({
          user_id,
          paymentMethodId: confirmIntent?.setupIntent?.payment_method,
          gateway: 'Stripe'
        }))
        .then(() => dispatch(show(types.PAYMENT_METHODS)))
      })
      .catch(error => {
        console.log('Setup intent err2.', error)
      })
      .finally(() => {
        setIsLoading(false)
        setBusy(false)
      })
  }

  const handleBack = () => {
    dispatch(show(types.PAYMENT_METHODS))
  }

  const handleChange = () => {    
  }
  
  useEffect(() => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
    setStripePromise(stripePromise)    
  }, [])

  return (
    <Modal 
      title={<img src={LogoDesktop} alt="Desktop Logo" />}
      open 
      onCancel={handleHide}
      className="payment-modal"
      footer={null}
    >
      <div className="payment-method-container">
        <div className="add-payment-method">
          <span className="payment-back mb-20" onClick={handleBack}>
            <Icon icon={arrowBackIcon} height="20px" width="12px" style={{ color: '#02AFEF' }}/>
            Manage payment method
          </span>
          <h3 className="payment-method-title mb-5">Add payment method</h3>
          <div className="payment-method-description">New payment method</div>
          <div className="payment-form w-full mt-15">
            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ stripe, elements }) => (
                  <form onSubmit={(e) => (handleSubmit(e, stripe, elements))}>
                    <Form.Item
                      layout="vertical"
                      label="Card number"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      <CardNumberElement
                        id="card-number"
                        onChange={handleChange}
                        options={{
                          disabled: busy,
                          style: {
                            base: {
                              fontSize: '14px',
                            },
                          },
                        }}
                      />
                    </Form.Item>
                    <div className="row-half">
                      <Form.Item
                        layout="vertical"
                        label="Expiration date"
                        labelCol={{
                          span: 12,
                        }}
                        wrapperCol={{
                          span: 12,
                        }}
                        className="half"
                      >
                        <CardExpiryElement
                          id="card-expiry"
                          onChange={handleChange}
                          options={{
                            disabled: busy,
                            style: {
                              base: {
                                fontSize: '14px',
                              },
                            },
                          }}
                        />                      
                      </Form.Item>
                      <Form.Item
                        layout="vertical"
                        label="CVC"
                        labelCol={{
                          span: 12,
                        }}
                        wrapperCol={{
                          span: 12,
                        }}
                        className="half"
                      >
                        <CardCvcElement
                          id="card-cvc"
                          onChange={handleChange}
                          options={{
                            disabled: busy,
                            style: {
                              base: {
                                fontSize: '14px',
                              },
                            },
                          }}
                        />                      
                      </Form.Item>                    
                    </div>
                    <Form.Item
                      layout="vertical"
                      label="Name on card"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      <Input
                        className="payment-input"
                        placeholder="Jenny Rosen"
                        onChange={event => {
                          setName(event.target.value)
                        }}  
                      />
                    </Form.Item>                                          
                    <Form.Item
                      layout="vertical"
                      label="Zip code"
                      labelCol={{
                        span: 24,
                      }}
                      wrapperCol={{
                        span: 24,
                      }}
                    >
                      <Input
                        className="payment-input"
                        placeholder="12345"
                        onChange={event => {
                          setPostal(event.target.value)
                        }}                        
                      />
                    </Form.Item>
                    <SubmitButton
                      label="Save"
                      loading={isLoading}
                      btnClassName="payment-button w-full mt-10"
                    />
                  </form>
                )}
              </ElementsConsumer>
            </Elements>
          </div>
        </div>
      </div>
    </Modal>
  )
}