export const UPDATE_ONBOARDING_STEP = 'UPDATE_ONBOARDING_STEP';
export const UPDATE_ONBOARDING_STEP_SUCCESS = 'UPDATE_ONBOARDING_STEP_SUCCESS';
export const UPDATE_ONBOARDING_STEP_FAIL = 'UPDATE_ONBOARDING_STEP_FAIL';

export const CREATE_ACCOUNT_SSO = 'CREATE_ACCOUNT_SSO';
export const CREATE_ACCOUNT_SSO_ERROR = 'CREATE_ACCOUNT_SSO_ERROR';
export const CREATE_ACCOUNT_SSO_SUCCESS = 'CREATE_ACCOUNT_SSO_SUCCESS';

export const CONFIRM_USER = 'CONFIRM_USER';
export const CONFIRM_USER_SUCCESS = 'CONFIRM_USER_SUCCESS';
export const CONFIRM_USER_FAIL = 'CONFIRM_USER_FAIL';

export const SET_LOCATION_INFO = 'SET_LOCATION_INFO';
export const SET_LOCATION_INFO_SUCCESS = 'SET_LOCATION_INFO_SUCCESS';
export const SET_LOCATION_INFO_FAIL = ' SET_LOCATION_INFO_FAIL';

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_ERROR = 'CREATE_ACCOUNT_ERROR';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_ERROR = "VERIFY_OTP_ERROR";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";

export const RESEND_CODE_REQUEST = "RESEND_CODE_REQUEST";
export const RESEND_CODE_SUCCESS = "RESEND_CODE_SUCCESS";
export const RESEND_CODE_ERROR = "RESEND_CODE_ERROR";

export const GET_BASIC_INFO = "GET_BASIC_INFO";
export const GET_BASIC_INFO_SUCCESS = "GET_BASIC_INFO_SUCCESS";
export const GET_BASIC_INFO_FAIL = "GET_BASIC_INFO_FAIL";

export const SET_BASIC_INFO = "SET_BASIC_INFO";
export const SET_BASIC_INFO_SUCCESS = "SET_BASIC_INFO_SUCCESS";
export const SET_BASIC_INFO_FAIL = "SET_BASIC_INFO_FAIL";

export const SET_COMPANY_INFO = "SET_COMPANY_INFO";
export const SET_COMPANY_INFO_SUCCESS = "SET_COMPANY_INFO_SUCCESS";
export const SET_COMPANY_INFO_FAIL = "SET_COMPANY_INFO_FAIL";

export const SET_PLAN_INFO = "SET_PLAN_INFO";
export const SET_PLAN_INFO_SUCCESS = "SET_PLAN_INFO_SUCCESS";
export const SET_PLAN_INFO_FAIL = "SET_PLAN_INFO_FAIL";

export const GET_PRODUCT_LIST = "GET_PRODUCT_LIST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAIL = "GET_PRODUCT_LIST_FAIL";
export const CREATE_CHECKOUT_SESSION = "CREATE_CHECKOUT_SESSION";
export const CREATE_CHECKOUT_SESSION_SUCCESS = "CREATE_CHECKOUT_SESSION_SUCCESS";
export const CREATE_CHECKOUT_SESSION_FAIL = "CREATE_CHECKOUT_SESSION_FAIL";

export const SKIPPED_INFO_SUCCESS = "SKIPPED_INFO_SUCCESS";
export const SKIPPED_INFO_ERROR = "SKIPPED_INFO_ERROR";
