import { toast } from "react-toastify";

import { LOGOUT_REQUEST_SUCCESS, CLEAR_STORE } from "../constant/auth";
import {
  PUT_PAYMENT_METHOD,
  PUT_PAYMENT_METHOD_SUCCESS,
  PUT_PAYMENT_METHOD_ERROR,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_ERROR,
  GET_PAYMENT_TRANSACTIONS,
  GET_PAYMENT_TRANSACTIONS_SUCCESS,
  GET_PAYMENT_TRANSACTIONS_ERROR,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_ERROR,
} from "../constant/payment"

const initialState = {
  loading: false,
  error: null,
  sources: [],
  transactions: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {    
    case CLEAR_STORE:
      return initialState
    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        sources: [],
        transactions: {},
        error: null
      }  
    case PUT_PAYMENT_METHOD:
    case DELETE_PAYMENT_METHOD:
    case GET_PAYMENT_METHODS:
    case GET_PAYMENT_TRANSACTIONS:
      return {
        ...state,
        loading: true,
      }
    case PUT_PAYMENT_METHOD_SUCCESS:  
    case DELETE_PAYMENT_METHOD_SUCCESS: 
      return {
        ...state,
        loading: false,
      }
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        sources: action.payload,
      }
    case GET_PAYMENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload,
      }
    case GET_PAYMENT_METHODS_ERROR:
    case DELETE_PAYMENT_METHOD_ERROR:
    case PUT_PAYMENT_METHOD_ERROR:
    case GET_PAYMENT_TRANSACTIONS_ERROR:
      toast.error("Payment request failed. Please contact administrator.");
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
