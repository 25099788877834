/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Space,
  Button,
  Popover,
  List,
  Avatar,
  Switch,
  Dropdown,
} from "antd";
import {
  BellFilled,
  DownOutlined,
  LogoutOutlined,
  MenuOutlined,
  SearchOutlined,
} from "@ant-design/icons";
// import Switch from "react-switch";
import LogoMobile from "../../../assets/img/logo_mobile.svg";
import "./style.scss";
import { logout } from "../../../redux/actions/auth";
import { useNavigate } from "react-router-dom";

import { getAccountInfo } from "../../../redux/actions/settings";

const Header = (props) => {
  const [isNewNotification, setIsNewNotification] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(true);

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const databillityStr = localStorage.getItem("databillity");
  const username = localStorage.getItem("username");
  const databillity = databillityStr ? JSON.parse(databillityStr) : null;
  const switchDataStr = localStorage.getItem("switchData");
  const switchData = switchDataStr ? JSON.parse(switchDataStr) : null;

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const { accountInfo } = useSelector((state) => state.settings);
  const [switchValues, setSwitchValues] = useState({
    signature: true,
    richmond: true,
  });

  useEffect(() => {
    if (switchData) {
      setSwitchValues(switchData);
    }
  }, [switchData]);
  useEffect(() => {
    dispatch(getAccountInfo());
  }, []);

  useEffect(() => {
    if (accountInfo) {
      localStorage.setItem("roles", accountInfo?.roles);
    }
  }, [accountInfo]);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (isUserDropdownOpen && !dropdownRef.current.contains(event.target))
        setIsUserDropdownOpen(false);
    }
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isUserDropdownOpen]);
  const deleteCookie = (name, path, domain) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain}`;
  };

  // Function to delete all cookies
  const deleteAllCookies = () => {
    // Get all cookies
    const cookies = document.cookie.split("; ");

    // Delete each cookie
    cookies.forEach((cookie) => {
      const [name] = cookie.split("=");
      deleteCookie(name, "/", window.location.hostname);
    });
  };

  const getInitials = (name) => {
    const names = name?.split(" ");
    const initials = names
      ?.map((n) => n[0])
      .join("")
      ?.toUpperCase();
    return initials;
  };
  const handleLogout = () => {
    localStorage.removeItem("databillity_token");
    localStorage.clear();
    sessionStorage.clear();
    deleteAllCookies();
    dispatch(logout());
  };

  const handleChangeSwitch = (checked, name) => {
    const obj = { ...switchValues, [name]: checked };
    localStorage.setItem("switchData", JSON.stringify(obj));
    setSwitchValues(obj);
  };

  const contentSetting = (
    <div
      style={{
        minWidth: "fit-content",
      }}
    >
      <List itemLayout="horizontal">
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                size={28}
                style={{
                  background: "#002857",
                  fontSize: "14px",
                  fontWeight: 500,
                  marginLeft: "15px",
                }}
              >
                <Space>{getInitials(accountInfo?.first_name)}</Space>
              </Avatar>
            }
            title={
              <span style={{ display: "block" }}>
                {`${accountInfo?.first_name || ""} ${
                  accountInfo?.last_name || ""
                }`}
              </span>
            }
            description={
              <span
                style={{
                  display: "block",
                  marginTop: "2px",
                  lineHeight: "0.5",
                }}
              >
                {accountInfo?.email || ""}
              </span>
            }
          />
        </List.Item>
        <List.Item style={{ marginTop: "10px" }}>
          <h5 style={{ marginRight: "10px" }}>
            {`${
              accountInfo?.company_legal_name?.length > 21
                ? accountInfo.company_legal_name.slice(0, 21) + "..."
                : accountInfo.company_legal_name
            }`}
          </h5>
          <div>
            <Switch
              size="small"
              checked={switchValues.signature}
              onChange={(e) => handleChangeSwitch(e, "signature")}
            />
          </div>
        </List.Item>

        {accountInfo?.additional_locations && (
          <List.Item>
            <div style={{ flex: 1 }}>
              <h5 style={{ margin: 0 }}>
                {`${
                  accountInfo?.location_name?.length > 21
                    ? accountInfo.location_name.slice(0, 21) + "..."
                    : accountInfo.location_name || ""
                }`}
              </h5>
            </div>
            <div>
              <Switch
                size="small"
                checked={switchValues.richmond}
                onChange={(e) => handleChangeSwitch(e, "richmond")}
              />
            </div>
          </List.Item>
        )}
      </List>
    </div>
  );

  return (
    <>
      {isMobile ? (
        <div className="header mobile">
          <div className="header-toggle" onClick={props.onClick}>
            <MenuOutlined />
          </div>
          <div className="header-logo">
            <img src={LogoMobile} alt="Logo" />
          </div>
          {/* <div>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "1",
                    label: accountInfo?.company_legal_name?.toUpperCase(),
                  },
                ],
              }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 19L13 13M1 8C1 8.91925 1.18106 9.82951 1.53284 10.6788C1.88463 11.5281 2.40024 12.2997 3.05025 12.9497C3.70026 13.5998 4.47194 14.1154 5.32122 14.4672C6.1705 14.8189 7.08075 15 8 15C8.91925 15 9.82951 14.8189 10.6788 14.4672C11.5281 14.1154 12.2997 13.5998 12.9497 12.9497C13.5998 12.2997 14.1154 11.5281 14.4672 10.6788C14.8189 9.82951 15 8.91925 15 8C15 7.08075 14.8189 6.1705 14.4672 5.32122C14.1154 4.47194 13.5998 3.70026 12.9497 3.05025C12.2997 2.40024 11.5281 1.88463 10.6788 1.53284C9.82951 1.18106 8.91925 1 8 1C7.08075 1 6.1705 1.18106 5.32122 1.53284C4.47194 1.88463 3.70026 2.40024 3.05025 3.05025C2.40024 3.70026 1.88463 4.47194 1.53284 5.32122C1.18106 6.1705 1 7.08075 1 8Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Dropdown>

          </div> */}
          <div className="header-contorls">
            <ul className="header-contorls-list">
              <li className="">
                <a
                  // onClick={(e) => e.preventDefault()}
                  className="header-contorls-list-loginUserName"
                >
                  <Space>{getInitials(accountInfo?.first_name)}</Space>
                </a>
              </li>
              <li className="header-contorls-list-logout">
                <p onClick={handleLogout}>
                  <LogoutOutlined className="header-contorls-list-logout-icon" />
                </p>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className={`header${isTablet ? " tablet" : ""}`}>
          <div className="header-toggle" onClick={props.onClick}>
            <MenuOutlined />
          </div>
          <div className="header-search">
            {accountInfo?.additional_locations ? (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: accountInfo?.location_name?.toUpperCase(),
                    },
                  ],
                }}
              >
                <Button
                  className="btnDropdown"
                  style={{ justifyContent: "left" }}
                >
                  {accountInfo?.company_legal_name?.toUpperCase()}
                  {/* Optional dropdown arrow icon */}
                  {/* <svg
          width="13"
          height="7"
          viewBox="0 0 13 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0L6.5 7L13 0H0Z"
            fill="white"
            fillOpacity="0.6"
          />
        </svg> */}
                </Button>
              </Dropdown>
            ) : (
              <Button
                className="btnDropdown"
                style={{ justifyContent: "left" }}
              >
                {accountInfo?.company_legal_name?.toUpperCase()}
              </Button>
            )}
          </div>

          <div className="header-contorls ">
            <ul className="header-contorls-list">
              <li className="">
                <Popover
                  content={contentSetting}
                  trigger={["click"]}
                  rootClassName="profileMenus1"
                  id="popover-class"
                  placement="left"
                  overlayStyle={{
                    inset: "None",
                    marginTop: "70px !important",
                    marginLeft: "1200px",
                  }}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    className="header-contorls-list-loginUserName"
                  >
                    <Space>{getInitials(accountInfo?.first_name)}</Space>
                  </a>
                </Popover>
              </li>

              {/* <li className="header-contorls-list-notification">
                <Badge dot={isNewNotification} offset={[-3, 3]}>
                  <BellFilled className="header-contorls-list-notification-icon" />
                </Badge>
              </li> */}
              <li className="header-contorls-list-logout">
                <p onClick={handleLogout}>
                  <LogoutOutlined className="header-contorls-list-logout-icon" style={{height:"22px",width:"22px"}} />
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
