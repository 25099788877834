import axios from "axios"
import {
  PUT_PAYMENT_METHOD,
  PUT_PAYMENT_METHOD_SUCCESS,
  PUT_PAYMENT_METHOD_ERROR,
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_ERROR,
  GET_PAYMENT_TRANSACTIONS,
  GET_PAYMENT_TRANSACTIONS_SUCCESS,
  GET_PAYMENT_TRANSACTIONS_ERROR,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_ERROR,
} from "../constant/payment"

export const putPaymentMethod = ({ 
  user_id, id,
  paymentMethodId,
  gateway, active,
  is_default,
  name, 
  postal, 
  expiryMonth, 
  expiryYear,
}) => async (dispatch) => {
  dispatch({
    type: PUT_PAYMENT_METHOD,
  })

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/customers/${user_id}/payments/sources`,
      {
        id, 
        gateway,
        paymentMethodId,
        active,
        is_default,
        name, 
        postal, 
        expiryMonth, 
        expiryYear,      
      }      
    )

    dispatch({
      type: PUT_PAYMENT_METHOD_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: PUT_PAYMENT_METHOD_ERROR,
      payload: error.message,
    })
  }
}

export const deletePaymentMethod = ({ user_id, id, }) => async (dispatch) => {
  dispatch({
    type: DELETE_PAYMENT_METHOD,
  })
  
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/customers/${user_id}/payments/sources/${id}`,
    )

    dispatch({
      type: DELETE_PAYMENT_METHOD_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_PAYMENT_METHOD_ERROR,
      payload: error.message,
    })
  }
}

export const getPaymentMethods = ({ user_id }) => async (dispatch) => {
  dispatch({
    type: GET_PAYMENT_METHODS,
  })
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/customers/${user_id}/payments/sources`,
      {
        params: {
          id: user_id,
        },
      }      
    )

    dispatch({
      type: GET_PAYMENT_METHODS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: GET_PAYMENT_METHODS_ERROR,
      payload: error.message,
    })
  }
}

export const getPaymentTransactions = ({ user_id }) => async (dispatch) => {
  dispatch({
    type: GET_PAYMENT_TRANSACTIONS,
  })
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/customers/${user_id}/payments`,
      {
        params: {
          id: user_id,
        },
      }      
    )

    dispatch({
      type: GET_PAYMENT_TRANSACTIONS_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: GET_PAYMENT_TRANSACTIONS_ERROR,
      payload: error.message,
    })
  }

}