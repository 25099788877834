import React, { useState, useEffect, useRef } from "react";
import FormOrganization from "./FormOrganization";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import Detail from "../profile/detail";
import ModalLocation from "./ModalLocation";
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import { getAccountInfo } from "../../../redux/actions/settings";
import { useMediaQuery } from 'react-responsive';


const SettingOrganization = () => {
  const [isEdit, setIsEdit] = useState(false);
  const fileInputRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });


  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(true);
  const { accountInfo } = useSelector(
    (state) => state.settings
  );
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const dispatch = useDispatch();

  const AvatarEditContainer = styled('div')({
    position: 'relative',
    display: 'inline-block'
  });

  const StyledAvatar = styled(Avatar)({
    width: 64,
    height: 64,
  });

  const EditIconButton = styled(IconButton)({
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: 'rgba(255, 255, 255, 0.6)', // Semi-transparent background to make the icon visible
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.8)', // Lighter background on hover
    }
  });
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    handleUpload(event.target.files[0]);
  };
  const fetchImageUrl = async () => {
    try {
      const response = await axios.get("https://qqiud3gxsa.execute-api.us-east-1.amazonaws.com/api/get-image-url?userId=123");
      setImageUrl(response.data.url);
    } catch (error) {
      console.error('Error fetching image URL:', error);
    }
  };
  useEffect(() => {
    fetchImageUrl();
  }, []);

  const handleUpload = async (fileData) => {
    if (!fileData) {
      alert('Please select a file first!');
      return;
    }

    try {
      // Get the signed URL from your backend
      const response = await axios.get("https://qqiud3gxsa.execute-api.us-east-1.amazonaws.com/api/upload-url?userId=123");
      const { url } = response.data;

      // Upload the image to S3 using the signed URL
      await axios.put(url, fileData, {
        headers: {
          'Content-Type': 'image/png'
        }
      });
      fetchImageUrl();

      alert('File uploaded successfully!');
    } catch (error) {
      console.error('Upload failed:', error);
      alert('Failed to upload file.');
    }
  };
  const toTitleCase = (text) => {
    if (text) {
      return text
        .toLowerCase()
        .split(' ')
        .map(word => {
          const cleanWord = word.replace(/[.,]/g, '');
          
          if (["nw", "sw", "st"].includes(cleanWord.toLowerCase())) {
            return cleanWord.toUpperCase(); // Fully capitalize specific abbreviations
          }
          
          // Otherwise, apply title case to the word
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    } else {
      return "--";
    }
  };
  const formatPhoneNumber = (phoneNumber) => {
    // Check if the phone number is a valid 10-digit number
    if (!phoneNumber || phoneNumber.length !== 10 || isNaN(phoneNumber)) {
      return phoneNumber; // Return as is if it's not a valid number
    }
    
    // Format the number as 999-999-9999
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  };
  useEffect(() => {
    if (accountInfo) {
      const locationLabel1 = accountInfo.country === "Canada" ? "Province" : "State";
      const postalCodeLabel = accountInfo?.country === "Canada" ? "Postal code" : "Zip code";
      setData([
        { label: "Legal Name", value: accountInfo?.company_legal_name },
        { label: "Website", value: accountInfo?.website },
        { label: "Main Phone Number", value: formatPhoneNumber(accountInfo?.main_phone) },
        // { label: "Email Address", value: accountInfo?.email },
        { label: "Company Email", value: accountInfo?.main_email },
        {
          label: "Address Line 1",
          value: toTitleCase([
            accountInfo?.address_line_1,
            // accountInfo?.address_line_2,
            // accountInfo?.city,
            // accountInfo?.country,
          ].filter(Boolean).join(", "))
        },
        { label: "Address Line 2", value:  toTitleCase(accountInfo?.address_line_2)},
        { label: "City", value:   toTitleCase(accountInfo?.city)},
        {
          label: locationLabel1,
          value: accountInfo?.state_province, 
        },
        { label: postalCodeLabel, value: accountInfo?.postal_code },
        { label: "Country", value: accountInfo?.country },
       
      ]);
    }
    setLoading(false);
    console.log(accountInfo);
  }, [accountInfo]);

  useEffect(() => {
    setLoading(true)
    dispatch(getAccountInfo());
  }, [isEdit]);
  function EditableAvatar({ imageUrl, onEdit }) {
    return (
      <Tooltip title="Edit Profile Picture" placement="top">
        <AvatarEditContainer>
          <StyledAvatar src={imageUrl} alt="Profile picture" style={{ width: '73px', height: '73px', objectFit: 'cover' }}  />
          <div style={{marginTop:"0px"}}>
          <EditIconButton onClick={onEdit} size="small" color="primary" style={{color:"#999999",backgroundColor:"white",border:"1px solid #999999",marginRight:"-6px"}}>
            <EditIcon style={{ fontSize: "1rem",}}/>
          </EditIconButton>
          </div>
        </AvatarEditContainer>
      </Tooltip>
    );
  }
  const handleEditClick = () => {
    console.log('Edit button clicked!');
    fileInputRef.current.click();
    // Logic for edit action can be added here
  };
  return (
    <><h1 className="title" style={{ display: isMobile ? "contents" : "none", marginLeft: isMobile ? "24px" : "" }}>
      Organization
    </h1><div className="settingOrganization">
        <div className="orgHeader" style={{ marginBottom: '24px' }}>
          {/* <input type="file" onChange={handleFileChange} accept="image/png" />
    <button onClick={handleUpload}>Upload to S3</button> */}
          {imageUrl ?
            // <UserDetail name="Databillity" image={imageUrl} type="org" /> 
            <>
              <div>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="image/*"
                  style={{ display: 'none' }} // Hide the file input
                />
                <EditableAvatar imageUrl={imageUrl} onEdit={handleEditClick} />
              </div>

            </>
            : <p>Loading...</p>}


          <div style={{ placeSelf: 'center', fontWeight: '600', fontSize: 'medium' }}>
            {accountInfo?.company_legal_name}
          </div>
        </div>
        {isEdit ? (
          <FormOrganization onCancel={() => setIsEdit(false)} />
        ) : data.length > 0 && !loading ? (
          <Detail
            data={data}
            onUpdate={() => setIsEdit(true)}
            onCancel={() => setIsEdit(false)}
            onDelete={() => setIsEdit(false)} />
        ) : ""}

        <ModalLocation open={open} onClose={() => setOpen(false)} />
      </div></>
  );
};

export default SettingOrganization;
