import { LOGOUT_REQUEST_SUCCESS, CLEAR_STORE } from "../constant/auth";
import {
  GET_INDIVIDUALS_TABLE_DATA,
  GET_INDIVIDUALS_TABLE_DATA_FAIL,
  GET_INDIVIDUALS_TABLE_DATA_SUCCESS,
  GET_INDIVIDUALS_TAB_DATA,
  GET_INDIVIDUALS_TAB_DATA_FAIL,
  GET_INDIVIDUALS_TAB_DATA_SUCCESS,
  GET_SEGMENT_TAB_DATA,
  GET_SEGMENT_TAB_DATA_FAIL,
  GET_SEGMENT_TAB_DATA_SUCCESS,
} from "../constant/insights";

const initialState = {
  insights: { segment: {}, individual: { table: {}, customer: {} } },
  isLoading: true,
  error: null,
};

export default function Insights(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STORE:
      return initialState;
    case GET_SEGMENT_TAB_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case GET_SEGMENT_TAB_DATA_SUCCESS:
      return {
        ...state,
        insights: { ...state.insights, segment: action.payload },
        isLoading: false,
      };
    case GET_SEGMENT_TAB_DATA_FAIL:
      // toast.error("Failed to fetch Segment Tab Data");
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_INDIVIDUALS_TAB_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INDIVIDUALS_TAB_DATA_SUCCESS:
      return {
        ...state,
        insights: { ...state.insights, individual: { ...state.insights.individual, customer: action.payload } },
        isLoading: false,
      };
    case GET_INDIVIDUALS_TAB_DATA_FAIL:
      // toast.error("Failed to fetch Individuals Tab Data");
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case GET_INDIVIDUALS_TABLE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case GET_INDIVIDUALS_TABLE_DATA_SUCCESS:
      return {
        ...state,
        insights: { ...state.insights, individual: { ...state.insights.individual, table: action.payload } },
        isLoading: false,
      };
    case GET_INDIVIDUALS_TABLE_DATA_FAIL:
      // toast.error("Failed to fetch Individuals Table Data");
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        insights: { segment: {}, individual: { table: {}, customer: {} } },
        isLoading: true,
        error: null,
      }
    default:
      return state;
  }
}
