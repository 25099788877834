import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Layout from "./components/Layout";
import Notification from "./components/common/Notification";
import PrivateRoute from "./components/routing/privateRoute";
import ModalSwitcher from "./components/Modals/ModalSwitcher";
import Location from "./pages/Location";
import CoPilot from "./pages/CoPilot";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Connections from "./pages/Connections";
import OldConnections from "./pages/Connections-Old";
import Reports from "./pages/Reports";
import Settings from "./pages/Settings";
import CheckIn from "./pages/CheckIn";
import OptOut from "./pages/OptOut";
import OptOutConfirmPage from "./pages/OptOutConfirm";
import ApiKeys from "./pages/ApiKeys";
import CompanyName from "./components/Onboarding/CompanyInformation/CompanyName";
import setAuthToken from "./utils/setAuthToken";
import store from "./redux/store";
import { loadUser, logout } from "./redux/actions/auth";
import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/theme";
import Onboarding from "./pages/Onboarding";
import CreateAccountPage from "./pages/Onboarding/CreateAccountPage";
import Pbs from "./pages/Onboarding/Pbs";
import ConnectDataLanding from "./pages/Onboarding/ConnectDataLanding";

let inactivityTimer;

function App() {
  const { onboarding } = useSelector((state) => state.onboarding);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    store.dispatch(loadUser());

    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch(logout());
    });

    let inactivityTimer;
    const logoutTime = 2 * 60 * 60 * 1000; 
    let interval;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      clearInterval(interval);

      // console.log("User activity detected, resetting timer...");
      const startTime = Date.now();

      inactivityTimer = setTimeout(() => {
        console.log("Logging out due to inactivity");
        dispatch(logout());
        navigate("/login");
      }, logoutTime);

      // Log remaining time in the console at 1-second intervals
      interval = setInterval(() => {
        const timeElapsed = Date.now() - startTime;
        const timeRemaining = logoutTime - timeElapsed;

        if (timeRemaining <= 0) {
          clearInterval(interval);
        } else {
          // console.log(`Time remaining before logout: ${Math.ceil(timeRemaining / 1000)} seconds`);
        }
      }, 1000);
    };

    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keypress", resetInactivityTimer);
    window.addEventListener("scroll", resetInactivityTimer);

    // Initial timer setup
    resetInactivityTimer();

    return () => {
      clearTimeout(inactivityTimer);
      clearInterval(interval);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keypress", resetInactivityTimer);
      window.removeEventListener("scroll", resetInactivityTimer);
    };
  }, [dispatch, navigate]);
  const urlParams = new URLSearchParams(window.location.search);
  const isAuthenticated = !!urlParams.get('code') || onboarding.account.verified;

  return (
    <ThemeProvider theme={theme}>
      <Notification />
      <Routes>
        <Route path="/create-account" element={<CreateAccountPage />} />
        <Route path="/connect-data" element={<ConnectDataLanding />} />
        <Route path="/Pbs" element={<Pbs />} />
        <Route path="/company-name" element={<CompanyName />} />
        <Route path="/login" element={<Login />} />
        <Route path="/opt-out" element={<OptOut />} />
        <Route path="/opt-out/confirm" element={<OptOutConfirmPage />} />
        <Route path="/onboarding" element={isAuthenticated ? <Onboarding /> : <Navigate to="/login" replace />} />
        <Route path="/" element={<Navigate to="/customers" />} />
        
        <Route path="/" element={<PrivateRoute component={Layout} />}>
          <Route path="/co-pilot" element={<CoPilot />} />
          <Route path="/customers" element={<Dashboard />} />
          <Route path="/location" element={<Location />} />
          <Route path="/connections-old" element={<OldConnections />} />
          <Route path="/connections" element={<Connections />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/apikeys" element={<ApiKeys />} />
        </Route>

        <Route path="/check-in" element={<PrivateRoute component={CheckIn} />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <ModalSwitcher />
    </ThemeProvider>
  );
}

export default App;
