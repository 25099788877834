import { Modal, Form, Select, Input, Button, Row, Col } from "antd";
import FormProfile from "../profile/FormProfile";
import "./style.scss";
import { inviteUserSES} from "../../../redux/actions/settings";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const ModalInvite = ({ open, onClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { accountInfo } = useSelector(
    (state) => state.settings
  );

  const handleFinish = () => {
    const emailValue = form.getFieldValue('email');
    dispatch(inviteUserSES(emailValue));
    console.log('Email:', emailValue);
    onClose();

  };


  return (
    <Modal
      wrapClassName="modalInvite"
      open={open}
      onCancel={onClose}
      title=""
      footer={null}
    >
      <h3 className="title">Invite people to {accountInfo?.company_legal_name}</h3>

      <Form form = {form} className="formProfile" layout="vertical" onFinish={handleFinish}>
        <Row gutter={[54, 15]}>
        <Col xs={24}>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required',
            },
            {
              type: 'email',
              message: 'Email invalid',
            },
          ]}
        >
          <Input placeholder="" defaultValue="" />
        </Form.Item>
      </Col>

          <Col xs={24}>
            <Form.Item
              label="Role"
              name="role"
            > <Input className="role"
            value="Standard User"
            disabled
          />
           
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div className="actions">
              <Button className="btnSave" type="primary" htmlType="submit">
                Send
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalInvite;
