import { React, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  Switch,
  Box,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { Skeleton } from "antd";
import SegmentsTab from "../../components/Reports/SegmentsTab";
import IndividualsTab from "../../components/Reports/IndividualsTab";
import CustomerDetailDrawer from "../../components/common/CustomerDetailDrawer";
import { getAllCutomerData } from "../../redux/actions/co-pliot";
import "./style.scss";
import useWindowSize from "../../hooks/useWindowSize";
import ButtonRangePicker from "../../components/common/ButtonRangePicker";
import { borderRadius } from "polished";

const filterFormat = "YYYY-MM-DD";

const StyledTab = styled(Tab)({
  textTransform: "none",
  color: "#999999",
  fontSize: "16px",
  height: "35px",
  fontWeight:"500",
  
  // marginLeft:'-30px,
  "&.Mui-selected": {
    color: "#002857",
    padding: "0px",
    minWidth: "55px",
    fontWeight: "700",
    // marginRight:'20px',
  },
  ".&.MuiTabs-indicator": {
    top: "33px",
    // backgroundColor:"black"
  },
  // "& .MuiTabs-indicator": {
  //   backgroundColor: "black", // Change the tab indicator color here
  // },
  ".&.MuiTabs-scroller": {
    top: "33px",
    marginBottom: "10px"
  }
});
const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    bottom: "8px",
    height: "4px",
    borderRadius: "20px",
  },
});

const Reports = () => {
  const dispatch = useDispatch();

  const { customer, loadingCustomer } = useSelector(
    (state) => state.coPilot
  );
  const { width } = useWindowSize();
  const isMobile = useMediaQuery({ maxWidth: 769 });

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filters, setFilters] = useState({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    key: "selection",
  });

  const handleFilter = (dates) => {
    if (dates.startDate && dates.endDate) {
      dispatch(
        getAllCutomerData({
          start_date: moment(dates.startDate).format(filterFormat),
          end_date: moment(dates.endDate).format(filterFormat),
        })
      );
      setFilters({
        startDate: dates.startDate,
        endDate: dates.endDate,
        key: "selection",
      });
    }
  };

  const loadData = () => {
    if (filters) {
      dispatch(
        getAllCutomerData({
          start_date: moment(filters.startDate).format(filterFormat),
          end_date: moment(filters.endDate).format(filterFormat),
        })
      );
    } else {
      dispatch(getAllCutomerData());
    }
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    setStartDate(moment(filters.startDate).format(filterFormat));
    setEndDate(moment(filters.endDate).format(filterFormat));
  }, [filters]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (

    <Grid container spacing={2} style={{marginRight:isMobile ? "7px":"", overflow: isMobile ? "hidden" : "" }}>
      <Grid container style={{ paddingTop: "1%" }} xs={12}>
        {/* <Grid container spacing={2}> */}
        <Grid item xs={6}>
          <Typography
            variant="h6"
            className="blue bold-600"
            style={{ marginLeft: '10px' }}
            sx={{ pt: isMobile ? 6 : 1, pl: isMobile ? 2 : 2 }}
          >
            Insights
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <CustomerDetailDrawer
            isOpen={isDrawerOpen}
            onClose={onClose}
            customer={customer}
            isMobile={isMobile}
            isLoading={loadingCustomer}
            onLoadData={loadData}
          />

          {width > 1024 ? (
            <div style={{
              display: "flex", flexDirection: " row", alignItems: "end",
              justifyContent: "end"
            }}>
              <div style={{ cursor: "pointer", alignSelf: "center", marginRight: "10px" }}>
                <Box sx={{ borderRadius: 20 }}>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 21C12 20.7348 12.1054 20.4804 12.2929 20.2929C12.4804 20.1054 12.7348 20 13 20H19C19.2652 20 19.5196 20.1054 19.7071 20.2929C19.8946 20.4804 20 20.7348 20 21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21ZM8 15C8 14.7348 8.10536 14.4804 8.29289 14.2929C8.48043 14.1054 8.73478 14 9 14H23C23.2652 14 23.5196 14.1054 23.7071 14.2929C23.8946 14.4804 24 14.7348 24 15C24 15.2652 23.8946 15.5196 23.7071 15.7071C23.5196 15.8946 23.2652 16 23 16H9C8.73478 16 8.48043 15.8946 8.29289 15.7071C8.10536 15.5196 8 15.2652 8 15ZM4 9C4 8.73478 4.10536 8.48043 4.29289 8.29289C4.48043 8.10536 4.73478 8 5 8H27C27.2652 8 27.5196 8.10536 27.7071 8.29289C27.8946 8.48043 28 8.73478 28 9C28 9.26522 27.8946 9.51957 27.7071 9.70711C27.5196 9.89464 27.2652 10 27 10H5C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9Z"
                      fill="#6F7174"
                    />
                  </svg>
                </Box>
              </div>
              <div className="copilot-datetime" style={{ paddingTop: "10px" }}>
                {loading ? (
                  <Skeleton.Input
                    active
                    size="small"
                    style={{ width: "180px" }}
                  />
                ) : (
                  <div>
                    <ButtonRangePicker
                      onFilter={handleFilter}
                      showIcon={false}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="showFilter" style={{ paddingLeft: isMobile ? "80%" : "158px" }}>
              <ButtonRangePicker showIcon={true} onFilter={handleFilter} />
            </div>
          )}
        </Grid>
        {/* </Grid> */}
      </Grid>

      <Grid item xl={12} xs={12} sx={{ pl: isMobile ? 0 : 2, marginLeft: isMobile ? 0 : "12px", marginBottom: "35px", marginTop: "14px" }}>
        {isMobile ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // marginBottom: "20px",
              overflow: "hidden",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: "#002857", fontFamily: "Plus Jakarta Sans" }}
            >
              Segments
            </Typography>
            <Switch
              onChange={() => setActiveTab(activeTab === 0 ? 1 : 0)}
              sx={{
                padding: "7px",
                color: "primary",
                "& .MuiSwitch-switchBase": {
                  transitionDuration: "800ms",
                },
                "& .MuiSwitch-track": {
                  borderRadius: 22 / 2,
                  // backgroundColor: "#002857",
                  opacity: 1,
                },
              }}
            />
            <Typography
              variant="body2"
              sx={{ fontWeight: 500, color: "#002857", fontFamily: "Plus Jakarta Sans" }}
            >
              Individuals
            </Typography>
          </Box>
        ) : (
          <StyledTabs
            value={activeTab}
            onChange={handleTabChange}
            indicatorColor="secondary"
            style={{ height: "35px" }}
          >
            <StyledTab label="Segments" disableRipple={true} // Disable the ripple effect on click
              disableFocusRipple={true} sx={{
                pl: 0, marginLeft: "0px", height: "35px", fontSize: "16px", fontFamily: "Plus Jakarta Sans", '&:hover': {
                  backgroundColor: "transparent", // no background on hover
                }, '&.Mui-selected': {
                  backgroundColor: "transparent", // no background when selected
                },
              }} />
            <StyledTab label="Individuals"
              disableRipple={true} // Disable the ripple effect on click
              disableFocusRipple={true}
              sx={{
                marginLeft: "45px", fontSize: "16px", fontFamily: "Plus Jakarta Sans", '&.Mui-selected': {
                  backgroundColor: "transparent", // no background when selected
                },
              }} />
          </StyledTabs>
        )}
      </Grid>
      <Grid item xs={12}>
        {activeTab === 0 ? (
          <SegmentsTab startDate={startDate} endDate={endDate} />     //filters.startDate
        ) : activeTab === 1 ? (
          <IndividualsTab startDate={startDate} endDate={endDate} />   //filters.endDate
        ) : null}
      </Grid>
    </Grid>

  );
};

export default Reports;
