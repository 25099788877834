import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import megaphoneIcon from "@iconify-icons/mdi/megaphone"
import keyIcon from "@iconify-icons/ph/key-bold"
import refreshIcon from "@iconify-icons/material-symbols/refresh"
import copyIcon from "@iconify-icons/ph/copy"
import docIcon from "@iconify-icons/ph/file-text"
import chatIcon from "@iconify-icons/ph/chat"
import { Icon } from "@iconify/react"
import { Skeleton, Tooltip } from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { getApiKeys, rotateApiKeys } from "../../redux/actions/apikeys"
import apiImg from "../../assets/img/api-bg.jpg";
import "./style.scss"

const ApiKeys = () => {
  const dispatch = useDispatch()
  const { accountInfo: { user_id } } = useSelector(
    (state) => state.settings
  )
  const { keys: { dby_key }, loading }  = useSelector(
    (state) => state.apikeys
  )
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleCopy = () => {
    setOpenTooltip(true)
    setTimeout(() => setOpenTooltip(false), 2000)
  }

  const handleRotate = () => {
    dispatch(rotateApiKeys({user_id}))
  }

  useEffect(() => {
    if (user_id) {
      dispatch(getApiKeys({user_id}))
    }

  }, [ user_id ])

  return (
    <div className="api-keys-page">
      <h1 className="title">APIs</h1>
      <h3 className="description">Create and support your own rich, data-driven experiences and applications</h3>
      <div className="api-keys-container">
        <div className="box">
          <img alt="API Keys Docs" className="w-full mb-20" src={apiImg} />
          <div className="col-item mb-20">
            <Icon icon={docIcon} className="mr-10" height="20px" width="20px" style={{ color: '#C8C8CE' }}/>
            Docs
          </div>
          <div className="col-item">
            <Icon icon={chatIcon} className="mr-10" height="20px" width="20px" style={{ color: '#C8C8CE' }}/>
            Chat with us
          </div>
        </div>
        {(!loading) ? (
          <div className="box">
            <div className="key-section mb-25">
              <Icon icon={keyIcon} className="col-item" style={{ color: '#707070', fontSize: '15px' }}/>
              <div className="col-item label">Production key</div>
              <div className="col-item api-key">
                <div className="api-key-txt">{dby_key ?? ''}</div>
                <Tooltip open={openTooltip} title="Copied!" >
                  <CopyToClipboard text={dby_key} onCopy={handleCopy}>
                    <Icon icon={copyIcon} className="col-item end copy" style={{ color: '#02AFEF', fontSize: '15px' }}/>
                  </CopyToClipboard>
                </Tooltip>
              </div>
              <Icon icon={refreshIcon} className="col-item rotate" style={{ color: '#02AFEF', fontSize: '23px' }} onClick={handleRotate}/>
            </div>
            <div className="info mb-20">
              <div className="col-item">
                <Icon icon={megaphoneIcon} height="20px" width="20px" style={{ color: '#02AFEF' }}/>
              </div>            
              <div className="col-item">Access to your API key should be limited to key personnel only.</div>
            </div>
            <div className="info">
              <div className="col-item">
                <Icon icon={megaphoneIcon} height="20px" width="20px" style={{ color: '#02AFEF' }}/>
              </div>             
              <div className="col-item">Rotating your key will require an update to your client application or service.</div>
            </div>
          </div>
        ) : (
          <div className="box">
            <div className="mb-20">
              <Skeleton.Avatar
                active
                size="small"
                shape="circle"
                style={{ marginRight: "5px" }}
              />
              <Skeleton.Input
                className="mr-10"
                active
                shape="round"
                size="small"
                style={{ width: "100%" }}
              />
              <Skeleton.Input
                className="hide-input mr-10"
                active
                shape="round"
                size="small"
                style={{ width: "100%" }}
              />
            </div>
            <div className="mb-20">
              <Skeleton.Input   
                className="w-full"
                active
                shape="round"
                size="small"
                style={{ width: "100%" }}
              />
            </div>
            <div>
              <Skeleton.Input
                className="w-full"
                active
                shape="round"
                size="small"
                style={{ width: "100%" }}
              />
            </div>            
          </div>
        )}
      </div>
    </div>
  )
}

export default ApiKeys