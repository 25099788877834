import { LOGOUT_REQUEST_SUCCESS, CLEAR_STORE } from "../constant/auth";
import {
  CREATE_ACCOUNT_SSO,
  CREATE_ACCOUNT_SSO_SUCCESS,
  CREATE_ACCOUNT_SSO_ERROR,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_ERROR,
  CREATE_ACCOUNT_SUCCESS,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
  RESEND_CODE_REQUEST,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
  GET_BASIC_INFO,
  GET_BASIC_INFO_SUCCESS,
  GET_BASIC_INFO_FAIL,
  SET_BASIC_INFO,
  SET_BASIC_INFO_SUCCESS,
  SET_LOCATION_INFO,
  SET_LOCATION_INFO_SUCCESS,
  SET_LOCATION_INFO_FAIL,
  SET_BASIC_INFO_FAIL,
  SET_COMPANY_INFO,
  SET_COMPANY_INFO_SUCCESS,
  SET_COMPANY_INFO_FAIL,
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_FAIL,
  CREATE_CHECKOUT_SESSION,
  CREATE_CHECKOUT_SESSION_SUCCESS,
  CREATE_CHECKOUT_SESSION_FAIL,
  UPDATE_ONBOARDING_STEP,
  UPDATE_ONBOARDING_STEP_SUCCESS,
  UPDATE_ONBOARDING_STEP_FAIL,
  CONFIRM_USER,
  CONFIRM_USER_SUCCESS,
  CONFIRM_USER_FAIL,

  SKIPPED_INFO_SUCCESS,
  SKIPPED_INFO_ERROR
} from "../constant/onboarding";
import { toast } from "react-toastify";

const initialState = {
  onboarding: {
    account: { email: null, provider: null, verified: false },
    basicInfo: {},
    companyInfo: {},
    planInfo: { email: null, plan: null, list: [], url: null },
    onboardingStep: null
  },
  isLoading: false,
  error: null,
};

const stepMapping = {
  at_basic_info: 0,
  at_business_info: 1,
  at_stripe: 2,
  completed: 3,
  at_location_info: 0.1,
};

export default function Onboarding(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STORE:
      return initialState;
    case UPDATE_ONBOARDING_STEP:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_ONBOARDING_STEP_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          onboardingStep: stepMapping[action.payload],
        },
        isLoading: false,
        error: null,
      };
    case UPDATE_ONBOARDING_STEP_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_ACCOUNT_SSO:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_ACCOUNT_SSO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onboarding: {
          ...state.onboarding,
          account: {
            ...state.onboarding.account,
            ...action.payload,
          },
        },
        error: null,
      };
    case CREATE_ACCOUNT_SSO_ERROR:
      toast.error("Creation of account failed");
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CONFIRM_USER:
      return {
        ...state,
        isLoading: true,
      };
    case CONFIRM_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        onboarding: {
          ...state.onboarding,
          account: {
            ...state.onboarding.account,
            verified: action.payload,
          },
        },
      };
    case CONFIRM_USER_FAIL:
      toast.error("Unable to Confirm User", action.payload.error);
      return {
        ...state,
        isLoading: false,
        onboarding: {
          ...state.onboarding,
          account: {
            ...state.onboarding.account,
            verified: action.payload.verified,
          },
        },
        error: action.payload.error,
      };
    case CREATE_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        onboarding: {
          ...state.onboarding,
          account: {
            ...state.onboarding.account,
            email: action.payload,
          },
        },
        error: null,
      };
    case CREATE_ACCOUNT_ERROR:
      toast.error(action.payload);
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case VERIFY_OTP_SUCCESS:
      toast.success("OTP verified");
      return {
        ...state,
        isLoading: false,
        onboarding: {
          ...state.onboarding,
          account: {
            ...state.onboarding.account,
            verified: action.payload,
            provider: "2FA"
          },
        },
        error: null,
      };
    case VERIFY_OTP_ERROR:
      toast.error("Verification of OTP failed");
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case RESEND_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case RESEND_CODE_SUCCESS:
      toast.success("Code resent");
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    case RESEND_CODE_ERROR:
      toast.error("Resend of code failed");
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GET_BASIC_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case GET_BASIC_INFO_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          basicInfo: {
            ...state.onboarding.basicInfo,
            ...action.payload,
          },
        },
        isLoading: false,
        error: null,
      };
    case GET_BASIC_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case SET_BASIC_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case SET_BASIC_INFO_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          basicInfo: {
            ...state.onboarding.basicInfo,
            ...action.payload,
          },
        },
        isLoading: false,
        error: null,
      };
    case SET_BASIC_INFO_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SET_COMPANY_INFO:
      return {
        ...state,
        isLoading: true,
      };
    case SET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          companyInfo: {
            ...state.onboarding.companyInfo,
            ...action.payload,
          },
        },
        isLoading: false,
        error: null,
      };
    
    case SET_COMPANY_INFO_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      case SET_LOCATION_INFO:
        return {
          ...state,
          isLoading: true,
        };
      case SET_LOCATION_INFO_SUCCESS:
        return {
          ...state,
          onboarding: {
            ...state.onboarding,
            locationInfo: {
              ...state.onboarding.locationInfo,
              ...action.payload,
            },
          },
          isLoading: false,
          error: null,
        };
        case SET_LOCATION_INFO_FAIL:
          return {
            ...state,
            isLoading: false,
            error: action.payload,
          };
    case GET_PRODUCT_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          planInfo: {
            ...state.onboarding.planInfo,
            list: action.payload,
          },
        },
        isLoading: false,
        error: null,
      };
    case GET_PRODUCT_LIST_FAIL:
      // toast.error("Failed to fetch Product list");
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CREATE_CHECKOUT_SESSION:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_CHECKOUT_SESSION_SUCCESS:
      return {
        ...state,
        onboarding: {
          ...state.onboarding,
          planInfo: {
            ...state.onboarding.planInfo,
            ...action.payload,
          },
        },
        isLoading: false,
        error: null,
      };
    case CREATE_CHECKOUT_SESSION_FAIL:
      // toast.error("Failed to create Checkout session");
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        onboarding: {
          account: { email: null, provider: null, verified: false },
          basicInfo: {},
          companyInfo: {},
          planInfo: { email: null, plan: null, list: [], url: null },
          onboardingStep: null,
        },
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
}
