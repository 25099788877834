import React, { useEffect, useState } from "react";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Box, styled, Typography, Avatar, Skeleton } from "@mui/material";
import "./style.scss";
import "../../../../pages/Reports/style.scss";
import Pagination from "@mui/material/Pagination";
import TopSellerImage from "../../../../assets/img/top-seller-img.svg";

const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: "#999999",
    fontWeight: "500",
    border: "1px solid #f4f4f4",
    backgroundColor: "white",
    "&.Mui-selected": {
      color: "#002857",
      backgroundColor: "white",
    },
    "& .MuiSvgIcon-root": {
      fill: "#002857",
    },
  },
}));

const TopSellersCard = (props) => {
  const { topSellers, isLoading } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(isLoading);
    }, 2000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  const formatAmount = (value) => {
    const hasDecimalParts = value % 1 !== 0;
    return hasDecimalParts ? value.toFixed(2) : value;
  };

  const ItemsPerPage = 4;
  const totalPages = Math.ceil(topSellers.length / ItemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * ItemsPerPage;
  const endIndex = startIndex + ItemsPerPage;
  const currentItems = topSellers.slice(startIndex, endIndex);

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const skeletonRows = () => {
    let results = [];
    for (let i = 0; i < 4; i++) {
      results.push(
        <Box key={i} className="topSellerBody">
          <Box className="topSellerLeftSide">
            <Skeleton variant="circular" className="skeletonAvatar" active />
            <Box sx={{ marginLeft: "16px" }}>
              <Typography variant="subtitle1" sx={{ fontSize: "15px" }}>
                <Skeleton className="skeletonSubHeading" active />
              </Typography>
              <Typography variant="caption" className="gray">
                <Skeleton className="skeletonText" active />
              </Typography>
            </Box>
          </Box>
          <Box className="topSellerRightSide">
            <Typography variant="subtitle2" className="dark-gray">
              <Skeleton className="skeletonText" active />
            </Typography>
            <Box>
              <Typography variant="subtitle2" className="dark-gray">
                <Skeleton className="skeletonText" active />
              </Typography>
              <Typography variant="caption" className="gray">
                <Skeleton className="skeletonText" active />
              </Typography>
            </Box>
            <Typography variant="subtitle2" className="dark-gray">
              <Skeleton className="skeletonText" active />
            </Typography>
            <Typography
              variant="body1"
              className="blue bold-700"
              sx={{ fontSize: "18px" }}
            >
              <Skeleton className="skeletonValue" active />
            </Typography>
          </Box>
        </Box>
      );
    }

    return results;
  };

  return (
    <Box className="topSellerCard">
      <Box className="cardHeader">
        <Typography variant="h6" className="blue"  style={{fontSize:"18px", fontWeight:"500"}}>
          Top Sellers
        </Typography>
        <MoreHorizOutlinedIcon sx={{ color: "black", marginLeft: "auto" }} />
      </Box>
      {loading
        ? skeletonRows()
        : currentItems?.map((seller, index) => (
            <Box key={index} className="topSellerBody">
              <Box className="topSellerLeftSide">
                <Avatar
                  alt="Top Seller"
                  src={TopSellerImage}
                  sx={{ display: "flex", width: 55, height: 55 }}
                />
                <Box sx={{ marginLeft: "16px" }} className="productAffinityNameSection">
                  <Typography variant="subtitle1" style={{fontSize:"14px", fontWeight:"500", color:"#002857"}}>
                    {seller.name}
                  </Typography>
                  <Typography variant="caption" className="gray">
                    {seller.description}
                  </Typography>
                </Box>
              </Box>
              <Box className="topSellerRightSide">
                <Typography variant="subtitle2" className="dark-gray">
                  Services
                </Typography>
                <Box>
                  <Typography variant="subtitle2" className="dark-gray">
                    Services
                  </Typography>
                  <Typography variant="caption" className="gray">
                    {seller.description}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" className="dark-gray">
                  Services
                </Typography>
                <Typography
                  variant="body1"
                  className="blue bold-700"
                  sx={{ fontSize: "18px" }}
                >
                  {formatAmount(seller.percentage)}%
                </Typography>
              </Box>
            </Box>
          ))}
      <Box className="cardFooter">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          sx={{ marginLeft: "auto" }}
        />
      </Box>
    </Box>
  );
};

export default TopSellersCard;
