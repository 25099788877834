import React, { useState } from "react";
import {
  Menu,
  Box,
  Container,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { getAllCutomerData } from "../../redux/actions/co-pliot";
import { Skeleton } from "antd";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../../hooks/useWindowSize";
import ButtonRangePicker from "../../components/common/ButtonRangePicker";
import CustomerDetailDrawer from "../../components/common/CustomerDetailDrawer";
import DashboardTab from "../../components/DashboardTab";

const filterFormat = "YYYY-MM-DD";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { customer, loadingCustomer } = useSelector((state) => state.coPilot);
  const { width } = useWindowSize();
  const isMobile = useMediaQuery({ maxWidth: 769 });

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("ALL");
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({
    startDate: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
    key: "selection",
  });

  // Event Handlers
  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    handleClose();
  };

  const handleFilter = (dates) => {
    if (dates.startDate && dates.endDate) {
      dispatch(
        getAllCutomerData({
          start_date: moment(dates.startDate).format(filterFormat),
          end_date: moment(dates.endDate).format(filterFormat),
        })
      );
    }
  };

  const onCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <Container maxWidth="-webkit-fill-available" style={isMobile ? { padding: "0px" } : {}}>
      <Grid container spacing={2}>
        {/* Header Section */}
        
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          style={{ display: "flex", flexWrap: "nowrap", }}
        >
     
            <Typography
              variant="h6"
              className="blue bold-600"
              sx={{ ml: isMobile ? 4 : "0", pt: isMobile ? 6 : "1.6%" }}
              style={{ flex: isMobile ? "0" : "1" }}
            >
              Customers
            </Typography>

            {/* Customer Detail Drawer */}
             <CustomerDetailDrawer
               isOpen={isDrawerOpen}
               onClose={onCloseDrawer}
               customer={customer}
               isMobile={isMobile}
               isLoading={loadingCustomer}

                  style={{ justifyContent:"center"}}
               onLoadData={() => dispatch(getAllCutomerData())}
             />
             
            {/* Menu Button */}
            {width > 1000 && (
            <div onClick={handleIconClick} style={{cursor: "pointer",alignItems:"center"}}>
              <Box sx={{  marginTop:"9px", borderRadius: 20}} >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 32 32"
                  fill="none"
                
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 21C12 20.7348 12.1054 20.4804 12.2929 20.2929C12.4804 20.1054 12.7348 20 13 20H19C19.2652 20 19.5196 20.1054 19.7071 20.2929C19.8946 20.4804 20 20.7348 20 21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21ZM8 15C8 14.7348 8.10536 14.4804 8.29289 14.2929C8.48043 14.1054 8.73478 14 9 14H23C23.2652 14 23.5196 14.1054 23.7071 14.2929C23.8946 14.4804 24 14.7348 24 15C24 15.2652 23.8946 15.5196 23.7071 15.7071C23.5196 15.8946 23.2652 16 23 16H9C8.73478 16 8.48043 15.8946 8.29289 15.7071C8.10536 15.5196 8 15.2652 8 15ZM4 9C4 8.73478 4.10536 8.48043 4.29289 8.29289C4.48043 8.10536 4.73478 8 5 8H27C27.2652 8 27.5196 8.10536 27.7071 8.29289C27.8946 8.48043 28 8.73478 28 9C28 9.26522 27.8946 9.51957 27.7071 9.70711C27.5196 9.89464 27.2652 10 27 10H5C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9Z"
                    fill="#6F7174"
                  />
                </svg>
              </Box>
            </div>
          )}

       
          <Grid item xl={2.5} lg={4} md={4} xs={4} style={{  }}>
            {width > 1000 ? (
              <div>
                {loading ? (
                  <Skeleton.Input active size="small" style={{ width: "180px" }} />
                ) : (
                  <ButtonRangePicker onFilter={handleFilter} showIcon={false} />
                )}
              </div>
            ) : (
              <div className="showFilter" style={{ paddingLeft: isMobile ? "74%" : "158px" }}>
                <ButtonRangePicker showIcon={true} onFilter={handleFilter} />
              </div>
            )
            }
          </Grid>

         
          {/* {width > 1000 && (
            <div onClick={handleIconClick} style={{ cursor: "pointer", alignSelf: "flex-end" }}>
              <Box sx={{ ml: isMobile ? 2 : 0, pr: isMobile ? 0 : 2, borderRadius: 20 }}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 21C12 20.7348 12.1054 20.4804 12.2929 20.2929C12.4804 20.1054 12.7348 20 13 20H19C19.2652 20 19.5196 20.1054 19.7071 20.2929C19.8946 20.4804 20 20.7348 20 21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H13C12.7348 22 12.4804 21.8946 12.2929 21.7071C12.1054 21.5196 12 21.2652 12 21ZM8 15C8 14.7348 8.10536 14.4804 8.29289 14.2929C8.48043 14.1054 8.73478 14 9 14H23C23.2652 14 23.5196 14.1054 23.7071 14.2929C23.8946 14.4804 24 14.7348 24 15C24 15.2652 23.8946 15.5196 23.7071 15.7071C23.5196 15.8946 23.2652 16 23 16H9C8.73478 16 8.48043 15.8946 8.29289 15.7071C8.10536 15.5196 8 15.2652 8 15ZM4 9C4 8.73478 4.10536 8.48043 4.29289 8.29289C4.48043 8.10536 4.73478 8 5 8H27C27.2652 8 27.5196 8.10536 27.7071 8.29289C27.8946 8.48043 28 8.73478 28 9C28 9.26522 27.8946 9.51957 27.7071 9.70711C27.5196 9.89464 27.2652 10 27 10H5C4.73478 10 4.48043 9.89464 4.29289 9.70711C4.10536 9.51957 4 9.26522 4 9Z"
                    fill="#6F7174"
                  />
                </svg>
              </Box>
            </div>
          )} */}

          
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                borderRadius: "10px",
              },
            }}
          >
            <MenuItem
              style={{
                backgroundColor: "#e0f7fa",
                color: "#002857",
                fontSize: "13px",
                paddingRight: "150px",
                fontWeight: "500",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              onClick={() => handleSelectOption("ALL")}
            >
              All
            </MenuItem>
            <MenuItem
              style={{
                color: "#002857",
                fontSize: "13px",
                paddingRight: "150px",
                fontWeight: "500",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              onClick={() => handleSelectOption("onsite")}
            >
              Onsite
            </MenuItem>
            <MenuItem
              style={{
                color: "#002857",
                fontSize: "13px",
                paddingRight: "150px",
                fontWeight: "500",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              onClick={() => handleSelectOption("Online")}
            >
              Online
            </MenuItem>
          </Menu>
        </Grid>

        {/* Dashboard Content */}
        <Grid pt={2} xs={12} id="feed">
        <DashboardTab isMobile={isMobile} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
