import { useEffect, Fragment } from "react"
import { Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { Skeleton } from "antd"
import clsx from 'clsx'
import moment from "moment"
import numeral from 'numeral'

import { getPaymentTransactions } from "../../../../redux/actions/payment"
import { hide } from "../../../../redux/actions/modals"
import LogoDesktop from "../../../../assets/img/logo-desktop.svg"
import McIcon from "../../../../assets/img/icons/mc.svg"
import VisaIcon from "../../../../assets/img/icons/visa.svg"
import EllipseIcon from "../../../../assets/img/icons/ellipse-gray.svg"
import EyeIcon from "../../../../assets/img/icons/eye.svg"

import "./style.scss"

export default function PaymentHistoryModal() {
  const dispatch = useDispatch()
  const { accountInfo: { user_id } } = useSelector(
    (state) => state.settings
  )
  const { transactions, loading } = useSelector(
    (state) => state.payment
  )
  const { data: subscription } = useSelector((state) => state.subscription)

  const handleHide = () => {
    dispatch(hide()) 
  }

  useEffect(() => {
    if (user_id) {
      dispatch(getPaymentTransactions({user_id}))
    }
  }, [ user_id ])

  const getBrandDescription = ({brand, last4}) => {
    switch (brand) {
      case 'mastercard':
        return <div className="history-pm">
          <img src={McIcon} alt=""/>
          <div className="last-four">
            <img src={EllipseIcon} alt=""/> 
            <img src={EllipseIcon} alt=""/> 
            <img src={EllipseIcon} alt=""/> 
            {last4}
          </div>
        </div>
      case 'visa':
        return <div className="history-pm">
          <img className="icon" src={VisaIcon} alt=""/>
          <div className="last-four">
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            {last4}
          </div>
        </div>
      default:
        return <div className="history-pm"> 
          <div className="last-four">
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            {last4}
          </div>
        </div>
    }
  }

  return (
    <Modal 
      title={<img src={LogoDesktop} alt="Desktop Logo" />}
      open 
      onCancel={handleHide}
      className="payment-modal"
      footer={null}
    >
      <div className="payment-history-container">
        <div className="payment-history">
          <h3 className="payment-history-title">Payment History</h3>
          {subscription && 
            <Fragment>
              <div className="payment-history-subtitle mt-25 mb-10">Your Plan</div>
              <div className="plan-info">
                <div className="plan-desc">
                  <div className="name mb-5">{subscription.product.name}</div>
                  <div>Your next billing date is {moment.unix(subscription.current_period_end).format("MMMM D, YYYY")}</div>
                </div>
                <div className="plan-price">
                  <div>{numeral((subscription.amount / 100)).format('($0,0)')}</div>
                </div>                
              </div>          
            </Fragment>
          }
          <div className="payment-history-list w-full">
            {(!loading) ? (
              <div>
                <div className="payment-history-subtitle mt-25 mb-10">Transaction History</div>
                <div className="payment-history-table">
                  <div className="payment-history-table-header row">
                    <div className="col col-date">Date</div>
                    <div className="col col-desc">Description</div>
                    <div className="col col-pm">Payment Method</div>
                    <div className="col col-invoice">Invoice</div>
                    <div className="col col-total">Total</div>
                  </div>
                  {transactions?.data?.map(({ id, created, description, brand, last4, amount, receipt_url }, i) => (
                    <div key={i} className={clsx("payment-history-item row", (((i + 1) % 2) === 1) ? 'odd' : 'even' )}>
                      <div className="col col-date">{moment.unix(created).format("MM/DD/YYYY")}</div>
                      <div className="col col-desc">{description}</div>
                      <div className="col col-pm">{getBrandDescription({brand, last4})}</div>
                      <div className="col col-invoice">
                        <a href={receipt_url} target="_blank" rel="noopener noreferrer"><img src={EyeIcon} alt=""/> View</a>
                      </div>
                      <div className="col col-total">{numeral((amount / 100)).format('($0,0.00)')}</div>
                    </div>
                  ))}              
                </div>
              </div>
            ) : (
              <div className="w-full mt-25">
                <div className="mb-15">
                  <Skeleton.Input   
                    className="w-full"
                    active
                    shape="round"
                    size="small"
                    style={{ width: "100% !important" }}
                  />
                </div>
                <div className="mb-15">
                  <Skeleton.Input
                    className="w-full"
                    active
                    shape="round"
                    size="small"
                    style={{ width: "100% !important" }}
                  />
                </div>
                <div className="mb-15">
                  <Skeleton.Input
                    className="w-full"
                    active
                    shape="round"
                    size="small"
                    style={{ width: "100% !important" }}
                  />
                </div>            
                <div>
                  <Skeleton.Input
                    className="w-full"
                    active
                    shape="round"
                    size="small"
                    style={{ width: "100% !important" }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}