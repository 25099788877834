import axios from "axios";
import { userPool } from "../../utils/aws-config";
import {
  GET_SUMMARYDATA_REQUEST,
  GET_SUMMARYDATA_REQUEST_SUCCESS,
  GET_SUMMARYDATA_REQUEST_ERROR,
  GET_ALL_CUSTOMERS_REQUEST,
  GET_ALL_CUSTOMERS_REQUEST_ERROR,
  GET_ALL_CUSTOMERS_REQUEST_SUCCESS,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_REQUEST_ERROR,
  GET_CUSTOMER_REQUEST_SUCCESS,
  GET_ASYNC_DATA,
  GET_ASYNC_DATA_ERROR,
  GET_ASYNC_DATA_SUCCESS,
  UPDATE_RATES_SUCCESS,
  GET_USER_RATE,
  GET_USER_RATE_ERROR,
  GET_USER_RATE_SUCCESS,
  CREATE_RATE,
  CREATE_RATE_ERROR,
  CREATE_RATE_SUCCESS,
  UPDATE_PERSONA,
  UPDATE_PERSONA_ERROR,
  UPDATE_PERSONA_SUCCESS,
  RESET_STATE_CO_PILOT,
  GET_ALL_PERSONA,
  GET_ALL_PERSONA_ERROR,
  GET_ALL_PERSONA_SUCCESS,
} from "../constant/co-pilot";

const getAxiosConfig = () => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.getSession(function (err, session) {
      if (err) {
        reject(err);
      } else {
        const axiosConfig = {
          headers: {
            Authorization: `${session.accessToken.jwtToken}`,
           // Accept: "application/json",
            // "User-Agent": "Mozilla",
            // "User-Agent": "axios 0.21.1",
          },
        };
        // console.log(
        //   `this is the axios config = ${JSON.stringify(axiosConfig)}`
        // )
        resolve(axiosConfig);
      }
    });
  });
};

export const getSummaryData = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SUMMARYDATA_REQUEST });
    const config = await getAxiosConfig();
    const { data } = await axios.get(
      `${process.env.REACT_APP_COPILOT_SUMMARY_API_URL}/dev/copilot/summary`,
      config
    );
    dispatch({ type: GET_SUMMARYDATA_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    console.log(`error in getting axios ${error}`);
    dispatch({ type: GET_SUMMARYDATA_REQUEST_ERROR, payload: error });
  }
};

export const getAllCutomerData = (filters) => async (dispatch) => {
  try {
    console.log("filter: ", filters);
    dispatch({ type: GET_ALL_CUSTOMERS_REQUEST });
    const config = await getAxiosConfig();
    let query = "";
    if (filters) {
      query = `?start_date=${filters.start_date || ""}&end_date=${
        filters.end_date || ""
      }`;
    }
    const { data } = await axios.get(
      `${process.env.REACT_APP_COPILOT_CUSTOMERS_API_URL}/dev/copilot/customers${query}`,
      config
    );
    console.log("getAllCutomerData: ", data);
    dispatch({ type: GET_ALL_CUSTOMERS_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_CUSTOMERS_REQUEST_ERROR, payload: error });
  }
};

export const getCustomerData = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_CUSTOMER_REQUEST });
    const config = await getAxiosConfig();
    const { data } = await axios.get(
      `${process.env.REACT_APP_COPILOT_CUSTOMER_API_URL}/dev/copilot/customer/?id=${id}`,
      config
    );
    dispatch({ type: GET_CUSTOMER_REQUEST_SUCCESS, payload: { ...data, id } });
  } catch (error) {
    dispatch({ type: GET_CUSTOMER_REQUEST_ERROR, payload: error });
  }
};

export const getAsyncData = () => async (dispatch) => {
  try {
    await dispatch({ type: GET_ASYNC_DATA });
    const config = await getAxiosConfig();
    const { data } = await axios.get(
      `${process.env.REACT_APP_SNOWFLAKE_RENDER_API_URL}/dev/snowflake_render`,
      config
    );
    dispatch({ type: GET_ASYNC_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ASYNC_DATA_ERROR, payload: error });
  }
};

export const getUserRateWithId = (userId) => async (dispatch) => {
  try {
    await dispatch({ type: GET_USER_RATE });
    const config = await getAxiosConfig();
    const { data } = await axios.get(
      `${process.env.REACT_APP_RATING_REVIEW_API_URL}/dev/customer/rating_review/?id=${userId}`,
      config
    );
    console.log(">> data: ", data);
    dispatch({ type: GET_USER_RATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_USER_RATE_ERROR, payload: error });
  }
};

export const createRating = (body) => async (dispatch) => {
  try {
    await dispatch({ type: CREATE_RATE });
    const config = await getAxiosConfig();
    const { data } = await axios.post(
      `${process.env.REACT_APP_RATING_REVIEW_API_URL}/dev/customer/rating_review`,
      body,
      config
    );
    console.log(">> data: ", data);
    dispatch({ type: CREATE_RATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CREATE_RATE_ERROR, payload: error });
  }
};

export const updatePersona = (body) => async (dispatch) => {
  try {
    await dispatch({ type: UPDATE_PERSONA });
    const config = await getAxiosConfig();
    const { data } = await axios.post(
      `${process.env.REACT_APP_PERSONA_API_URL}/dev/copilot/customer/change_persona`,
      body,
      config
    );
    dispatch({ type: UPDATE_PERSONA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_PERSONA_ERROR, payload: error });
  }
};

export const updateSecondaryPersona = (body) => async (dispatch) => {
  try {
    await dispatch({ type: UPDATE_PERSONA });
    const config = await getAxiosConfig();
    const { data } = await axios.post(
      `${process.env.REACT_APP_PERSONA_API_URL}/dev/copilot/customer/change_secondary_persona`,
      body,
      config
    );
    dispatch({ type: UPDATE_PERSONA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_PERSONA_ERROR, payload: error });
  }
};

export const updateRatesData = (rates) => async (dispatch) => {
  dispatch({ type: UPDATE_RATES_SUCCESS, payload: rates });
};

export const resetStateCoPilot = () => async (dispatch) => {
  dispatch({ type: RESET_STATE_CO_PILOT });
};

export const getAllPersona = () => async (dispatch) => {
  try {
    await dispatch({ type: GET_ALL_PERSONA });
    const config = await getAxiosConfig();
    const { data } = await axios.get(
      `${process.env.REACT_APP_PERSONA_API_URL}/dev/copilot/customer/personas`,
      config
    );
    console.log(">> data: ", data);
    dispatch({ type: GET_ALL_PERSONA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_PERSONA_ERROR, payload: error });
  }
};
