import { handleActions } from 'redux-actions'

import {
  SHOW,
  HIDE,
  SET_DATA,
} from "../constant/modals"

const initialState = {
  visible: null,
  data: null,
};

export default handleActions(
  {
    [HIDE]: () => initialState,
    [SHOW]: (state, { payload: { visible, data } }) => ({ visible, data }),
    [SET_DATA]: (state, { payload }) => ({ ...state, data: payload }),
  },
  initialState,
)