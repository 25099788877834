import { Modal } from "antd";
import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import "./style.scss";
import { PlanList } from "../../../../pages/Onboarding/constants";

const ModalPlanDetail = ({ open, onClose, selectedPlan}) => {
    const plan = PlanList.find(p => p.id === selectedPlan);

    return (
        <Modal
        wrapClassName="modalDetail"
        open={open}
        onCancel={onClose}
        title=""
        footer={null}
      >
        <h3 style={{ color: "rgba(2, 175, 239, 1)", fontSize: "0.9rem", fontWeight: "500" }}>PLAN DETAILS</h3>
        <h3 className="title">{plan.title}</h3>
        <Grid container spacing={2} style={{}}>
          {/* Ensuring the Grid container takes the full width */}
          {plan.points && Array.isArray(plan.points) ? (
            plan.points.map((point, index) => (
              // Set Grid item to take up 6 columns on xs breakpoint, making two items per row
              <Grid item xs={12} md={6} key={index}  style={{ whiteSpace:"nowrap"}}>
                <ListItem>
                  <ListItemIcon>
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1867_2047)">
                        <path d="M7.5 0C3.35789 0 0 3.35789 0 7.5C0 11.6423 3.35789 15 7.5 15C11.6423 15 15 11.6423 15 7.5C15 3.35789 11.6423 0 7.5 0ZM7.5 14.0773C3.88148 14.0773 0.9375 11.1185 0.9375 7.49997C0.9375 3.88145 3.88148 0.937471 7.5 0.937471C11.1185 0.937471 14.0625 3.88147 14.0625 7.49997C14.0625 11.1185 11.1185 14.0773 7.5 14.0773ZM10.4932 4.7557L6.0928 9.18375L4.11116 7.20211C3.92811 7.01906 3.63139 7.01906 3.44811 7.20211C3.26506 7.38516 3.26506 7.68187 3.44811 7.86492L5.76819 10.1852C5.95124 10.368 6.24795 10.368 6.43124 10.1852C6.45233 10.1641 6.47039 10.1412 6.4868 10.1173L11.1565 5.41874C11.3393 5.23569 11.3393 4.93897 11.1565 4.7557C10.9732 4.57266 10.6765 4.57266 10.4932 4.7557Z" fill="#02AFEF"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_1867_2047">
                          <rect width="15" height="15" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </ListItemIcon>
                  <ListItemText primary={point} />
                </ListItem>
              </Grid>
            ))
          ) : (
            <p>No points available for this plan.</p>
          )}
        </Grid>
      </Modal>
      
    );
   
};

export default ModalPlanDetail;
