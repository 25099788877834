import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { getAllPersonas } from "../../../redux/actions/dashboard";
import "./style.scss";
import "../../../pages/Reports/style.scss";
import { Typography } from "antd";

export default function ConsentedDialog(props) {
    const { onClose, selectedValue, open, dialogValue } = props;
    const [personaData, setPersonaData] = useState();
    const { personas } = useSelector((state) => state.dashboard);
    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    useEffect(() => {
        //    setLoading(true);
        dispatch(getAllPersonas(token));
    }, [token]);
    const handleClose = () => {
        onClose(selectedValue);
    };
    useEffect(() => {
        if (personas) {
            setPersonaData(personas);
        }
        console.log("persona", personas)
    }, [personas]);

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <div style={{ width: "fit-content", margin: "10%" }}>
                <div style={{marginBottom:"5%", fontSize:"15px", fontWeight:"600", color:"#02AFEF"}}>DEFINITION</div>
                {dialogValue == "consented" ?  <Typography style={{marginBottom:"5%", fontSize:"18px", fontWeight:"600"}}>Consented </Typography>:
               <Typography style={{marginBottom:"5%", fontSize:"18px", fontWeight:"600"}}>Sharing data </Typography>
               }
               {dialogValue == "consented" ? <span style={{color:"#002857", fontSize:"14px"}}>When present on the customer profile, “consented” has the meaning that your customer consents to sharing their data with your business, and your business only for the purpose of improving their experience. No data is shared with any third parties.
            </span>:<span style={{color:"#002857", fontSize:"14px"}}>
                When present on the customer profile, “sharing data” has the meaning that your customer consents to sharing their data with your business, qualified third parties and businesses across the DataBillity network, all for the purpose of improving and marketing relevant products, services and experiences.</span>}
            </div>
        </Dialog>
    );
}

ConsentedDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};