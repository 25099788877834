import { useSelector } from "react-redux"

import { types } from "../../redux/constant/modals"
import PaymentMethodsModal from "../settings/subscription/PaymentMethod/PaymentMethodsModal"
import AddPaymentMethodModal from "../settings/subscription/PaymentMethod/AddPaymentMethodModal"
import UpdatePaymentMethodModal from "../settings/subscription/PaymentMethod/UpdatePaymentMethodModal"
import PaymentHistoryModal from "../settings/subscription/PaymentHistory/PaymentHistoryModal"
import CancelSubscriptionModal from "../settings/subscription/CancelSubscription/CancelSubscriptionModal"

const ModalSwitcher = () => {
  const { visible, data } = useSelector(
    (state) => state.modals
  )

  switch (visible) {
    case types.PAYMENT_METHODS:
      return <PaymentMethodsModal type={visible} {...data} />
    case types.ADD_PAYMENT_METHOD:
      return <AddPaymentMethodModal type={visible} {...data} />  
    case types.EDIT_PAYMENT_METHOD:
      return <UpdatePaymentMethodModal type={visible} {...data} />
    case types.PAYMENT_HISTORY:
      return <PaymentHistoryModal type={visible} {...data} />
    case types.CANCEL_SUBSCRIPTION:
      return <CancelSubscriptionModal type={visible} {...data} />            
    default:
      return null
  }
}

export default ModalSwitcher