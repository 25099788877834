import { Form, Input, Row, Col, Button, Select } from "antd";
import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountOrgInfo, updateAccountInfo} from "../../../redux/actions/settings";
import { useMediaQuery } from "react-responsive";

import "./form-profile.scss";

const FormOrganization = ({ isCancel = true, onCancel }) => {
  const handleFinish = (values) => {
    console.log(values);
  };
  const { accountInfo } = useSelector(
    (state) => state.settings
  );
  const dispatch = useDispatch();
  const formatPhoneNumber = (phoneNumber) => {
    // Check if the phone number is a valid 10-digit number
    if (!phoneNumber || phoneNumber.length !== 10 || isNaN(phoneNumber)) {
      return phoneNumber; // Return as is if it's not a valid number
    }
    
    // Format the number as 999-999-9999
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  };
  const [formData, setFormData] = useState({
    company_legal_name: accountInfo?.company_legal_name || '',
    website: accountInfo?.website|| '',
    main_phone: formatPhoneNumber(accountInfo?.main_phone)|| '',
    email: accountInfo.email || '',
    main_email: accountInfo?.main_email || '',
    address_line_1: accountInfo?.address_line_1 || '',
    address_line_2: accountInfo?.address_line_2 || '',
    postal_code: accountInfo?.postal_code || '',
    city: accountInfo?.city || '',
    country: accountInfo?.country || '',
    province: accountInfo.state_province || '',
    state: accountInfo.state_province || ''
  });
  const [coFormData, setCoFormData] = useState({
    firstName: accountInfo.first_name || '',
    lastName: accountInfo.last_name || '',
    phone: accountInfo.phone || '',
    email: accountInfo.email || '',
    title: accountInfo.job_role || '',
    role: accountInfo.account_level_role || '',
    streetAddress: accountInfo.address_line_1 || '',
    unit: accountInfo.address_line_2 || '',
    city: accountInfo.city || '',
    province: accountInfo.state_province || '',
    zip: accountInfo.postal_code || '',
    country: accountInfo.country || 'US',
    state: accountInfo.state_province || ''
  });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const handleInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'country' && { state: null }) // Reset state when country changes
    }));
    setCoFormData(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'country' && { state: null }) // Reset state when country changes
    }));
  };
  const stateOptions = {
    US: ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
      "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", 
      "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", 
      "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", 
      "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", 
      "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", 
      "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", 
      "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", 
      "Washington", "West Virginia", "Wisconsin", "Wyoming"],
    Canada: [  "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon"]
  };
  //  const submitData = () =>{
  //   console.log("formData",formData)
  //   dispatch(updateAccountOrgInfo(formData));
  //   dispatch(updateAccountInfo(coFormData))
  //    onCancel();
  //  }
  const submitData = () => {
    try {
     // console.log("Submitting form data:", formData);
      
 dispatch(updateAccountOrgInfo(formData));
    //  window.location.href = window.location.href; 

      
      onCancel(); 
    } catch (error) {
      console.error("Failed to update account info:", error);
    }
  };
  console.log("disData",formData.main_phone
  );
  return (
    <Form className="formProfile" layout="vertical" onFinish={handleFinish}>
      <Row gutter={[54, 15]}>
        <Col md={12} xs={24}>
          <Form.Item
            label="Legal Name"
            name="company_legal_name"
            rules={[
              {
                required: true,
                message: "Legal name is required",
              },
            ]}
          >
            <Input placeholder="" 
             value={formData?.company_legal_name}
             defaultValue={formData?.company_legal_name}
             onChange={(e) => handleInputChange('company_legal_name', e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Website"
            name="website"
            rules={[
              {
                required: true,
                message: "Website is required",
              },
            ]}
          >
            
            <Input placeholder="" defaultValue={formData?.website} onChange={(e) => handleInputChange(
              "website", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,
                message: "Phone number is required",
              },
            ]}
          >
            <Input placeholder="" defaultValue={formatPhoneNumber(formData?.main_phone)} onChange={(e) => handleInputChange(
              "main_phone", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Main Email" name="Main Email">
            <Input placeholder="" defaultValue={formData?.main_email} onChange={(e) => handleInputChange(
              "main_email", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Address line 1" name="Address line 1">
          <Input placeholder="" defaultValue={formData?.address_line_1} onChange={(e) => handleInputChange(
              "address_line_1", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Address line 2" name="Address line 2">
          <Input placeholder="" defaultValue={formData?.address_line_2} onChange={(e) => handleInputChange(
              "address_line_2", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
        <Form.Item 
  label={formData.country === 'Canada' ? 'Postal Code' : formData.country === 'US' ? 'Zip Code' : 'Postal Code'}
  name="postal_code"
>
  <Input 
    placeholder=""
    defaultValue={formData?.postal_code} 
    onChange={(e) => handleInputChange('postal_code', e.target.value)} 
  />
</Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="City" name="City">
            <Input placeholder="" defaultValue={formData?.city} onChange={(e) => handleInputChange(
              "city", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label= {formData.country==='Canada'?'Province':formData.country==='US'?'State':'State or Province'} name="state">
            <Select value={formData.state} 
             placeholder={
              formData.state
                ? formData.state 
                : formData.country === 'Canada'
                ? 'Select a Province'
                : formData.country === 'US'
                ? 'Select a State'
                : 'Select an option'
            }name="state"
            onChange={value => handleInputChange('state', value)}>
              {formData?.country && stateOptions[formData?.country]?.map(state => (
                <Select.Option key={state} value={state}>{state}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Country" name="country">
            <Select defaultValue={formData.country} onChange={value => handleInputChange('country', value)}
              suffixIcon={
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                    fill="#002857"
                  />
                </svg>
              }
              >
              <option value="US">United States</option>
              <option value="Canada">Canada</option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <div className="actions"style={{display:isMobile?"flex":"",justifyContent:isMobile?"center":""}}>
            {isCancel && (
              <Button
                className="btnCancel"
                htmlType="button"
               onClick={onCancel}
              >
                Cancel
              </Button>
            )}
            <Button className="btnSave"style={{marginBottom:"5%"}} type="primary" htmlType="submit"  onClick={submitData}>
Submit
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default FormOrganization;
