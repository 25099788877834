import { React, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  CircularProgress,
} from "@mui/material";
import StepIcon from "../../assets/img/step-icon.svg";
import ActiveStepIcon from "../../assets/img/active-step-icon.svg";
import CompletedStepIcon from "../../assets/img/completed-step-icon.svg";
import BasicInformation from "../../components/Onboarding/BasicInformation";
import CompanyInformation from "../../components/Onboarding/CompanyInformation";
import CompanyInfoForm from "../../components/Onboarding/CompanyInformation/CompanyName.js";
import ChoosePlan from "../../components/Onboarding/ChoosePlan";
import Footer from "../../components/Layout/Footer";
import Images from "../../assets/img/ImgConstants.jsx";
import { steps } from "./constants.js";
import LoginHeader from "../../components/Layout/LoginHeader";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  createAccountSSO,
  onboardingStep,
} from "../../redux/actions/onboarding.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Onboarding = () => {
  const companyinfo = (localStorage.getItem('companyLegalName'));
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1200 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onboarding } = useSelector(
    (state) => state.onboarding
  );
  const [backClicked, setBackClicked] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [storageChanged, setStorageChanged] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get("code");

    if (authorizationCode) {
      const domain = process.env.REACT_APP_COGNITO_DOMAIN;
      const clientId = process.env.REACT_APP_AWS_CLIENT_ID;
      const redirectUri = process.env.REACT_APP_REDIRECT_URI_LOGIN_STAGING;
      const tokenUrl = `${domain}/oauth2/token`;

      const params = new URLSearchParams();
      params.append("grant_type", "authorization_code");
      params.append("client_id", clientId);
      params.append("redirect_uri", redirectUri);
      params.append("code", authorizationCode);

      axios
        .post(tokenUrl, params)
        .then((response) => {
          const { id_token, access_token, refresh_token } = response.data;

          // Store the tokens in localStorage and redux store > Confirm user in DB > fetch onboarding step
          dispatch(createAccountSSO(id_token, access_token, refresh_token, navigate));
        })
        .catch((error) => {
          console.error("Error exchanging code for tokens:", error);
        });
    }
  }, [navigate]);

  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     setStorageChanged((prev) => !prev);
  //   };

  //   window.addEventListener("storage", handleStorageChange);

  //   // Clean up event listener on component unmount
  //   return () => { window.removeEventListener("storage", handleStorageChange); };
  // }, []);

  // // If user is coming from sso, create account by saving tokens to store and getting user email
  // useEffect(() => {
  //   if (Object.keys(localStorage).some((key) => key.includes("CognitoIdentityServiceProvider")))
  //     dispatch(createAccountSSO());
  // }, [storageChanged]);



  // If onboarding step is 3, redirect to home page, if not got to specific onboarding step, unless coming back from company information page
  // Redirect to your desired page after successful login
  useEffect(() => {
    if (onboarding.account.verified === true && !backClicked && (localStorage.getItem("databillity_token") !== null || onboarding.account.provider=== "2FA") ) {
      if (onboarding.onboardingStep === 3) navigate("/customers");
      else setLoading(false);
    } 
  }, [onboarding.account.verified, onboarding.onboardingStep]);

  //  On reload, get onboarding step
  useEffect(() => {
    if (onboarding.account.email !== null && onboarding.account.verified === true)
      dispatch(onboardingStep(onboarding.account.email));
  }, []);

  const StepIconComponent = (index) => {
    if (index < onboarding.onboardingStep) {
      return (
        <img
          src={CompletedStepIcon}
          alt="Completed step"
          className="stepperIcon" style={{marginRight:isMobile?'0px':"4px",marginLeft:isMobile?"-12px":"0px",marginTop:isMobile?"4%":"0%"}}
        />
      );
    } else if (index === onboarding.onboardingStep) {
      // Active step should be solid blue
      return (
        <img
          src={ActiveStepIcon}
          alt="Active step"
          className="stepperIcon" style={{marginRight:'4px'}}
        />
      );
    } else {
      // Incomplete steps should be outlined blue
      return (
        <div   className="stepperIcon"
          style={{
            width: isMobile ? "9px" : "24px",  
    height: isMobile ? "9px" : "24px",
    
            borderRadius: "50%",
            // border: "2px solid #002857", // Outline style for incomplete steps
            display: "flex",
            alignItems:isMobile? "none":"center",
            justifyContent: "center",
            marginRight:isMobile?'0px':"8px",
            marginLeft:'2px'
          }}
        >
          <img
            src={StepIcon}
            alt="Step"
            style={{ opacity: 1.5 }} // Adjust opacity to make it appear inactive
            
          />
        </div>
      );
    }
    
  };
  
  

  return loading || onboarding.onboardingStep === 3 ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <CircularProgress />
    </Box>
  // ) : onboarding.onboardingStep === 3 ? (
  //   <Confirmation />
  ) : (
    <Container maxWidth="xxl" className="basicInformation">
      <Grid container>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box>
            <LoginHeader />
          </Box>
        </Grid>
        {isMobile || isTablet ? (
          <Grid item md={12} sm={12} xs={12}>
            <Box className="progressGraphMobile">
              <div style={{marginTop:"-13px"}}></div>
              <Stepper
                connector={Images.ACTIVE_STEPPER_ARROW_CONNECTOR}
                activeStep={onboarding.onboardingStep}
              >
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel
                      StepIconComponent={() => StepIconComponent(index)}
                      StepConnectorComponent={() =>
                        index === onboarding.onboardingStep
                          ? Images.ACTIVE_STEPPER_ARROW_CONNECTOR
                          : Images.STEPPER_ARROW_CONNECTOR
                      }
                    >
                     <Typography
  variant="body1"
  className={
    index > onboarding.onboardingStep
      ? `activeStepperTex`
      : `activeStepperText`
  }
  style={{ fontSize:isMobile?"9px":"18px" }} 
>
  {step.label}
</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        ) : null}

        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}style={{minHeight:isMobile?'86vh':"100vh"}}>
        {onboarding.onboardingStep === 0 ? (
            <BasicInformation />
          ) : onboarding.onboardingStep === 1 && companyinfo!=null  ? (
            <CompanyInformation setBackClicked={setBackClicked} />
          ) : onboarding.onboardingStep === 2 ? (
            <ChoosePlan />):
            onboarding.onboardingStep === 0.1 || (onboarding.onboardingStep === 1 && companyinfo==null) ? 
              <CompanyInfoForm setBackClicked={setBackClicked} />    
           : null}
        </Grid>
        {!isMobile && !isTablet ? (
          <Grid item xl={4} lg={4} md={4}>
            <Box className="infoPageRightSection">
              <Box className="progressGraph">
                <Stepper
                  activeStep={onboarding.onboardingStep}
                  orientation="vertical"
                  sx={{
                    "& .MuiStepContent-root": {
                      borderColor: "#002857",
                    },
                    "& .MuiStepConnector-line": {
                      borderColor: "#002857",
                      minHeight: "60px",
                      marginLeft: "2px",
                    },
                  }}
                >
                  {steps.map((step, index) => (
                    <Step expanded key={index}>
                      <StepLabel
                       StepIconComponent={() => StepIconComponent(index)}
                      >
                        <Typography variant="body1" className="headingText"style={{ marginLeft: "2px"}}>
                          {step.label}
                        </Typography>
                      </StepLabel>
                      <StepContent style={{ marginLeft: "14px"}}>
                        <Typography variant="body2" className="normalText" style={{ marginLeft: "9px"}}>
                          {step.description}
                        </Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <Box className="bulletData">
  <Typography variant="body1" className="headingText">
    Strict data confidentiality
  </Typography>
  <ul style={{ 

        alignItems: "flex-start", 
        justifyContent: "flex-start", 
        marginLeft:"16px",    // Remove default margin
        padding: 0     // Remove default padding
      }}>
    <li style={{ color: "#002857", }}> 
      <Typography variant="body2" className="normalText">
        Securely stored in compliance
      </Typography>
    </li>
    <li style={{ color: "#002857", marginRight: "16px" }}>
      <Typography variant="body2" className="normalText">
        100% data protection regulations
      </Typography>
    </li>
    <li style={{ color: "#002857" }}>
      <Typography variant="body2" className="normalText">
        Prices are subject to change
      </Typography>
    </li>
  </ul>
</Box>
</Box>      
          </Grid>
        ) : null}
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box>
            <Footer />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Onboarding;
