import {
  GET_SUMMARYDATA_REQUEST,
  GET_SUMMARYDATA_REQUEST_ERROR,
  GET_SUMMARYDATA_REQUEST_SUCCESS,
  GET_ALL_CUSTOMERS_REQUEST,
  GET_ALL_CUSTOMERS_REQUEST_ERROR,
  GET_ALL_CUSTOMERS_REQUEST_SUCCESS,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_REQUEST_ERROR,
  GET_CUSTOMER_REQUEST_SUCCESS,
  GET_ASYNC_DATA,
  GET_ASYNC_DATA_ERROR,
  GET_ASYNC_DATA_SUCCESS,
  UPDATE_RATES,
  UPDATE_RATES_ERROR,
  UPDATE_RATES_SUCCESS,
  GET_USER_RATE,
  GET_USER_RATE_ERROR,
  GET_USER_RATE_SUCCESS,
  CREATE_RATE,
  CREATE_RATE_ERROR,
  CREATE_RATE_SUCCESS,
  UPDATE_PERSONA,
  UPDATE_PERSONA_ERROR,
  UPDATE_PERSONA_SUCCESS,
  RESET_STATE_CO_PILOT,
  GET_ALL_PERSONA,
  GET_ALL_PERSONA_SUCCESS,
  GET_ALL_PERSONA_ERROR,
} from "../constant/co-pilot";

const initialState = {
  summaryData: [],
  customers: [],
  customer: {},
  asyncData: {},
  rates: [],
  userRating: null,
  rated: false,
  allPersona: [],
  updatedPersona: false,
  loadingSummaryData: false,
  loadingCustomers: false,
  loadingCustomer: false,
  loadedSummary: false,
  loadedCustomers: false,
  loadingAsync: false,
  loadedAsync: false,
  errors: null,
};

export default function CoPilot(state = initialState, action) {
  switch (action.type) {
    case RESET_STATE_CO_PILOT:
      return {
        ...state,
        updatedPersona: false,
      };
    case GET_SUMMARYDATA_REQUEST:
      return {
        ...state,
        loadingSummaryData: true,
      };
    case GET_SUMMARYDATA_REQUEST_SUCCESS:
      return {
        ...state,
        summaryData: action.payload,
        loadingSummaryData: false,
        loadedSummary: true,
      };
    case GET_SUMMARYDATA_REQUEST_ERROR:
      return {
        ...state,
        loadingSummaryData: false,
        loadedSummary: true,
      };
    case GET_ALL_CUSTOMERS_REQUEST:
      return {
        ...state,
        loadingCustomers: true,
      };
    case GET_ALL_CUSTOMERS_REQUEST_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        loadingCustomers: false,
        loadedCustomers: true,
      };
    case GET_ALL_CUSTOMERS_REQUEST_ERROR:
      return {
        ...state,
        loadingCustomers: false,
        loadedCustomers: true,
      };
    case GET_CUSTOMER_REQUEST:
      return {
        ...state,
        loadingCustomer: true,
        updatedPersona: false,
      };
    case GET_CUSTOMER_REQUEST_SUCCESS:
      return {
        ...state,
        customer: action.payload,
        loadingCustomer: false,
      };
    case GET_CUSTOMER_REQUEST_ERROR:
      return {
        ...state,
        loadingCustomer: false,
      };
    case GET_ASYNC_DATA:
      return {
        ...state,
        loadingAsync: true,
        loadedAsync: false,
        loadedSummary: false,
        loadedCustomers: false,
      };
    case GET_ASYNC_DATA_SUCCESS:
      return {
        ...state,
        asyncData: action.payload,
        loadingAsync: false,
        loadedAsync: true,
      };
    case GET_ASYNC_DATA_ERROR:
      return {
        ...state,
        loadingAsync: false,
        loadedAsync: true,
      };
    case UPDATE_RATES:
      return {
        ...state,
      };
    case UPDATE_RATES_ERROR:
      return {
        ...state,
      };
    case UPDATE_RATES_SUCCESS:
      return {
        ...state,
        rates: action.payload,
      };
    // get user rating
    case GET_USER_RATE:
      return {
        ...state,
        rated: false,
      };
    case GET_USER_RATE_SUCCESS:
      return {
        ...state,
        userRating: action.payload,
      };
    case GET_USER_RATE_ERROR:
      return {
        ...state,
        userRating: null,
        errors: {
          userRating: action.payload,
        },
      };
    // create rating
    case CREATE_RATE:
      return {
        ...state,
        rated: false,
      };
    case CREATE_RATE_SUCCESS:
      return {
        ...state,
        rated: true,
      };
    case CREATE_RATE_ERROR:
      return {
        ...state,
        rated: false,
        errors: {
          rated: action.payload,
        },
      };
    // update persona
    case UPDATE_PERSONA:
      return {
        ...state,
        updatedPersona: false,
      };
    case UPDATE_PERSONA_SUCCESS:
      return {
        ...state,
        updatedPersona: true,
      };
    case UPDATE_PERSONA_ERROR:
      return {
        ...state,
        updatedPersona: false,
        errors: {
          updatedPersona: action.payload,
        },
      };
    // get all persona
    case GET_ALL_PERSONA:
      return {
        ...state,
      };
    case GET_ALL_PERSONA_SUCCESS:
      return {
        ...state,
        allPersona: action.payload,
      };
    case GET_ALL_PERSONA_ERROR:
      return {
        ...state,
        errors: {
          allPersona: action.payload,
        },
      };
    // case LOGOUT_REQUEST_SUCCESS:
    //   state.summaryData= [];
    //   state.customers= [];
    //   state.customer= {};
    //   state.asyncData= {};
    //   state.rates= [];
    //   state.userRating= null;
    //   state.rated= false;
    //   state.allPersona= [];
    //   state.updatedPersona= false;
    //   state.loadingSummaryData= false;
    //   state.loadingCustomers= false;
    //   state.loadingCustomer= false;
    //   state.loadedSummary= false;
    //   state.loadedCustomers= false;
    //   state.loadingAsync= false;
    //   state.loadedAsync= false;
    //   state.errors= null;
    //   break;
    default:
      return state;
  }
}
