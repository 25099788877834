import axios from "axios";
import {
  CREATE_CHECKIN_REQUEST,
  CREATE_CHECKIN_REQUEST_ERROR,
  CREATE_CHECKIN_REQUEST_SUCCESS,
} from "../constant/check-in";
import { setNotification } from "./notification";

export const createCheckIn = (formdata, token) => async (dispatch) => {
  
  dispatch({
    type: CREATE_CHECKIN_REQUEST,
  });

  try {
    const epochTime = Date.now()
    formdata = { ...formdata, localCheckInTime: epochTime };

    const response = await axios.post(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/admin/opt-in`,
      formdata,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      }
    );

    dispatch({
      type: CREATE_CHECKIN_REQUEST_SUCCESS,
      payload: response.message,
    });

    dispatch(setNotification("success", response.message));

    // cognitoUser.getUserAttributes(function (err, result) {
    //   if (err) {
    //     alert(err.message || JSON.stringify(err));
    //     return;
    //   }
    //   // Find the 'email_verified' attribute in the result array
    //   const clientUUIDProp = result.find(
    //     (attr) => attr.getName() === "custom:client_uuid"
    //   );

    //   if (clientUUIDProp) {
    //     const clientUUIDValue = clientUUIDProp.getValue();
    //     console.log("client_uuid has value:", clientUUIDValue);
    //   } else {
    //     console.log("client_uuid attribute not found.");
    //   }
    // });
  } catch (error) {
    dispatch({
      type: CREATE_CHECKIN_REQUEST_ERROR,
      payload: error.message,
    });
  }
};
