import { React, useEffect, useState } from "react";
import { Form, Input, Row, Col, Button, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountInfo } from "../../../redux/actions/settings";
import { useMediaQuery } from "react-responsive";
import "./form-profile.scss";
const FormProfile = ({ isCancel = true, okTitle = "Save", onCancel, accountInfo }) => {
  const handleFinish = (values) => {
    console.log(values);
  };
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 769 });
  const [touchedFields, setTouchedFields] = useState({ firstName: false });
  const [formData, setFormData] = useState({
    firstName: accountInfo.first_name || '',
    lastName: accountInfo.last_name || '',
    phone: accountInfo.phone || '',
    email: accountInfo.email || '',
    title: accountInfo.job_role || '',
    role: accountInfo.account_level_role || '',
    streetAddress: accountInfo.address_line_1 || '',
    unit: accountInfo.address_line_2 || '',
    city: accountInfo.city || '',
    // province: accountInfo.state_province || '',
    zip: accountInfo.postal_code || '',
    country: accountInfo.country || 'US',
    state: accountInfo.state_province || ''
  });

  // const { updateAccountData } = useSelector((state) => state.updateAccountData);

  useEffect(() => {

    setFormData({
      firstName: accountInfo.first_name || '',
      lastName: accountInfo.last_name || '',
      phone: accountInfo.phone || '',
      email: accountInfo.email || '',
      title: accountInfo.job_role || '',
      role: accountInfo.account_level_role || '',
      streetAddress: accountInfo.address_line_1 || '',
      unit: accountInfo.address_line_2 || '',
      city: accountInfo.city || '',
      // province: accountInfo.state_province || '',
      zip: accountInfo.postal_code || '',
      country: accountInfo.country || 'US',
      state: accountInfo.state_province || ''
    });

  }, []);


  const stateOptions = {
    US: [ "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
      "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", 
      "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", 
      "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", 
      "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", 
      "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", 
      "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", 
      "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", 
      "Washington", "West Virginia", "Wisconsin", "Wyoming"],
    Canada: [   "Alberta", "British Columbia", "Manitoba", "New Brunswick", 
      "Newfoundland and Labrador", "Nova Scotia", "Ontario", 
      "Prince Edward Island", "Quebec", "Saskatchewan", 
      "Northwest Territories", "Nunavut", "Yukon"]
  };
  // useEffect(()=>{
  //   if(updateAccountData){

  //   }
  //   },[updateAccountData])

  const handleInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'country' && { state: null }) // Reset state when country changes
    }));
  };
  const submitData = async () => {
    try {
      console.log("Submitting form data:", formData);
      
await dispatch(updateAccountInfo(formData));
      window.location.href = window.location.href; 

      
      onCancel(); 
    } catch (error) {
      console.error("Failed to update account info:", error);
    }
  };
  
  
  return (
    <Form className="formProfile" layout="vertical" onFinish={handleFinish}>
      <Row gutter={[54, 15]}>
        <Col md={12} xs={24}>
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              {
                required: touchedFields.firstName,
                message: "First name is required",
              },
            ]}
          >
            <Input placeholder=""
              value={formData?.firstName}
              defaultValue={formData?.firstName}
              onChange={(e) => handleInputChange('firstName', e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              {
                required: touchedFields.lastName,
                message: "Last name is required",
              },
            ]}
          >

            <Input placeholder="" defaultValue={formData?.lastName} onChange={(e) => handleInputChange(
              "lastName", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: touchedFields.phone,
                message: "Phone number is required",
              },
            ]}
          >
            <Input placeholder="" defaultValue={formData?.phone} onChange={(e) => handleInputChange(
              "phone", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>

          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: touchedFields.email,
                message: "Email is required",
              },
              {
                type: "email",
                message: "Email invalid",
              },
            ]}
          >
            <Input placeholder="" defaultValue={formData?.email} onChange={(e) => handleInputChange(
              "email", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Title" name="title">
            <Input placeholder="" defaultValue={formData?.title} onChange={(e) => handleInputChange(
              "title", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Role" name="role">
            <Input placeholder="" defaultValue={formData?.role} onChange={(e) => handleInputChange(
              "role", e.target.value)} />
          </Form.Item>
        </Col>

        <Col md={12} xs={24}>
          <Form.Item label="Street Address" name="streetAddress">
            <Input placeholder="" defaultValue={formData?.streetAddress} onChange={(e) => handleInputChange(
              "streetAddress", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Suite, Unit, Apt." name="unit">
            <Input placeholder="" defaultValue={formData?.unit} onChange={(e) => handleInputChange(
              "unit", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="City" name="city">
            <Input placeholder="" defaultValue={formData?.city} onChange={(e) => handleInputChange(
              "city", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Zip/Postal Code" name="zip">
            <Input placeholder="" defaultValue={formData?.zip} onChange={(e) => handleInputChange(
              "zip", e.target.value)} />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item label="Country" name="country">
            <Select defaultValue={formData.country} onChange={value => handleInputChange('country', value)}
              suffixIcon={
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.96478 8.26649C6.02441 8.34209 6.10421 8.40391 6.19729 8.44661C6.29038 8.48932 6.39396 8.51163 6.49912 8.51163C6.60428 8.51163 6.70786 8.48932 6.80094 8.44661C6.89403 8.40391 6.97382 8.34209 7.03346 8.26649L12.8839 0.890256C12.9516 0.805177 12.9913 0.705527 12.9987 0.602131C13.0061 0.498736 12.9809 0.39555 12.9258 0.303784C12.8708 0.212018 12.7879 0.135183 12.6863 0.0816256C12.5847 0.0280683 12.4683 -0.000162472 12.3496 7.03383e-07H0.648661C0.53025 0.000427622 0.414212 0.0290214 0.313027 0.082707C0.211842 0.136393 0.129337 0.213139 0.0743855 0.304692C0.019434 0.396245 -0.00588502 0.499142 0.00115111 0.602316C0.00818723 0.705491 0.0473123 0.805039 0.114319 0.890256L5.96478 8.26649Z"
                    fill="#002857"
                  />
                </svg>
              }
            >
              <option value="US">United States</option>
              <option value="Canada">Canada</option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <div className="actions" style={isMobile?{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap:"37px"
          }:{}}>
            {isCancel && (
              <Button
                className="btnCancel"
                htmlType="button"
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}
            <Button className="btnSave" type="primary" htmlType="submit" onClick={submitData}>
              {okTitle}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default FormProfile;
