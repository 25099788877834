export const SHOW = 'SHOW';
export const HIDE = 'HIDE';
export const SET_DATA = 'SET_DATA';

export const types = {
  PAYMENT_METHODS: 'PAYMENT_METHODS',
  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  EDIT_PAYMENT_METHOD: 'EDIT_PAYMENT_METHOD',
  PAYMENT_HISTORY: 'PAYMENT_HISTORY',
  CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
}
