import React from "react";
import { Row, Col, Button } from "antd";
import "./style.scss";

const Detail = ({ data, onUpdate, onCancel, onDelete }) => {
  const roles = localStorage.getItem("roles");
  

  const Item = ({ label, value }) => {
    return (
      <div className="detailItem">
        <p>{label}</p>
        <h4>{value}</h4>
      </div>
    );
  };

  return (
    <div className="settingDetail">
      <Row gutter={["24px", "56px"]}>
        {data?.map((item, i) => (
          <Col xs={24} md={12} key={i}>
            <Item label={item.label} value={item.value} />
          </Col>
        ))}
      </Row>

      {roles == "Admin" &&
        <div className="actions">
          <Button color="primary" className="btnUpdate" onClick={onUpdate}>
            Update
          </Button>
          {/* <Button danger className="btnDelete" onClick={onDelete}>
          Delete Account
        </Button> */}
        </div>}
    </div>
  );
};

export default Detail;
