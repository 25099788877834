export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS'
export const GET_PAYMENT_METHODS_ERROR = 'GET_PAYMENT_METHODS_ERROR'
export const GET_PAYMENT_TRANSACTIONS = 'GET_PAYMENT_METHODS'
export const GET_PAYMENT_TRANSACTIONS_SUCCESS = 'GET_PAYMENT_TRANSACTIONS_SUCCESS'
export const GET_PAYMENT_TRANSACTIONS_ERROR = 'GET_PAYMENT_TRANSACTIONS_ERROR'
export const PUT_PAYMENT_METHOD = 'PUT_PAYMENT_METHOD'
export const PUT_PAYMENT_METHOD_SUCCESS = 'PUT_PAYMENT_METHOD_SUCCESS'
export const PUT_PAYMENT_METHOD_ERROR = 'PUT_PAYMENT_METHOD_ERROR'
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'
export const DELETE_PAYMENT_METHOD_ERROR = 'DELETE_PAYMENT_METHOD_ERROR'