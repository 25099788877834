import axios from "axios"
import {
  GET_API_KEYS_REQUEST,
  GET_API_KEYS_REQUEST_SUCCESS,
  GET_API_KEYS_REQUEST_ERROR,
  ROTATE_API_KEYS_REQUEST,
  ROTATE_API_KEYS_REQUEST_SUCCESS,
  ROTATE_API_KEYS_REQUEST_ERROR,
} from "../constant/apikeys"

export const getApiKeys = ({ user_id }) => async (dispatch) => {
  dispatch({
    type: GET_API_KEYS_REQUEST,
  })
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/apikeys`,
      {
        params: {
          id: user_id,
        },
      }      
    )

    dispatch({
      type: GET_API_KEYS_REQUEST_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: GET_API_KEYS_REQUEST_ERROR,
      payload: error.message,
    })
  }
}

export const rotateApiKeys = ({ user_id }) => async (dispatch) => {
  dispatch({
    type: ROTATE_API_KEYS_REQUEST,
  })
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/apikeys/rotate`,
      {
        id: user_id,
      }      
    )

    dispatch({
      type: ROTATE_API_KEYS_REQUEST_SUCCESS,
      payload: response.data,
    })
  } catch (error) {
    dispatch({
      type: ROTATE_API_KEYS_REQUEST_ERROR,
      payload: error.message,
    })
  }
}