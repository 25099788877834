import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';

export default function SubmitButton({
  btnClassName,
  loaderClassName,
  label,
  loading,
}) {
  return (
    <button type="submit" disabled={loading} className={btnClassName}>
      {!loading 
        ? label 
        : <Flex align="center" gap="middle" justify="center">
            <Spin  indicator={<LoadingOutlined spin className={loaderClassName} style={{ color: '#fff' }} />} />
          </Flex>}
    </button>
  );
}
