import { useEffect, Fragment } from "react"
import { Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { Skeleton, Dropdown, Popconfirm } from "antd";
import plusIcon from "@iconify-icons/mdi/plus"
import threeDotsIcon from "@iconify-icons/ph/dots-three-vertical-bold"

import { Icon } from "@iconify/react"

import { hide, show } from "../../../../redux/actions/modals"
import { getPaymentMethods, putPaymentMethod, deletePaymentMethod } from "../../../../redux/actions/payment"
import { types } from "../../../../redux/constant/modals"
import LogoDesktop from "../../../../assets/img/logo-desktop.svg"
import LogoOnly from "../../../../assets/img/logo-closed.svg"
import McIcon from "../../../../assets/img/icons/mc.svg"
import VisaIcon from "../../../../assets/img/icons/visa.svg"
import EllipseIcon from "../../../../assets/img/icons/ellipse.svg"

import "./style.scss"

export default function PaymentMethodsModal() {
  const dispatch = useDispatch()
  const { accountInfo: { user_id } } = useSelector(
    (state) => state.settings
  )
  const { sources, loading } = useSelector(
    (state) => state.payment
  )
  const handleHide = () => {
    dispatch(hide()) 
  }

  const handleOpenAddPaymentMethod = () => {
    dispatch(show(types.ADD_PAYMENT_METHOD))
  }

  useEffect(() => {
    if (user_id) {
      dispatch(getPaymentMethods({user_id}))
    }
  }, [ user_id ])

  const handleSetDefault = (e, id) => {
    e.preventDefault()    
    dispatch(putPaymentMethod({ user_id, id, is_default: true }))
      .then(() => dispatch(getPaymentMethods({ user_id })))
  }

  const handleUpdate = (e, id) => {
    e.preventDefault()
    dispatch(show(types.EDIT_PAYMENT_METHOD, { id }))
  }

  const handleRemove = (e, id) => {
    e.preventDefault()
    dispatch(deletePaymentMethod({ user_id, id }))
      .then(() => dispatch(getPaymentMethods({ user_id })))
  }  

  const getActionsDropdown = ({id, is_default, last_four_digits}) => {
    let items = [
      {
        label: <button onClick={(e) => handleUpdate(e, id)} className="action-item">Update</button>,
      },
    ]
    if (!is_default) {
      items = [
        {
          label: <button onClick={(e) => handleSetDefault(e, id)} className="action-item">Set as default</button>,
          key: '0',
        },        
        {
          label: 
            <button className="action-item">
              <Popconfirm
                className="w-full"
                title="Delete payment method"
                description={`Are you sure you want to remove ••• ${last_four_digits} ?`}
                okText="Yes"
                cancelText="No"
                placement="topRight"
                arrow={false}
                onConfirm={(e) => handleRemove(e, id)}
              >
                Remove
              </Popconfirm>    
            </button>
          ,
          key: '1',
        },        
        ...items,
      ]
    }
    return items
  }

  const getBrandDescription = ({id, brand, last_four_digits, is_default}) => {
    switch (brand) {
      case 'mastercard':
        return <div className="payment-method-info">
          <img src={McIcon} alt=""/> MC 
          <div className="last-four">
            <img src={EllipseIcon} alt=""/> 
            <img src={EllipseIcon} alt=""/> 
            <img src={EllipseIcon} alt=""/> 
            {last_four_digits}
          </div>
          <div className="actions">
            {is_default &&
              <span className="default">Default</span>
            }
            <Dropdown menu={{ items: getActionsDropdown({id, is_default, last_four_digits}) }} placement="bottomRight" trigger={['click']}>              
              <Icon icon={threeDotsIcon} className="" style={{ color: '#002857', fontSize: '25px' }}/>              
            </Dropdown>                        
          </div>
        </div>
      case 'visa':
        return <div className="payment-method-info">
          <img className="icon" src={VisaIcon} alt=""/> Visa 
          <div className="last-four">
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            {last_four_digits}
          </div>
          <div className="actions">
            {is_default &&
              <span className="default">Default</span>
            }
            <Dropdown menu={{ items: getActionsDropdown({id, is_default, last_four_digits}) }} placement="bottomRight" trigger={['click']}>              
              <Icon icon={threeDotsIcon} className="" style={{ color: '#002857', fontSize: '25px' }}/>              
            </Dropdown>                        
          </div>
        </div>
      default:
        return <div className="payment-method-info"> 
          <div className="last-four">
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            {last_four_digits}
          </div>
          <div className="actions">
            {is_default &&
              <span className="default">Default</span>
            }
            <Dropdown menu={{ items: getActionsDropdown({id, is_default, last_four_digits}) }} placement="bottomRight" trigger={['click']}>              
              <Icon icon={threeDotsIcon} className="" style={{ color: '#002857', fontSize: '25px' }}/>              
            </Dropdown>                        
          </div>
        </div>
    }
  }

  return (
    <Modal 
      title={<img src={LogoDesktop} alt="Desktop Logo" />}
      open 
      onCancel={handleHide}
      className="payment-modal"
      footer={null}
    >
      <div className="payment-method-container">
        <div className="payment-methods">
          <img src={LogoOnly} alt="Logo" className="payment-method-logo" />
          <h3 className="payment-method-title mb-15">Manage payment method</h3>
          <div className="payment-method-description mb-35">Control how you pay for your subscription</div>
          {(!loading) ? (
            <Fragment>
              {sources.map(({ id, brand, last_four_digits, active, is_default}, i) => (
                <div key={i} className="payment-method-item mb-15">
                  {getBrandDescription({id, brand: (brand).toLowerCase(), last_four_digits, active, is_default})}
                </div>
              ))}
              <button className="payment-method-button mt-20" onClick={handleOpenAddPaymentMethod}>
                <Icon icon={plusIcon} className="mr-5" style={{ color: '#002857', fontSize: '15px' }}/> Add payment method
              </button>
            </Fragment>
          ) : (
            <div className="w-full">
              <div className="mb-15">
                <Skeleton.Input   
                  className="w-full"
                  active
                  shape="round"
                  size="small"
                  style={{ width: "100% !important" }}
                />
              </div>
              <div className="mb-15">
                <Skeleton.Input
                  className="w-full"
                  active
                  shape="round"
                  size="small"
                  style={{ width: "100% !important" }}
                />
              </div>            
              <div>
                <Skeleton.Input
                  className="w-full"
                  active
                  shape="round"
                  size="small"
                  style={{ width: "100% !important" }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}