import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { getAllPersonas } from "../../../redux/actions/dashboard";
import "./style.scss";
import "../../../pages/Reports/style.scss";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "antd";

export default function PersonasDialog(props) {
  const { onClose, selectedValue, open, dialogValue } = props;
  const [personaData, setPersonaData] = useState();
  const { personas } = useSelector((state) => state.dashboard);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // Fetch personas data on component mount
  useEffect(() => {
    dispatch(getAllPersonas(token));
  }, [token]);

  // Update personaData when personas change
  useEffect(() => {
    if (personas) {
      setPersonaData(personas);
    }
    console.log("persona", personas);
  }, [personas]);

  // Close dialog and pass the selected value
  const handleClose = () => {
    onClose(selectedValue);
  };

  // Handle list item click and pass value to parent component
  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          borderRadius: "10px",
          width: "90%",
          maxWidth: "450px",
          height: "53vh",
          maxHeight: "500px",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        style={{
          position: "absolute",
          top: "8px",
          right: "8px",
          fontWeight: "bold",
          color: "black",
        }}
      >
        <CloseIcon />
      </IconButton>
      <div
        style={{
          // position: 'relative',
          marginRight: "4%",
          padding: "8%",
          textAlign: "left",
        }}
      >
        <div
          style={{
            marginBottom: "1%",
            fontSize: "12px",
            fontWeight: "500",
            color: "#02AFEF",
            marginTop: "3%",
          }}
        >
          DEFINITION
        </div>
        {dialogValue === "Personas" ? (
          <>
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "500",
                fontFamily: "Gotham",
                color: "#002857",
                marginBottom: "20px",
              }}
            >
              Needs based
            </Typography>
            <span
              style={{
                color: "#002857",
                fontSize: "14px",
                fontFamily: "Plus Jakarta Sans",

                fontWeight: "400",
                lineHeight: "1.7",
              }}
            >
              A Needs-Based Buyer persona represents a customer whose purchasing
              decisions are primarily driven by practical needs rather than
              desires, trends, or emotional factors. This buyer focuses on
              finding solutions to specific problems or fulfilling essential
              requirements, often seeking the most efficient or cost-effective
              option that meets their functional needs. They prioritize
              practicality, reliability, and value over brand loyalty or
              aesthetic appeal and typically make decisions based on rational
              evaluation rather than impulse or social influence.
            </span>
          </>
        ) : (
          <Typography
            style={{ marginBottom: "5%", fontSize: "18px", fontWeight: "600" }}
          >
            Sharing data
          </Typography>
        )}
      </div>
    </Dialog>
  );
}

PersonasDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  dialogValue: PropTypes.string.isRequired,
};
