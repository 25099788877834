import dog1 from "../assets/img/White dog.png";
import dog2 from "../assets/img/Layla.png";
import dog3 from "../assets/img/Dog pic.png";
const dogImages = [dog1, dog2, dog3];
export const getOrAssignAvatar = (id) => {
    const storedImages = JSON.parse(localStorage.getItem("feedAvatars")) || {};
    if (!storedImages[id]) {
        const randomImage = dogImages[Math.floor(Math.random() * dogImages.length)];
        storedImages[id] = randomImage;
        localStorage.setItem("feedAvatars", JSON.stringify(storedImages));
    }
    return storedImages[id];
};
