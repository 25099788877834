import { React, useState } from "react";
import ConnectData from "../../../components/Onboarding/ConnectData";

const ConnectDataLanding = () => {
  const [activeStep, setActiveStep] = useState(0);

 

  return <ConnectData/>
};

export default ConnectDataLanding;