
import {  CLEAR_STORE } from "../constant/auth";
import {
    GET_ACCOUNT_INFO_SUCCESS,
    GET_ACCOUNT_INFO_ERROR,
    UPDATE_ACCOUNT_INFO,
    UPDATE_ACCOUNT_INFO_ERROR,
    UPDATE_ACCOUNT_INFO_ORG,
    UPDATE_ACCOUNT_INFO_ORG_ERROR,
    INVITE_USER,
    INVITE_USER_ERROR,
    COUNT_USERS_SUCCESS,
    COUNT_USERS_ERROR,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_ERROR,
    GET_USERS_INFO_SUCCESS,
    GET_USERS_INFO_ERROR,
    SUBMIT_USER_INFO_SUCCESS,
    SUBMIT_USER_INFO_ERROR,
    DELETE_USER_BY_ID,
    DELETE_USER_BY_ID_ERROR,
    RESEND_INVITE_SUCCESS,
    RESEND_INVITE_ERROR
} from "../constant/settings";

const initialState = {
    accountInfo: {},
    updateAccountData: false,
    errors: {},
    updateAccountOrgInfo: false,
    inviteSent: false,
    count: "",
    allUsersData: null,
    userInfo: null,
    submittedInfo: false,
    deletedUser: false,
    resend: false,
};



export default function settings(state = initialState, action) {
    switch (action.type) {
        case CLEAR_STORE:
            return initialState;

        case GET_ACCOUNT_INFO_SUCCESS:
            return {
                ...state,
                accountInfo: action.payload,
            };
        case GET_ACCOUNT_INFO_ERROR:
            return {
                ...state,
                errors: {
                    accountInfo: action.payload,
                },
            };
        case INVITE_USER:
            return {
                ...state,
                inviteSent: true
            };
        case INVITE_USER_ERROR:
            return {
                ...state,
                errors: {
                    inviteSent: action.payload,
                },
            };

        case UPDATE_ACCOUNT_INFO:
            return {
                ...state,
                updateAccountData: true,
            };
        case UPDATE_ACCOUNT_INFO_ERROR:
            return {
                ...state,
                errors: {
                    updateAccountData: action.payload,
                },
            };
        case UPDATE_ACCOUNT_INFO_ORG:
            return {
                ...state,
                updateAccountOrgInfo: true,
            };
        case UPDATE_ACCOUNT_INFO_ORG_ERROR:
            return {
                ...state,
                errors: {
                    updateAccountOrgInfo: action.payload,
                },
            };
        case COUNT_USERS_SUCCESS:
            return {
                ...state,
                count: action.payload
            };
        case COUNT_USERS_ERROR:
            return {
                ...state,
                errors: {
                    count: action.payload,
                }
            };
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                allUsersData: action.payload
            };
        case GET_ALL_USERS_ERROR:
            return {
                ...state,
                errors: {
                    allUsersData: action.payload,
                }
            };

        case GET_USERS_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.payload
            };
        case GET_USERS_INFO_ERROR:
            return {
                ...state,
                errors: {
                    userInfo: action.payload,
                }
            };
        case SUBMIT_USER_INFO_SUCCESS:
            return {
                ...state,
                submittedInfo: true
            };
        case SUBMIT_USER_INFO_ERROR:
            return {
                ...state,
                errors: {
                    submittedInfo: false
                }
            };
        case DELETE_USER_BY_ID:
            return {
                ...state,
                deletedUser: true
            }
        case DELETE_USER_BY_ID_ERROR:
            return {
                ...state,
                errors: {
                    deletedUser: false
                }
            };
            case RESEND_INVITE_SUCCESS:
                return {
                    ...state,
                    resend: true
                }
            case RESEND_INVITE_ERROR:
                return {
                    ...state,
                    errors: {
                        resend: false
                    }
                };
        default:
            return state;
    }
}
