import axios from "axios";
import jwtDecode from "jwt-decode";
import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGIN_REQUEST_FAIL,
  LOGOUT_REQUEST_SUCCESS,
  LOGOUT_REQUEST_FAIL,
  LOAD_USER_REQUEST_SUCCESS,
  LOAD_DATABILLITY_USER_REQUEST,
  LOAD_DATABILLITY_USER_REQUEST_SUCCESS,
  LOAD_DATABILLITY_USER_REQUEST_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_REQUEST_FAIL,
  SEND_CODE,
  SEND_CODE_SUCCESS,
  SEND_CODE_FAIL,
  SAVE_CODE,
  SAVE_CODE_SUCCESS,
  SAVE_CODE_FAIL,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAIL,
  CLEAR_STORE,
  LOGIN_REQUEST_CHALLENGE
} from "../constant/auth";
import { CREATE_ACCOUNT_SSO_SUCCESS } from "../constant/onboarding";
import { onboardingStep } from "./onboarding";

export const login =
  ({ email, password, setLoginClicked }) =>
    async (dispatch) => {
      dispatch({
        type: LOGIN_REQUEST,
      });

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_LOGIN_API_URL}/api/login`,
          {
            email: email,
            password: password,
          }
        );
        
        if (response.data.challengeName === "NEW_PASSWORD_REQUIRED") {
          localStorage.setItem("email", email);
          localStorage.setItem("session", response.data.session);
          dispatch({
            type: LOGIN_REQUEST_CHALLENGE,
          });
        } else {
          const { idToken, accessToken, refreshToken, tenantId, userId } = response.data;
          localStorage.setItem("databillity_token", accessToken);
          localStorage.setItem("email", email);
          localStorage.setItem("tenantID", tenantId);
          localStorage.setItem("userIDSub", userId);

          dispatch({
            type: LOGIN_REQUEST_SUCCESS,
            payload: {
              email: email,
              token: accessToken,
              refreshToken,
            },
          });

          dispatch({
            type: CREATE_ACCOUNT_SSO_SUCCESS,
            payload: {
              email: email,
              verified: true,
            },
          });
 // Dispatch action to load user data
          dispatch(loadUser(idToken));

          setLoginClicked(true);
          dispatch(onboardingStep(email));

          // newPasswordRequired: function (userAttributes, requiredAttributes) {
          //   console.log("new password required");
          //   delete userAttributes.email_verified;
          // },
        }
      } catch (error) {
        dispatch({
          type: LOGIN_REQUEST_FAIL,
          payload: error.response.data.error ?? error.message,
        });
        setLoginClicked(true);
      }
    };

// Action to load user data
export const loadUser = (idToken) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    const decodedToken = jwtDecode(idToken);

    dispatch({
      type: LOAD_USER_REQUEST_SUCCESS,
      payload: decodedToken,
    });

    const userUUID =
      decodedToken.custom_user_uuid || decodedToken["custom:user_uuid"];
    if (userUUID) {
      dispatch(getUserDatabillity(userUUID));
    }
  } catch (error) {
    dispatch({
      type: LOAD_USER_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

// Action to get databillity user data
export const getUserDatabillity = (user_uuid) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_DATABILLITY_USER_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_USER_API_URL}/databillity_user/?user_id=${user_uuid}`
    );

    localStorage.setItem("databillity", JSON.stringify(data));
    dispatch({ type: LOAD_DATABILLITY_USER_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LOAD_DATABILLITY_USER_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT_REQUEST_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: LOGOUT_REQUEST_FAIL,
      payload: error.message,
    });
  }
};

export const clearStore = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_STORE,
    });
  } catch (error) {
    console.log("Failed to clear store");
  }
};

export const sendCode = (email) => async (dispatch) => {
  dispatch({
    type: SEND_CODE,
  });

  try {
    await axios.post(
      `${process.env.REACT_APP_FORGOT_PASSWORD_API_URL}`,
      { email }
    );

    dispatch({
      type: SEND_CODE_SUCCESS,
      payload: email,
    });
  } catch (error) {
    dispatch({
      type: SEND_CODE_FAIL,
      payload: error.message,
    });
  }
};

export const saveCode = (code) => async (dispatch) => {
  dispatch({
    type: SAVE_CODE,
  });

  try {
    dispatch({
      type: SAVE_CODE_SUCCESS,
      payload: code,
    });
  } catch (error) {
    dispatch({
      type: SAVE_CODE_FAIL,
      payload: error.message,
    });
  }
};

export const setNewPassword = (formdata) => async (dispatch) => {
  dispatch({
    type: SET_NEW_PASSWORD,
  });

  try {
    if (formdata.newPasswordRequired) {
      const email = localStorage.getItem("email");
      const session = localStorage.getItem("session");

      await axios.post(
        `${process.env.REACT_APP_NEW_PASSWORD_REQUIRED_API_URL}`,
        {
          email: email,
          newPassword: formdata.newPassword,
          session: session,
        }
      );
    } else {
      await axios.post(
        `${process.env.REACT_APP_NEW_PASSWORD_API_URL}`,
        formdata
      );
    }

    dispatch({
      type: SET_NEW_PASSWORD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SET_NEW_PASSWORD_FAIL,
      payload: error.message,
    });
  }
};
