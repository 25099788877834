export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_CHALLENGE = "LOGIN_REQUEST_CHALLENGE";
export const LOGIN_REQUEST_FAIL = "LOGIN_REQUEST_FAIL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_REQUEST_SUCCESS = "LOGOUT_REQUEST_SUCCESS";
export const CLEAR_STORE= "CLEAR_STORE";
export const LOGOUT_REQUEST_FAIL = "LOGOUT_REQUEST_FAIL";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_REQUEST_SUCCESS = "LOAD_USER_REQUEST_SUCCESS";
export const LOAD_USER_REQUEST_FAIL = "LOAD_USER_REQUEST_FAIL";

export const LOAD_DATABILLITY_USER_REQUEST = "LOAD_DATABILLITY_USER_REQUEST";
export const LOAD_DATABILLITY_USER_REQUEST_SUCCESS = "LOAD_DATABILLITY_USER_REQUEST_SUCCESS";
export const LOAD_DATABILLITY_USER_REQUEST_FAIL = "LOAD_DATABILLITY_USER_REQUEST_FAIL";

export const SEND_CODE = "SEND_CODE";
export const SEND_CODE_SUCCESS = "SEND_CODE_SUCCESS";
export const SEND_CODE_FAIL = "SEND_CODE_FAIL";

export const SAVE_CODE = "SAVE_CODE";
export const SAVE_CODE_SUCCESS = "SAVE_CODE_SUCCESS";
export const SAVE_CODE_FAIL = "SAVE_CODE_FAIL";

export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_NEW_PASSWORD_SUCCESS = "SET_NEW_PASSWORD_SUCCESS";
export const SET_NEW_PASSWORD_FAIL = "SET_NEW_PASSWORD_FAIL";