import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { getAllPersonas } from "../../../redux/actions/dashboard";
import {
    Chip
} from "@mui/material";
import "./style.scss";
import "../../../pages/Reports/style.scss";
const personaColors = [
    {
        bgColor: "rgba(2, 175, 239, 0.2)",
        labelColor:"#02AFEF"
    },
    {
        bgColor: "rgba(125, 198, 153, 0.2)",
        labelColor:"#7DC699"
    },
    {
        bgColor: "rgba(254, 164, 46, 0.2)",
        labelColor:"#FEA42E"
    },
    {
        bgColor: "rgba(125, 0, 183, 0.2)",
        labelColor:"#7D00B7"
    },
    {
        bgColor: "rgba(188, 62, 199, 0.2)",
        labelColor:"#BC3EC7"
    },
    {
        bgColor: "rgba(233, 105, 105, 0.2)",
        labelColor:"#E96969"
    },
    {
        bgColor: "rgba(227, 0, 183, 0.2)",
        labelColor:"#E300B7"
    },
    {
        bgColor: "rgba(2, 175, 239, 0.2)",
        labelColor:"#02AFEF"
    },
    {
        bgColor: "rgba(125, 198, 153, 0.2)",
        labelColor:"#7DC699"
    },
    {
        bgColor: "rgba(254, 164, 46, 0.2)",
        labelColor:"#FEA42E"
    },
    {
        bgColor: "rgba(125, 0, 183, 0.2)",
        labelColor:"#7D00B7"
    },
    {
        bgColor: "rgba(188, 62, 199, 0.2)",
        labelColor:"#BC3EC7"
    },
    {
        bgColor: "rgba(233, 105, 105, 0.2)",
        labelColor:"#E96969"
    },
    {
        bgColor: "rgba(227, 0, 183, 0.2)",
        labelColor:"#E300B7"
    },
    {
        bgColor: "rgba(2, 175, 239, 0.2)",
        labelColor:"#02AFEF"
    },
    {
        bgColor: "rgba(125, 198, 153, 0.2)",
        labelColor:"#7DC699"
    },
    {
        bgColor: "rgba(254, 164, 46, 0.2)",
        labelColor:"#FEA42E"
    },
    {
        bgColor: "rgba(125, 0, 183, 0.2)",
        labelColor:"#7D00B7"
    },
    {
        bgColor: "rgba(188, 62, 199, 0.2)",
        labelColor:"#BC3EC7"
    },
    {
        bgColor: "rgba(233, 105, 105, 0.2)",
        labelColor:"#E96969"
    },
    {
        bgColor: "rgba(227, 0, 183, 0.2)",
        labelColor:"#E300B7"
    }, {
        bgColor: "rgba(2, 175, 239, 0.2)",
        labelColor:"#02AFEF"
    },
    {
        bgColor: "rgba(125, 198, 153, 0.2)",
        labelColor:"#7DC699"
    },
    {
        bgColor: "rgba(254, 164, 46, 0.2)",
        labelColor:"#FEA42E"
    },
    {
        bgColor: "rgba(125, 0, 183, 0.2)",
        labelColor:"#7D00B7"
    },
    {
        bgColor: "rgba(188, 62, 199, 0.2)",
        labelColor:"#BC3EC7"
    },
    {
        bgColor: "rgba(233, 105, 105, 0.2)",
        labelColor:"#E96969"
    },
    {
        bgColor: "rgba(227, 0, 183, 0.2)",
        labelColor:"#E300B7"
    },
]

const emails = [
    {
        "personaId": 7,
        "personaLabel": "Habitual Buyer"
    },
    {
        "personaId": 8,
        "personaLabel": "Brand Conscious Buyer"
    },
    {
        "personaId": 10,
        "personaLabel": "Convenience Buyer"
    },
    {
        "personaId": 3,
        "personaLabel": "Organizational Buyer"
    },
    {
        "personaId": 2,
        "personaLabel": "Seasonal Buyer"
    },
    {
        "personaId": 14,
        "personaLabel": "Loyal Buyer"
    },
    {
        "personaId": 18,
        "personaLabel": "Brand Conscious Buyer"
    },
    {
        "personaId": 12,
        "personaLabel": "Convenience Buyer"
    },
    {
        "personaId": 9,
        "personaLabel": "Trendsetter/Innovation Buyer"
    },
    {
        "personaId": 4,
        "personaLabel": "Impulse Buyer"
    },
    {
        "personaId": 13,
        "personaLabel": "Research Oriented Buyer"
    },
    {
        "personaId": 16,
        "personaLabel": "Convenience Buyer"
    },
    {
        "personaId": 17,
        "personaLabel": "Trendsetter/Innovation Buyer"
    },
    {
        "personaId": 6,
        "personaLabel": "Social-Driven Buyer"
    },
    {
        "personaId": 20,
        "personaLabel": "Habitual Buyer"
    },
    {
        "personaId": 19,
        "personaLabel": "Habitual Buyer"
    },
    {
        "personaId": 1,
        "personaLabel": "Discount Driven Buyer"
    },
    {
        "personaId": 5,
        "personaLabel": "Need-Based Buyer"
    },
    {
        "personaId": 15,
        "personaLabel": "Convenience Buyer"
    },
    {
        "personaId": 11,
        "personaLabel": "Ethical/Sustainable Buyer"
    }
]
export default function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;
    const [personaData, setPersonaData] = useState();
    const { personas } = useSelector((state) => state.dashboard);
    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    useEffect(() => {
        //    setLoading(true);
        dispatch(getAllPersonas(token));
    }, [token]);
    const handleClose = () => {
        onClose(selectedValue);
    };
    useEffect(() => {
        if (personas) {
            setPersonaData(personas);
        }
        console.log("persona", personas)
    }, [personas]);

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <div style={{ minWidth: "400px" }}>
                <div style={{margin:"10%", marginBottom:"5%", fontSize:"20px", fontWeight:"600"}}>Edit Persona</div>
                <List style={{ border: "1px solid black", margin: "10%", marginTop:"0%", overflowY: "scroll", maxHeight: "200px" }} sx={{ pt: 0 }}>
                    {personaData?.map((email, id) => (
                        <ListItem disableGutters key={email.personaId} style={{ padding: "0px" }}>
                            <ListItemButton onClick={() => handleListItemClick(email)}>
                                <div style={{ minWidth: "-webkit-fill-available" }}>
                                    <Chip
                                        label={email.personaLabel}
                                        variant="filled"
                                        className="segmentBioBlueBadge badge"
                                        style={{ fontSize: "small", borderRadius: "0px", backgroundColor: `${personaColors[email.personaId].bgColor}`, color: `${personaColors[email.personaId].labelColor}`, maxHeight: "20px", fontWeight: "600" }}
                                    />
                                </div>
                            </ListItemButton>
                        </ListItem>
                    ))}

                </List>
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};