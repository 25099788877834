import axios from "axios";

import {
    GET_ALL_RECOMMENDATIONS,
    GET_ALL_RECOMMENDARTIONS_ERROR,
    GET_FEED_ID_LOADING,
    GET_ALL_FEEDS,
    GET_ALL_FEEDS_ERROR,
    GET_FEED_ID,
    GET_FEED_ID_ERROR,
    GET_ALL_PERSONAS,
    GET_ALL_PERSONAS_ERROR,
    UPDATE_PERSONAS,
    UPDATE_PERSONAS_ERROR,
    GET_USER_RATING,
    GET_USER_RATING_ERROR,
    CREATE_RATE_SUCCESS_DASHBOARD,
    CREATE_RATE_ERROR_DASHBOARD

} from "../constant/dashboard";
export const createRatingDashboard = (token, ratingData) => async (dispatch) => {
console.log(typeof(ratingData), "rrrrrr");
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/rating_review/${ratingData.customer_id}`,
      ratingData,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      }
    );
    dispatch({ type: CREATE_RATE_SUCCESS_DASHBOARD, payload: data });
  } catch (error) {
    console.error('Error submitting rating:', error);
    dispatch({ type: CREATE_RATE_ERROR_DASHBOARD, payload: error });
  }
};
//getuserratewithid
export const getUserRatings = (userId) => async (dispatch) => {
  try {
   // await dispatch({ type: GET_USER_RATE });
    const { data } = await axios.get(
       `${process.env.REACT_APP_RATING_API_URL}/rating_review/${userId}`
    );
    dispatch({ type: GET_USER_RATING, payload: data });
  } catch (error) {
    dispatch({ type: GET_USER_RATING_ERROR, payload: error });
  }
};


export const getAllRecommendations = (token, email) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/admin/recommendations?email=${email}`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
         },
        }
    );
    dispatch({ type: GET_ALL_RECOMMENDATIONS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ALL_RECOMMENDARTIONS_ERROR, payload: error });
  }
};

export const getAllFeeds = (token) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/customers`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
         },
        }
    );

   // data.sort((a,b) => new Date(a) < new Date(b) ? 1 : -1);
    dispatch({ type: GET_ALL_FEEDS, payload: data.message });
  } catch (error) {
    dispatch({ type: GET_ALL_FEEDS_ERROR, payload: error });
  }
};


export const getFeedId = (id, token) => async (dispatch) => {
  try {
    await dispatch({ type: GET_FEED_ID_LOADING });
    //const config = await getAxiosConfig();
    const { data } = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/dashboard?customerId=${id}`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      }
    );
    const response2 = await axios.get( 
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/rating_review/customer?customerId=${id}`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      });
    dispatch({ type: GET_FEED_ID, payload: data.message, rating: response2 });
  } catch (error) {
    dispatch({ type: GET_FEED_ID_ERROR, payload: error });
  }
};


export const getAllPersonas = (token) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/personas/list-all`,
      {
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      }
    );

    dispatch({ type: GET_ALL_PERSONAS, payload: data.message });
  } catch (error) {
    dispatch({ type: GET_ALL_PERSONAS_ERROR, payload: error });
  }
};

/**
 * TODO: Need to refactor, X-DBY-Key will not work anymore on /personas/secondary/change
 */
export const updatePersonas = (personaType, personaId, customerId, databillityId) => async (dispatch) => {
    try {
      let url = "";
      if(personaType==="primary"){
        url = `${process.env.REACT_APP_BASE_API_URL}/api/personas/primary/change`
      }
      else {
        url = `${process.env.REACT_APP_CUSTOMERS_API_BASE_URL}/personas/secondary/change`;
      }
      const response = await axios.patch(url, {
        "customerId": customerId,
        "personaId": personaId
      },
      {
        headers: {
          "X-DBY-Key": "stage_app.87358ea57bb946b69d2af721129f4d1a",
          "Content-Type": "application/json"
        }
      }
    );
    dispatch({ type: UPDATE_PERSONAS, payload: response });
  }
   catch (error) {
    dispatch({ type: UPDATE_PERSONAS_ERROR, payload: error });
  }
};