import { Modal } from "antd";
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import "./style.scss";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { changePlan } from "../../../../redux/actions/subscriptionManagement";
import { toast } from "react-toastify";
const ModalCurrentPlan = ({ open, onClose, isCurrent, selectedPlan, currentPlan }) => {
    const dispatch = useDispatch();
    const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });
    const [loading, setLoading] = useState(true);
    const { subscriptionEndDate, newPlan, plansData } = useSelector((state) => state.subscriptionManagement);
    const [endDate, setEndDate] = useState("")
    const changePlanData = () => {
        const selectedPlanData = plansData
            .filter(plan => plan.id === selectedPlan)
            .map(plan => plan.name);
        const newPlan = selectedPlanData + "/Month";
        dispatch(changePlan(newPlan))
    }
    useEffect(() => {
        if (subscriptionEndDate) {
            setEndDate(subscriptionEndDate);
        }
    }, [subscriptionEndDate])
    useEffect(() => {
        if (newPlan) {
          toast.success("Plan updated successfully!");
          onClose();
        }
      }, [newPlan]);
      useEffect(()=>{
        if(plansData){
            setLoading(false)
        }
    },[plansData])
    return (
        <>{loading? <></>:
        <Modal
            wrapClassName="modalPlan"
            open={open}
            onCancel={onClose}
            title=""
            footer={null}
        >
            <h4 style={{ color: "rgba(2, 175, 239, 1)", fontSize: "17px", fontWeight: "500" }}> VIEW CURRENT PLAN</h4>
            {/* <h3 className="title">Confirm New Plan</h3> */}
            {plansData.map((plan) => (plan.id == selectedPlan || plan.id == currentPlan) && (
                <Grid item xs={12} sm={12} md={12} key={plan.id} style={{ display: "flex", flexDirection: "row", marginTop: plan.id !== 1 ? "20px" : "55px" }}>
                    <Card sx={{
                        border: plan.id == selectedPlan ? '3px solid rgba(2, 175, 239, 1)' : '1px solid #E0E0E0',
                        boxShadow: 'none',
                        borderRadius: '8px',
                        ':hover': {
                            boxShadow: '0 2px 14px rgba(0,0,0,0.1)'
                        },
                        bgcolor: '#fff',
                        display: 'flex', flexDirection: 'row',
                        minWidth: "-webkit-fill-available"
                    }} >
  <Typography variant="h1" component="div" sx={{
                            width: "100px", backgroundColor: plan.id == selectedPlan ? "rgba(2, 175, 239, 0.2)" : "rgba(153, 153, 153, 0.2)",              
                            fontSize: "15px",        
                            paddingTop:isMobile? "31px": "0px",
                            fontWeight: "500",
                            color: plan.id == selectedPlan ? 'rgba(2, 175, 239, 1)' : "rgba(153, 153, 153, 1)",

                            textAlign: "center",  // Horizontally center text
                            display: "flex",       // Enables flex layout
                            flexDirection: "column", // Stack items vertically
                            alignItems: "center",  // Center items horizontally within the flex container
                            justifyContent: "center" 
                        }}>
                            {plan.id == selectedPlan ? <div style={{ fontSize: "10px", paddingTop:"-5%"}}>New Plan</div> : <div style={{ fontSize: "10px" }}>Current Plan</div>}
                            {plan.name}
                        </Typography> 

                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: "1" }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                <Typography sx={{ mb: 1.5, color: 'text.secondary', fontSize: isMobile? "12px":'0.875rem', marginTop: "12px",marginBottom: "0px" }}>
                                    {plan.description}
                                </Typography>
                            </Box>
                            <Typography variant="body2" sx={{ fontWeight: 'medium', color:"#002857", fontSize: isMobile? "13px": "1rem", marginTop: "12px",marginBottom: "0px"}}>
                                {plan.price} / month
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
            <div style={{ fontSize: "14px", fontWeight: "300", marginTop: "5%" , fontFamily:'Gotham', }}>
              In case you select a new plan please note:   {endDate}
            </div>
            <div style={{ fontSize: "14px", fontWeight: "300", marginTop: "3%", marginBottom: "5%" , fontFamily:'Gotham', }}>
                You agree that your DataBillity membership will continue and that we will charge the updated monthly fee
                until you cancel. You may cancel at any time to avoid future charges.
                To cancel, go to databillity.com/cancelplan.
            </div>
        </Modal>
}</>
    );
};
export default ModalCurrentPlan;

