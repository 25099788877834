import { Modal } from "antd";
import { Card, CardContent, Typography, Button, Box, Grid } from '@mui/material';
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { changePlan } from "../../../../redux/actions/subscriptionManagement";
import { toast } from "react-toastify";
import moment from "moment"

const ModalChangePlan = ({ open, onClose, isCurrent, selectedPlan, currentPlan }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { subscriptionEndDate, newPlan, plansData } = useSelector((state) => state.subscriptionManagement);
    const [endDate, setEndDate] = useState("");
    const { data: subscription } = useSelector((state) => state.subscription)


    useEffect(() => {
        if (plansData) {
            setLoading(false);
        }
    }, [plansData]);

    const changePlanData = () => {
        const selectedPlanData = plansData
            .filter(plan => plan.id === selectedPlan)
            .map(plan => plan.name);

        const newPlan = selectedPlanData + "/Month";
        dispatch(changePlan(newPlan));
    };

    useEffect(() => {
        if (subscriptionEndDate) {
            setEndDate(subscriptionEndDate);
        }
    }, [subscriptionEndDate]);

    useEffect(() => {
        if (newPlan) { 
            toast.success("Plan updated successfully!");
            onClose();
        }
    }, [newPlan]);

    return (
        <>
            {loading ? <></> :
            <Modal
                wrapClassName="modalPlan"
                open={open}
                onCancel={onClose}
                title=""
                footer={null}
            >
                <h4 style={{ color: "rgba(2, 175, 239, 1)", fontSize: "13px", fontWeight: "600", marginBottom:"3px"}}> CHANGE PLAN</h4>
                <h3 className="title" >Confirm New Plan</h3>
                {plansData.map((plan) => (plan.id === selectedPlan || plan.id === currentPlan) && (
                    <Grid 
                        item xs={12} sm={12} md={12} 
                        key={plan.id} 
                        style={{ display: "flex", flexDirection: "row", marginTop: "25px", }}
                    >
                        <Card sx={{
                            border: plan.id === selectedPlan ? '3px solid rgba(2, 175, 239, 1)' : '1px solid #e0e0e0',
                            boxShadow: 'none',
                            borderRadius: '8px',
                            ':hover': {
                                boxShadow: '0 2px 14px rgba(0,0,0,0.1)'
                            },
                            bgcolor: '#fff',
                            display: 'flex', flexDirection: 'row',
                            minWidth: "-webkit-fill-available",
                            marginTop:"10px",
                          
                        
                        }} >
                            <Typography variant="h1" component="div" sx={{
                               backgroundColor: plan.id === selectedPlan ? "rgba(2, 175, 239, 0.2)" : "rgba(153, 153, 153, 0.2)",
                                fontSize: "16px", 
                                fontWeight: "500",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "120px",
                                  lineHeight:"20px",
                              
                                color: plan.id === selectedPlan ? 'rgba(2, 175, 239, 1)' : "rgba(153, 153, 153, 1)"
                            }}>
                                {plan.id === selectedPlan ? <div style={{ fontSize: "10px" }}>New Plan</div> : <div style={{ fontSize: "10px" }}>Current Plan</div>}
                                {plan.name}
                            </Typography>
                            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flex: "1", marginTop:"10px"}}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 ,marginTop:"10px"}}>
                                    <Typography sx={{ mb: 1, color: 'text.secondary', fontSize: '13px', textAlign: 'left' , }}>
                                        {plan.description}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" sx={{ fontWeight: 'medium', fontSize: '14px',color:"#002857" }}>
                                    {plan.price} / month
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                <div style={{ fontSize: "14px", fontWeight: "300", marginTop: "5%", fontFamily: 'Gotham' }}>
                    Your new plan starts after the end of your billing period on {moment.unix(subscription?.current_period_end).format("MMMM D, YYYY")}
                </div>
                <div style={{ fontSize: "14px", fontWeight: "300", marginTop: "2%", marginBottom: "5%", fontFamily: 'Gotham' }}>
                    You agree that your DataBillity membership will continue and that we will charge the updated monthly fee
                    until you cancel. You may cancel at any time to avoid future charges.
                    To cancel, go to databillity.com/cancelplan.
                </div>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, p: 2 }}>
                    <Button variant="contained" onClick={onClose} style={{
                        width: "20%", borderRadius: "20px", backgroundColor: "rgba(153, 153, 153, 1)",
                        height: "45px",
                        minWidth: "fit-content"
                    }}>Cancel</Button>
                    <Button variant="contained" color="primary"
                        style={{
                            width: "20%", borderRadius: "20px", marginLeft: "15px",
                            height: "45px",
                            minWidth: "fit-content"
                        }} onClick={changePlanData}
                    >Confirm</Button>
                </Box>
            </Modal>
            }
        </>
    );
};

export default ModalChangePlan;
