import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Box,
  Button,
  Chip,
  Grid,
  Typography,
  Avatar,
  Skeleton,
  Collapse,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CommentIcon from "@mui/icons-material/Comment";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "./style.scss";

const RecommendationsWrapper = () => {
  const [segmentsData, setSegmentsData] = useState();
  const [expand, setExpand] = useState();

  const dispatch = useDispatch();
  const { insightsData, isLoading } = useSelector((state) => state.insights);
  
  useEffect(() => {
    if (!isLoading) {
      setSegmentsData(insightsData);
    }
  }, [insightsData]);

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 769 });

  // Control widget visibility
  const showWidget = false; // Set this to true to show the widget post-MVP

  return (      
    <Grid item xs={12} style={{ justifyContent: "right", display: "flex" }}>
      {showWidget && (
        <Card
          style={{
            maxWidth: isMobile ? "100%" : "30%",
            zIndex: "11",
            borderRadius: isMobile ? "20px" : "0",
          }}
          className={expand ? "expanded" : "notexpanded"}
        >
          <div
            style={{
              color: "white",
              background: isMobile ? "#FEA42E" : "#02AFEF",
              fontSize: "15px",
              padding: "3%",
            }}
          >
            <Stack
              direction="row"
              gap={1}
              onClick={() => (!isMobile ? setExpand(!expand) : "")}
              style={{ cursor: "pointer" }}
            >
              <CommentIcon />
              How to communicate
            </Stack>
          </div>

          <Collapse in={!isMobile ? expand : true}>
            <CardContent style={{ textAlign: "left" }}>
              <Typography variant="body2" style={{ fontSize: "14px" }}>
                <span>
                  Make sure you engage in small talk, sharing interesting stories
                  and display your sense of humor when working with Melanie.
                </span>
              </Typography>
            </CardContent>

            <div
              style={{
                background: isMobile ? "#FFF6EA" : "#02AFEF33",
                color: isMobile ? "#FEA42E" : "#02AFEF",
                padding: "5%",
                fontSize: "14px",
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.0833 31.6667H17.4167C17.4167 33.4083 15.9917 34.8333 14.25 34.8333C12.5083 34.8333 11.0833 33.4083 11.0833 31.6667ZM7.91667 30.0833H20.5833V26.9167H7.91667V30.0833ZM26.125 15.0417C26.125 21.09 21.9133 24.32 20.1558 25.3333H8.34417C6.58667 24.32 2.375 21.09 2.375 15.0417C2.375 8.48667 7.695 3.16667 14.25 3.16667C20.805 3.16667 26.125 8.48667 26.125 15.0417ZM22.9583 15.0417C22.9583 10.2442 19.0475 6.33333 14.25 6.33333C9.4525 6.33333 5.54167 10.2442 5.54167 15.0417C5.54167 18.9525 7.90083 21.2008 9.2625 22.1667H19.2375C20.5992 21.2008 22.9583 18.9525 22.9583 15.0417ZM33.8358 11.6692L31.6667 12.6667L33.8358 13.6642L34.8333 15.8333L35.8308 13.6642L38 12.6667L35.8308 11.6692L34.8333 9.5L33.8358 11.6692ZM30.0833 9.5L31.5717 6.23833L34.8333 4.75L31.5717 3.26167L30.0833 0L28.595 3.26167L25.3333 4.75L28.595 6.23833L30.0833 9.5Z"
                    fill={isMobile ? "#FEA42E" : "#02AFEF"}
                  />
                </svg>
                Sales Tips
              </Stack>
            </div>

            <CardContent style={{ textAlign: "left" }}>
              <div
                style={{ color: "#16B21D", paddingBottom: "4%", fontWeight: "600" }}
              >
                Do
              </div>
              <Stack direction="row" gap={1} style={{ fontSize: "14px" }}>
                <CheckCircleOutlineIcon style={{ color: "#16B21D" }} />
                <Typography variant="body2" color="text.secondary">
                  Use colorful language and a warm personal greeting
                </Typography>
              </Stack>
              <Stack direction="row" gap={1} style={{ fontSize: "14px" }}>
                <CheckCircleOutlineIcon style={{ color: "#16B21D" }} />
                <Typography variant="body2" color="text.secondary">
                  Use colorful language and a warm personal greeting
                </Typography>
              </Stack>
              <div
                style={{
                  color: "#DE2B2B",
                  paddingBottom: "4%",
                  paddingTop: "4%",
                  fontWeight: "600",
                }}
              >
                Don't
              </div>
              <Stack direction="row" gap={1} style={{ fontSize: "14px" }}>
                <CancelOutlinedIcon style={{ color: "#DE2B2B" }} />
                <Typography variant="body2" color="text.secondary">
                  Use colorful language and a warm personal greeting
                </Typography>
              </Stack>

              <Stack direction="row" gap={1} style={{ fontSize: "14px" }}>
                <CancelOutlinedIcon style={{ color: "#DE2B2B" }} />
                <Typography variant="body2" color="text.secondary">
                  Use colorful language and a warm personal greeting
                </Typography>
              </Stack>
            </CardContent>
          </Collapse>
        </Card>
      )}
    </Grid>
  );
};

export default RecommendationsWrapper;
