import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Button, Popover } from "antd";
import { dataConnections } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { getConnectionData } from "../../redux/actions/connection";

const ConnectionItem = ({ name, logo, connected, id, onChange }) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(connected);
  const { connections } = useSelector((state) => state.connections);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    if (connections) {
      connections.forEach((el, i) => {
        if (el.integration_name === name && el.connected === true) {
          setChecked(true);
        }
      });
    }
  }, [connections]);

  return (
    <div className="connectionsNew-content-connect-item">
      <div className="connectionsNew-content-connect-item-left">
        <div className="connectionsNew-content-connect-item-left-checked">
          {checked ? (
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              // onClick={() => onChange(id)}
            >
              <circle
                cx="12.5"
                cy="12.5"
                r="12.5"
                fill="#7DC699"
                fillOpacity="0.58"
              />
              <path
                d="M19.167 8.33301L10.8337 16.6663L6.66699 12.4997"
                stroke="#16B31D"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            // <Radio
            //   value={id}
            //   name={id}
            //   onChange={() => onChange(id)}
            //   disabled
            // />
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="15"
                cy="15"
                r="14"
                fill="#6F7174"
                fill-opacity="0.1"
                stroke="#6F7174"
                stroke-width="2"
              />
            </svg>
          )}
        </div>

        <div className="connectionsNew-content-connect-item-left-logo">
          <img alt="Logo Connect" src={logo} />
        </div>

        <h2 className="connectionsNew-content-connect-item-left-title">
          {name}
        </h2>
      </div>

      <div className="connectionsNew-content-connect-item-action">
        {checked && (
          <Button
            type="primary"
            className="connectionsNew-content-connect-item-action-btn_connect "
          >
            CONNECTED
          </Button>
        )}

        <Popover
          content={
            <Button
              onClick={() => {
                onChange(id);
                setOpen(false);
              }}
              type="primary"
              color="default"
            >
              {checked ? "Disconnect" : "Connect"}
            </Button>
          }
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
          placement="bottomRight"
          className="menuConnect"
          rootClassName="menuConnect"
        >
          <Button
            icon={
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1258_1263)">
                  <path
                    d="M12.5003 17.7083C13.0529 17.7083 13.5828 17.9278 13.9735 18.3185C14.3642 18.7092 14.5837 19.2391 14.5837 19.7917C14.5837 20.3442 14.3642 20.8741 13.9735 21.2648C13.5828 21.6555 13.0529 21.875 12.5003 21.875C11.9478 21.875 11.4179 21.6555 11.0272 21.2648C10.6365 20.8741 10.417 20.3442 10.417 19.7917C10.417 19.2391 10.6365 18.7092 11.0272 18.3185C11.4179 17.9278 11.9478 17.7083 12.5003 17.7083ZM12.5003 10.4167C13.0529 10.4167 13.5828 10.6362 13.9735 11.0269C14.3642 11.4176 14.5837 11.9475 14.5837 12.5C14.5837 13.0525 14.3642 13.5824 13.9735 13.9731C13.5828 14.3638 13.0529 14.5833 12.5003 14.5833C11.9478 14.5833 11.4179 14.3638 11.0272 13.9731C10.6365 13.5824 10.417 13.0525 10.417 12.5C10.417 11.9475 10.6365 11.4176 11.0272 11.0269C11.4179 10.6362 11.9478 10.4167 12.5003 10.4167ZM12.5003 3.125C13.0529 3.125 13.5828 3.34449 13.9735 3.73519C14.3642 4.12589 14.5837 4.6558 14.5837 5.20833C14.5837 5.76087 14.3642 6.29077 13.9735 6.68147C13.5828 7.07217 13.0529 7.29167 12.5003 7.29167C11.9478 7.29167 11.4179 7.07217 11.0272 6.68147C10.6365 6.29077 10.417 5.76087 10.417 5.20833C10.417 4.6558 10.6365 4.12589 11.0272 3.73519C11.4179 3.34449 11.9478 3.125 12.5003 3.125Z"
                    fill="#6F7174"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1258_1263">
                    <rect width="25" height="25" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
            className="connectionsNew-content-connect-item-action-btn_more"
          />
        </Popover>
      </div>
    </div>
  );
};

const Connections = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(dataConnections || []);

  const handleCheck = (id) => {
    // let results = data.filter((el) => {
    //   if (el.id === id) {
    //     el.connected = !el.connected;
    //   }

    //   return el;
    // });
    // setData(results);

    if (id === 17) {
      const url =
        process.env.REACT_APP_QUICKBOOK_URL +
        "?client_id=" +
        process.env.REACT_APP_CLIENT_ID +
        "&scope=" +
        process.env.REACT_APP_SCOPES +
        "&redirect_uri=" +
        process.env.REACT_APP_REDIRECT_URI +
        "&response_type=" +
        process.env.REACT_APP_RESPONSE_TYPE +
        "&state=" +
        process.env.REACT_APP_STATE;
      console.log(url);
      window.location.href = url;
    }
  };

  useEffect(() => {
    dispatch(getConnectionData());
  }, []);

  return (
    <div className="connectionsNew">
      <div className="connectionsNew-content" style={{marginLeft:'-37px'}}>
        <h1>Connections</h1>

        <div className="connectionsNew-content-connect">
          {data.length > 0 &&
            data.map((item, i) => (
              <ConnectionItem
                key={i}
                id={item.id}
                name={item.name}
                logo={item.logo}
                connected={item.connected}
                onChange={handleCheck}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Connections;
