import { toast } from "react-toastify";

import { LOGOUT_REQUEST_SUCCESS, CLEAR_STORE } from "../constant/auth";
import {
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_REQUEST_SUCCESS,
  GET_SUBSCRIPTION_REQUEST_ERROR,
} from "../constant/subscription"


const initialState = {
  loading: false,
  error: null,
  data: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {    
    case CLEAR_STORE:
      return initialState
    case LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: null
      }  
    case GET_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
      }            
    case GET_SUBSCRIPTION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case GET_SUBSCRIPTION_REQUEST_ERROR:
      toast.error("Subscription request failed. Please contact administrator.");
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}
