import { useState, useEffect } from "react"
import { Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import arrowBackIcon from "@iconify-icons/material-symbols/arrow-back-ios"
import { Icon } from "@iconify/react"
import { Form, Input, InputNumber } from 'antd'
import moment from "moment"
import axios from "axios"

import { hide, show } from "../../../../redux/actions/modals"
import { types } from "../../../../redux/constant/modals"
import { putPaymentMethod } from "../../../../redux/actions/payment"
import LogoDesktop from "../../../../assets/img/logo-desktop.svg"
import SubmitButton from "../../../../components/common/Button/submit-button"
import McIcon from "../../../../assets/img/icons/mc.svg"
import VisaIcon from "../../../../assets/img/icons/visa.svg"
import EllipseIcon from "../../../../assets/img/icons/ellipse.svg"

import "./style.scss";

export default function UpdatePaymentMethodModal({ id }) {

  const dispatch = useDispatch()
  const handleHide = () => {
    dispatch(hide()) 
  }
  const { accountInfo: { user_id } } = useSelector(
    (state) => state.settings
  )
  const { sources } = useSelector(
    (state) => state.payment
  )
  
  const [ paymentSource, setPaymentSource ]  = useState(null)
  const [ isLoading, setIsLoading ]  = useState(false)
  const [ name, setName ]  = useState('')
  const [ postal, setPostal ]  = useState('')
  const [ expiryMonth, setExpiryMonth ]  = useState(moment().format('MM'))
  const [ expiryYear, setExpiryYear ]  = useState(moment().format('YYYY'))

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(putPaymentMethod({ user_id, id, name, postal, expiryMonth, expiryYear }))
      .then(() => dispatch(show(types.PAYMENT_METHODS)))
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false))
  }

  const handleBack = () => {
    dispatch(show(types.PAYMENT_METHODS))
  }

  useEffect(() => {
    const [paymentSource] = sources
      .filter(({ id: sourceId }) => sourceId === id )
    setPaymentSource(paymentSource)
  }, [])

  const getCardDetails = async ({user_id, id}) => {
    setIsLoading(true)
    const { data: { name, postal_code, exp_month, exp_year } } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/customers/${user_id}/payments/sources/${id}/card`,
    )
    setName(name)
    setPostal(postal_code)
    setExpiryMonth(exp_month)
    setExpiryYear(exp_year)
    setIsLoading(false)
  }

  useEffect(() => {
    const [paymentSource] = sources
      .filter(({ id: sourceId }) => sourceId === id )
    setPaymentSource(paymentSource)
    getCardDetails({user_id, id})
  }, [])

  const getBrandDescription = ({brand, last_four_digits}) => {
    switch (brand) {
      case 'mastercard':
        return <div className="payment-method-info">
          <img src={McIcon} alt=""/> MC 
          <div className="last-four">
            <img src={EllipseIcon} alt=""/> 
            <img src={EllipseIcon} alt=""/> 
            <img src={EllipseIcon} alt=""/> 
            {last_four_digits}
          </div>
        </div>
      case 'visa':
        return <div className="payment-method-info">
          <img className="icon" src={VisaIcon} alt=""/> Visa 
          <div className="last-four">
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            {last_four_digits}
          </div>
        </div>
      default:
        return <div className="payment-method-info"> 
          <div className="last-four">
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            <img src={EllipseIcon} alt=""/>
            {last_four_digits}
          </div>
        </div>
    }
  }

  return (
    <Modal 
      title={<img src={LogoDesktop} alt="Desktop Logo" />}
      open 
      onCancel={handleHide}
      className="payment-modal"
      footer={null}
    >
      <div className="payment-method-container">
        <div className="update-payment-method">
          <span className="payment-back mb-20" onClick={handleBack}>
            <Icon icon={arrowBackIcon} height="20px" width="12px" style={{ color: '#02AFEF' }}/>
            Manage payment method
          </span>
          <h3 className="payment-method-title mb-5">Edit payment method</h3>
          <div className="payment-method-description">Update existing payment method</div>
          <div className="payment-form w-full mt-15">
            <form onSubmit={(e) => (handleSubmit(e))}>
              <Form.Item
                layout="vertical"
                label="Card number"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <div className="payment-method-item">
                  {paymentSource && getBrandDescription({brand: paymentSource.brand, last_four_digits: paymentSource.last_four_digits})}                  
                </div>                
              </Form.Item>
              <div className="row-half">
                <Form.Item
                  layout="vertical"
                  label="Expiration month"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 12,
                  }}
                  className="half"
                >
                  <InputNumber
                    className="payment-input"
                    min={1} 
                    max={12} 
                    defaultValue={expiryMonth}
                    value={expiryMonth}
                    onChange={value => {
                      setExpiryMonth(value)
                    }}  
                  />
                </Form.Item>
                <Form.Item
                  layout="vertical"
                  label="Expiration year"
                  labelCol={{
                    span: 24,
                  }}
                  wrapperCol={{
                    span: 12,
                  }}
                  className="half"
                >
                  <InputNumber
                    className="payment-input"
                    min={moment().format('YYYY')} 
                    max={moment().add(20,'y').format('YYYY')} 
                    defaultValue={expiryYear}
                    value={expiryYear}
                    onChange={value => {
                      setExpiryYear(value)
                    }}  
                  />
                </Form.Item>                    
              </div>
              <Form.Item
                layout="vertical"
                label="Name on card"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <Input
                  className="payment-input"
                  placeholder="Jenny Rosen"
                  value={name}
                  onChange={event => {
                    setName(event.target.value)
                  }}  
                />
              </Form.Item>                                          
              <Form.Item
                layout="vertical"
                label="Zip code"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
              >
                <Input
                  className="payment-input"
                  placeholder="12345"
                  value={postal}
                  onChange={event => {
                    setPostal(event.target.value)
                  }}                        
                />
              </Form.Item>
              <SubmitButton
                label="Save"
                loading={isLoading}
                btnClassName="payment-button w-full mt-10"
              />
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}